.nav-container {
  position: relative;

  .btn-backup {
    display: none;
    opacity: 0.1;
    transform: rotate(-90deg);
    @include font-family(TheinhardtMedium);
    font-size: 20px;
    color: #1D1E21;
    position: absolute;
    top: 85px;
    right: -40px;
    z-index: 10;

    @include breakpoint(large){
      font-size: 30px;
      right: 0;
    }

    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 90px;
      height: 2px;
      background-color: #1D1E21;
      margin-left: 20px;
    }
  }

  .contact-us.get-in-touch {
    display: none;
    text-align: center;
    padding: 30px 0;
    background: white;

    @include breakpoint(large){
      padding: 0;
      width: 100%;
      height: 300px;
      position: absolute;
      z-index: 10;
    }

    span {
      display: block;
      text-align: left;

      @include breakpoint(large){
        text-align: center;
        @include full-vertical-alignment();
      }

      a {
        color: color(blue);
      }
    }
  }



  .newsletter {
    display: none;
    position: absolute;
    width: 100%;
    background: white;
    z-index: 10;
    height: calc(100vh - 90px);
    overflow-y: scroll;

    @include breakpoint(large){
      padding-top: 0;
      height: calc(100vh - 139px);
      padding: 40px 0 30px 0;
    }

    .submit-newsletter{
      position: relative;
      top: 5px;
    }

    .thank-you-message {
      display: none;
      margin-top: 90px;

      @include breakpoint(large){
        margin-top: 0;
      }

      span {
        display: block;
        @include font-family(TheinhardtBold);
        font-size: 22px;
        line-height: 40px;
        color: color(black);
        margin-bottom: 20px;

        @include breakpoint(large){
          font-size: 32px;
        }
      }

      .btn-back {
        position: relative;
        @include font-family(TheinhardtMedium);
        font-size: 16px;
        color: color(black);

        @include breakpoint(large){
          font-size: 20px;
        }

        body.not-ios &{
          &:hover {
            color: color(blue);
            &:before {
              background: color(blue);
            }
          }
        }

        &:before {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
          height: 1px;
          background: color(black);
        }
      }
    }

    .headline {
      display: block;
      @include font-family(PlantinMTProLight);
      font-size: 30px;
      line-height: 34px;
      color: color(black);
      margin-bottom: 50px;

      @include breakpoint(large){
        margin: 0;
        font-size: 50px;
        line-height: 50px;
      }

      strong {
        @include font-family(TheinhardtBold);
        font-weight: normal;
      }
    }

    form {
      text-align: right;

      @include breakpoint(large){
        text-align: left;
      }

      input:not([type="radio"]) {
        @include font-family(TheinhardtRegular);
        font-size: 20px;
        color: #BBBBBC;
        letter-spacing: 0;
        line-height: 54px;
        height: 54px;
        border: none;
        box-shadow: none;
        padding: 0;
        text-align: left;

        @include breakpoint(large){
          margin: 0;
        }

        &:focus,
        &:active {
          border-bottom: 1px solid #ccc;
        }
      }

      small {
        font-size: 13px;
      }

      .form-error {
        margin-bottom: 0;
      }

      .gdpr_label {
        display: inline-block;
        vertical-align: top;
        width: 80px;
        @include font-family(TheinhardtRegular);
        color: #BBBBBC;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
      }

      .submit-newsletter {
        @include font-family(TheinhardtMedium);
        color: color(blue);
        letter-spacing: 0;
        font-size: 20px;
        line-height: 40px;
        border-bottom: 1px solid color(blue);
      }
    }

  }


  .primary-nav {

    @include breakpoint(large) {
      text-align: center;
    }

    ul {
      @include breakpoint(large) {
        display: block;
        vertical-align: top;
        column-count: 2;
        // width: 160px;
      }

      li {
        display: block;

        &.active,
        &.current {
          a {
            @include font-family(PlantinMTProLightItalic);
          }
        }

        a {
          @include font-family(TheinhardtBold);
          display: block;
          font-size: 45px;
          line-height: 60px;
          color: #1D1E21;
          letter-spacing: 0;
          text-align: right;

          body.lang-fr & {
            font-size: 28px;
            line-height: 44px;
          }

          @include breakpoint(large) {
            text-align: left;
          }

          body.not-ios &{
            &:hover,
            &:active,
            &:focus {
              top: 6px;
              position: relative;
              @include font-family(PlantinMTProLightItalic);
            }
          }
        }
      }
    }
  }

  .secondary-nav {
    float: right;
    margin-top: 30px;
    text-align: right;

    @include breakpoint(large) {
      float: none;
      margin: 0 auto;
      text-align: center;
    }

    ul {
      width: 100px;
      display: inline-block;
      vertical-align: top;

      @include breakpoint(large) {
        width: 140px;
      }

      li {
        display: block;

        a {
          opacity: 0.2;
          @include font-family(TheinhardtRegular);
          display: block;
          font-size: 16px;
          line-height: 30px;
          color: #1D1E21;
          letter-spacing: 0;
          text-align: right;

          &:hover,
          &:active,
          &:focus {
            opacity: 0.5;
          }

          @include breakpoint(large) {
            text-align: left;
            font-size: 22px;
            line-height: 43px;
          }
        }
      }
    }
  }

}


.equity-aux-nav {
  display: inline-block;
  max-width: 300px;
  margin-left: 10px;
  margin-top: 10px;

  span {
    display: block;
    @include font-family(TheinhardtMedium);
    font-size: 14px;
    line-height: 14px;
    color: color(blue);
    text-align: right;

    @include breakpoint(medium) {
      text-align: left;
      font-size: 18px;
      line-height: 18px;
    }
  }

  a {
    @include font-family(TheinhardtMedium);
    display: block;
    font-size: 16px;
    line-height: 16px;
    color: color(black);
    text-align: right;

    &:hover {
      color: color(blue);
    }

    @include breakpoint(medium) {
      text-align: left;
      font-size: 22px;
      line-height: 22px;
    }
  }
}
