
// Show More

[data-show-more] {
  color: color(blue)!important;
  text-decoration: underline;
  cursor: pointer;
}

[data-show-more-target] {
  display: none;
}
