.reip-container {

  &.op0 { opacity: 0; }

  .scroll {
    opacity: 0;
    margin-bottom: 70px;
  }

  &.landing {

    .reip-hero {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      flex-direction: column;
      padding-top: 70px;

      @include breakpoint(medium) {
        padding-top: 0;
        margin-top: 120px;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }

      @include breakpoint(xlarge) {
        margin-top: 100px;
      }

      .hero-column-left {
        width: 100%;
        margin-bottom: 20px;

        @include breakpoint(xlarge) {
          width: 45%;
          position: relative;
          margin-bottom: 0;
        }
      }

      .hero-column-right {
        width: 100%;
        @include breakpoint(xlarge) {
          width: 52%;
        }
      }

      .desktop-full-height {
        @include breakpoint(xlarge) {
          height: calc(100vh - 90px);
          position: absolute;
          &.spacer {
            position: static;
            &.w43 {
              width: 43vw;
              max-width: 500px;
            }
          }
        }
      }

      .reip-hero-content {
        &_headline {
          display: block;
          @include font-family(TheinhardtRegular);
          font-size: 26px;
          line-height: 27px;
          color: color(black);

          @include breakpoint(xlarge) {
            font-size: 40px;
            line-height: 42px;
          }

          &:not(:first-child){
            margin-top: 15px;
            @include breakpoint(xlarge) {
              margin-top: 20px;
            }
          }

          span {
            padding: 0 3px;
            background-color: #B7C6FF;
            display: inline-block;
            line-height: 23px;
            @include breakpoint(large) {
              line-height: 33px;
            }
          }
        }
      }

      .vertical-align {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .vertical-slide {
        @include breakpoint(xlarge) {
          position: fixed;
          top: 90px;
          width: 43vw;
          max-width: 500px;
        }
      }

      #content-desktop {
        display: none;
        @include breakpoint(xlarge) {
          display: block;
        }
      }

      .reip-drag-container {
        position: relative;

        &.fixed {
          position: fixed;
        }

        .reip-drag-image {
          position: relative;
          display: block;
          height: 408px;
          width: 330px;
          margin: 0 auto;

          @include breakpoint(xlarge) {
            width: 610px;
            height: 371px;
          }

          .reip-image {
            position: absolute;
            z-index: 1;
            width: 0px;
            height: 0px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) !important;
            overflow: hidden;

            #drag_image_1,
            #drag_image_2,
            #drag_image_3 {
              display: none;

              @include breakpoint(xlarge) {
                display: block;
              }
            }

            img {
              min-width: 245px;
              min-height: 370px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) !important;
            }
          }
        }

        .reip-drag-draggable, .reip-drag-draggable-handle {
          display: block;
          position: absolute;
          z-index: 2;
          cursor: move;
          &.top {
            top: 0;
          }
          &.bottom {
            bottom: 0;
          }
          &.left {
            left: 0;
          }
          &.right {
            right: 0;
          }
        }

        .reip-drag-draggable-handle {
          height: 134px;
          &.left {
            width: 190px;
          }
          &.right {
            width: 245px;
          }
        }

        .label {
          position: relative;
          display: block;
          border: 3px solid black;
          @include font-family(TheinhardtMedium);
          font-size: 36px;
          line-height: 44px;
          height: 50px;
          color: color(black);
          text-align: center;
          background: white;

          @include breakpoint(xlarge) {
            font-size: 50px;
            height: 67px;
            line-height: 61px;
          }

          .draggable-img-pattern {
            position: absolute;
            opacity: 0;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: top left;
            background-repeat: no-repeat;
            background-size: contain;
          }

          .hidden {
            opacity: 0;
          }

          &.choose {
            width: 140px;
            @include breakpoint(xlarge) {
              width: 194px;
            }
          }

          &.equity {
            width: 122px;
            @include breakpoint(xlarge) {
              width: 168px;
            }
          }

          &.evidence {
            width: 169px;
            @include breakpoint(xlarge) {
              width: 220px;
            }
          }

          &.both {
            width: 101px;
            @include breakpoint(xlarge) {
              width: 139px;
            }
          }

          &.equity,
          &.both {
            top: -3px;
          }

          &.both {
            left: -21px;
            @include breakpoint(xlarge) {
              left: -26px;
            }
          }
        }

        .btn-tap {
          @include full-center-alignment();
          position: absolute;
          z-index: 11;
          width: auto;
          text-align: center;
          @include font-family(TheinhardtRegular);
          font-size: 18px;
          line-height: 18px;
          color: color(black);
          display: inline-block;
          border-bottom: thin solid black;
          padding-bottom: 2px;
          white-space: nowrap;
          letter-spacing: -.02rem;
          &:hover {
            color: color(blue-accesible);
            border-bottom: thin solid color(blue-accesible);
          }
        }
      }
    }

    .intro {
      margin-bottom: 80px;
      @include breakpoint(xlarge) {
        margin-bottom: 0;
      }

      p {
        @include font-family(TheinhardtRegular);
        font-size: 20px;
        line-height: 22px;
        color: color(black);
        letter-spacing: -.02rem;

        @include breakpoint(xlarge) {
          font-size: 26px;
          line-height: 30px;
        }

        span:not(.highlight) {
          color: color(blue-accesible);
          @include font-family(TheinhardtMedium);
        }
        span.highlight {
          padding: 0 3px;
          background-color: #B7C6FF;
          line-height: 18px;
          display: inline-block;
          @include breakpoint(large){
            line-height: 24px;
          }
        }
      }
    }

    .reip-content {
      max-width: 750px;
      margin: 0 auto;

      @include breakpoint(xlarge) {
        position: relative;
        z-index: 10;
        background: white;
        margin-top: 80px;
        max-width: none;
      }

      &.mobile {
        display: block;
        @include breakpoint(xlarge) {
          display: none;
        }
      }
      &.desktop {
        display: none;
        @include breakpoint(xlarge) {
          display: block;
        }
      }


      .explore-area {

        .explore-area-block {
          position: relative;
          display: block;
          margin-bottom: 100px;

          &:after {
            content: "";
            display: table;
            clear: both;
          }

          @include breakpoint(xlarge) {
            width: 939px;
            margin: 0 auto;
            margin-bottom: 200px;
          }

          // Active Animation
          &.active, &:hover {
            .block-head {
              margin-right: 5%;
              @include breakpoint(xlarge) {
                margin-right: 0;
              }
              .cta-explore {
                top: 20px;
              }
              .block-head-img {
                top: 20px;
              }
            }
            .block-body {
              margin-left: 5%;
              top: 0;
              @include breakpoint(xlarge) {
                margin-left: 0;
                top: 20px;
              }
            }

            // Customize colors on animation
            &.section-01 {
              .cta-explore {
                background: color(reip_orange);
              }
              .block-body {
                background: color(reip_orange_light);
                p span {
                  background: color(reip_orange_highlight);
                }
              }
            }
            &.section-02 {
              .cta-explore {
                background: color(reip_blue);
                color: white;
              }
              .block-body {
                background: color(reip_blue_light);
                p span {
                  background: color(reip_blue_highlight);
                }
              }
            }
            &.section-03 {
              .cta-explore {
                background: color(reip_green);
              }
              .block-body {
                background: color(reip_green_light);
                p span {
                  background: color(reip_green_highlight);
                }
              }
            }
            &.section-04 {
              .cta-explore {
                background: color(reip_black);
                color: white;
              }
              .block-body {
                background: color(reip_black_light);
                p span {
                  background: color(reip_black_highlight);
                }
              }
            }
            &.section-05 {
              .cta-explore {
                background: color(reip_yellow);
              }
              .block-body {
                background: color(reip_yellow_light);
                p span {
                  background: color(reip_yellow_highlight);
                }
              }
            }
          }

          a {
            display: block;
            position: relative;

            @include breakpoint(xlarge) {
              display: flex;
            }
          }

          .border {
            border-width: 3px;
            border-style: solid;
          }

          .block-head,
          .block-body {
            position: relative;
            width: 90%;
            @include animation();

            @include breakpoint(xlarge) {
              width: 464px;
            }
          }

          .block-head {
            height: 86vw;
            // height: 91.3vw;
            float: right;

            @include breakpoint(xlarge) {
              order: 2;
              height: 330px;
              margin-left: -2px;
            }

            .cta-explore {
              @include font-family(TheinhardtRegular);
              position: absolute;
              z-index: 10;
              right: 0;
              top: 0;
              padding: 10px;
              font-size: 15px;
              line-height: 15px;
              color: color(black);
              background: white;
              letter-spacing: -.03rem;
              @include animation();

              @include breakpoint(xlarge) {
                font-size: 20px;
                line-height: 38px;
                height: 41px;
                padding: 0 10px;
                letter-spacing: -.02rem;
              }
            }
            .block-head-img {
              position: absolute;
              top: 38px;
              right: 0;
              height: calc(86vw - 15px);
              width: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              @include animation();

              @include breakpoint(xlarge) {
                height: 464px;
              }
            }
          }

          .block-body {
            float: left;
            clear: both;
            padding: 18px;
            background: white;
            position: relative;
            top: 20px;
            // height: 330px;

            @include breakpoint(xlarge) {
              @include animation();
              order: 1;
              height: 464px;
              top: 0;
              padding: 31px 31px;
            }

            .block-headline {
              display: block;
              @include font-family(TheinhardtMedium);
              font-size: 26px;
              line-height: 27px;
              color: color(black);
              margin-bottom: 100px;

              @include breakpoint(xlarge) {
                font-size: 42px;
                line-height: 44px;
                margin-bottom: 50px;
              }
            }

            p {
              @include font-family(TheinhardtRegular);
              font-size: 27px;
              line-height: 30px;
              color: color(black);
              // bottom: 0;
              // width: 90%;
              margin-bottom: 0;

              @include breakpoint(xlarge) {
                position: absolute;
                width: 85%;
                bottom: 0;
                font-size: 40px;
                line-height: 42px;
                margin-bottom: 31px;
              }

              span {
                @include animation();
                background: white;
                line-height: 31px;
                padding-left: 2px;
                padding-right: 2px;
              }

              .pattern-ico {
                display: inline-block;
                vertical-align: baseline;
                width: 33px;
                margin-right: 5px;

                @include breakpoint(xlarge) {
                  width: 52px;
                }
              }
            }
          }

          &.section-01 {
            .border {
              border-color: color(reip_orange);
            }
          }
          &.section-02 {
            .border {
              border-color: color(reip_blue);
            }
          }
          &.section-03 {
            .border {
              border-color: color(reip_green);
            }
          }
          &.section-04 {
            .border {
              border-color: color(reip_black);
            }
          }
          &.section-05 {
            .border {
              border-color: color(reip_yellow);
            }
          }
        }
      }

    }

  }

  .equity_hidden_iframe, 
  .year_in_review_hidden_iframe {
    position: absolute;
    height: 1px;
    visibility: hidden;
  }

}
