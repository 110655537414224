
.reip-container {
  // Colors variations
  &.section-1 {
    .reip-detail-hero {
      background-color: color(reip_orange_highlight);
      // @include breakpoint(large) {
      //   svg {
      //
      //     position: fixed;
      //     top: 50vh;
      //     right: 0;
      //     height: 50vw;
      //     width: 50vw;
      //     transform: translateY(-50%);
      //   }
      // }
    }
    .reip-detail-body {
      .slick-dots li button .slick-dot-icon {
        color: color(reip_orange_highlight);
      }
      .slick-dots li.slick-active button .slick-dot-icon {
        color: color(reip_orange);
      }
      p span {
        background-color: color(reip_orange_highlight);
      }
    }
    .reip-detail-wrap-up {
      .wrap-up-audio {
        .wrap-up-audio_hero {
          border-color: color(reip_orange);
        }
      }
      .wrap-up-audio_content {
        .cta-hear-a-scenario {
          background: color(reip_orange_light);
          border-color: color(reip_orange);
          i {
            background: color(reip_orange);
          }

          &:hover, &.play {
            background: color(reip_orange);

            i {
              background: white;

              &:before {
                border-left-color: color(reip_orange);
              }
            }
          }
        }
        .cta-guide {
          border-color: color(reip_orange);
          &:hover {
            background-color: color(reip_orange);
          }
        }
        .wrap-player {
          .audio-player {
            .timeline {
              background: color(reip_orange_light);
              .progress {
                background: color(reip_orange);
              }
            }
            .controls {
              .toggle-play {
                .pp-play {
                  fill: color(reip_orange);
                }
                .pp-pause {
                  stroke: color(reip_orange);
                }
                &.play .pp-play{
                  opacity: 0.5;
                }
                &.pause .pp-pause {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }
    .you-can-choose-both {
      &_form-container {
        &_fields {
          border-color: color(reip_orange);
          input {
            &[type="submit"] {
              &:hover {
                background-color: color(reip_orange);
              }
            }
          }
          .border {
            &.top {
              border-color: color(reip_orange);
            }
            &.right {
              border-color: color(reip_orange);
            }
          }
          .recaptcha {
            border-color: color(reip_orange);
          }
        }
        &_graphic {
          .reip-share-graphic {
            border-color: color(reip_orange);
          }
        }
      }
    }
  }
  &.section-2 {
    .reip-detail-slider {

      .reip-detail-hero {
        background-color: color(reip_blue_highlight);

        // @include breakpoint(large) {
        //   svg {
        //
        //     position: fixed;
        //     top: 50vh;
        //     right: 0;
        //     height: 50vw;
        //     width: 50vw;
        //     transform: translateY(-50%);
        //   }
        // }

        .reip-hero-content {
          color: white;
        }
      }
    }

    .reip-detail-body {
      .slick-dots li button .slick-dot-icon {
        color: color(reip_blue_highlight);
      }
      .slick-dots li.slick-active button .slick-dot-icon {
        color: color(reip_blue);
      }
      p span {
        background-color: color(reip_blue_highlight);
      }
    }
    .reip-detail-wrap-up {
      .wrap-up-audio {
        .wrap-up-audio_hero {
          border-color: color(reip_blue);
        }
      }
      .wrap-up-audio_content {
        .cta-hear-a-scenario {
          background: color(reip_blue_light);
          border-color: color(reip_blue);
          i {
            background: color(reip_blue);
          }

          &:hover, &.play {
            background: color(reip_blue);
            color: white;
            i {
              background: white;

              &:before {
                border-left-color: color(reip_blue);
              }
            }
          }
        }
        .cta-guide {
          border-color: color(reip_blue);
          &:hover {
            background-color: color(reip_blue);
            color: white;
          }
        }
        .wrap-player {
          .audio-player {
            .timeline {
              background: color(reip_blue_light);
              .progress {
                background: color(reip_blue);
              }
            }
            .controls {
              .toggle-play {
                .pp-play {
                  fill: color(reip_blue);
                }
                .pp-pause {
                  stroke: color(reip_blue);
                }
                &.play .pp-play{
                  opacity: 0.5;
                }
                &.pause .pp-pause {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }
    .you-can-choose-both {
      &_form-container {
        &_fields {
          border-color: color(reip_blue);
          input {
            &[type="submit"] {
              &:hover {
                background-color: color(reip_blue);
              }
            }
          }
          .border {
            &.top {
              border-color: color(reip_blue);
            }
            &.right {
              border-color: color(reip_blue);
            }
          }
          .recaptcha {
            border-color: color(reip_blue);
          }
        }
        &_graphic {
          .reip-share-graphic {
            border-color: color(reip_blue);
          }
        }
      }
    }
  }
  &.section-3 {
    .reip-detail-hero {
      background-color: color(reip_green_highlight);
    }
    .reip-detail-body {
      .slick-dots li button .slick-dot-icon {
        color: color(reip_green_highlight);
      }
      .slick-dots li.slick-active button .slick-dot-icon {
        color: color(reip_green);
      }
      p span {
        background-color: color(reip_green_highlight);
      }
    }
    .reip-detail-wrap-up {
      .wrap-up-audio {
        .wrap-up-audio_hero {
          border-color: color(reip_green);
        }
      }
      .wrap-up-audio_content {
        .cta-hear-a-scenario {
          background: color(reip_green_light);
          border-color: color(reip_green);
          i {
            background: color(reip_green);
          }

          &:hover, &.play {
            background: color(reip_green);

            i {
              background: white;

              &:before {
                border-left-color: color(reip_green);
              }
            }
          }
        }
        .cta-guide {
          border-color: color(reip_green);
          &:hover {
            background-color: color(reip_green);
          }
        }
        .wrap-player {
          .audio-player {
            .timeline {
              background: color(reip_green_light);
              .progress {
                background: color(reip_green);
              }
            }
            .controls {
              .toggle-play {
                .pp-play {
                  fill: color(reip_green);
                }
                .pp-pause {
                  stroke: color(reip_green);
                }
                &.play .pp-play{
                  opacity: 0.5;
                }
                &.pause .pp-pause {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }
    .you-can-choose-both {
      &_form-container {
        &_fields {
          border-color: color(reip_green);
          input {
            &[type="submit"] {
              &:hover {
                background-color: color(reip_green);
              }
            }
          }
          .border {
            &.top {
              border-color: color(reip_green);
            }
            &.right {
              border-color: color(reip_green);
            }
          }
          .recaptcha {
            border-color: color(reip_green);
          }
        }
        &_graphic {
          .reip-share-graphic {
            border-color: color(reip_green);
          }
        }
      }
    }
  }
  &.section-4 {
    .reip-detail-hero {
      background-color: #76777C;
      .reip-hero-content {
        color: white!important;
      }
    }
    .reip-detail-body {
      .slick-dots li button .slick-dot-icon {
        color: color(reip_black_highlight);
      }
      .slick-dots li.slick-active button .slick-dot-icon {
        color: color(reip_black);
      }
      p span {
        background-color: color(reip_black_highlight);
      }
    }
    .reip-detail-wrap-up {
      .wrap-up-audio {
        .wrap-up-audio_hero {
          border-color: color(reip_black);
        }
      }
      .wrap-up-audio_content {
        .cta-hear-a-scenario {
          background: color(reip_black_light);
          border-color: color(reip_black);
          i {
            background: color(reip_black);
          }

          &:hover, &.play {
            background: color(reip_black);
            color: white;

            i {
              background: white;

              &:before {
                border-left-color: color(reip_black);
              }
            }
          }
        }
        .cta-guide {
          border-color: color(reip_black);
          &:hover {
            background-color: color(reip_black);
          }
        }
        .wrap-player {
          .audio-player {
            .timeline {
              background: color(reip_black_light);
              .progress {
                background: color(reip_black);
              }
            }
            .controls {
              .toggle-play {
                .pp-play {
                  fill: color(reip_black);
                }
                .pp-pause {
                  stroke: color(reip_black);
                }
                &.play .pp-play{
                  opacity: 0.5;
                }
                &.pause .pp-pause {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }
    .you-can-choose-both {
      &_form-container {
        &_fields {
          border-color: white;
          input {
            &[type="submit"] {
              &:hover {
                background-color: white;
                color: color(reip_black);
              }
            }
          }
          .border {
            &.top {
              border-color: white;
            }
            &.right {
              border-color: white;
            }
          }
          .recaptcha {
            border-color: color(white);
          }
        }
        &_graphic {
          .reip-share-graphic {
            border-color: white;
          }
        }
      }
    }
  }
  &.section-5 {
    .reip-detail-hero {
      background-color: color(reip_yellow_highlight);
      svg {
        transform: scale(2.46);
        transform-origin: 50% 46.7%;
        @include breakpoint(large){
          transform: scale(2.46);
          transform-origin: 50% 84.5%;
        }
      }
    }
    .reip-detail-body {
      .slick-dots li button .slick-dot-icon {
        color: color(reip_yellow_highlight);
      }
      .slick-dots li.slick-active button .slick-dot-icon {
        color: color(reip_yellow);
      }
      p span {
        background-color: color(reip_yellow_highlight);
      }
    }
    .reip-detail-wrap-up {
      .wrap-up-audio {
        .wrap-up-audio_hero {
          border-color: color(reip_yellow);
        }
      }
      .wrap-up-audio_content {
        .cta-hear-a-scenario {
          background: color(reip_yellow_light);
          border-color: color(reip_yellow);
          i {
            background: color(reip_yellow);
          }

          &:hover, &.play {
            background: color(reip_yellow);

            i {
              background: white;

              &:before {
                border-left-color: color(reip_yellow);
              }
            }
          }
        }
        .cta-guide {
          border-color: color(reip_yellow);
          &:hover {
            background-color: color(reip_yellow);
          }
        }
        .wrap-player {
          .audio-player {
            .timeline {
              background: color(reip_yellow_light);
              .progress {
                background: color(reip_yellow);
              }
            }
            .controls {
              .toggle-play {
                .pp-play {
                  fill: color(reip_yellow);
                }
                .pp-pause {
                  stroke: color(reip_yellow);
                }
                &.play .pp-play{
                  opacity: 0.5;
                }
                &.pause .pp-pause {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }
    .you-can-choose-both {
      &_form-container {
        &_fields {
          border-color: color(reip_yellow);
          input {
            &[type="submit"] {
              &:hover {
                background-color: color(reip_yellow);
              }
            }
          }
          .border {
            &.top {
              border-color: color(reip_yellow);
            }
            &.right {
              border-color: color(reip_yellow);
            }
          }
          .recaptcha {
            border-color: color(reip_yellow);
          }
        }
        &_graphic {
          .reip-share-graphic {
            border-color: color(reip_yellow);
          }
        }
      }
    }
  }

}
