@charset 'utf-8';

@import 'settings';
@import '~foundation-sites/scss/foundation';
@import '~motion-ui/motion-ui.scss';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
// @include foundation-forms;
@include foundation-accordion;
@include foundation-dropdown;
@include foundation-responsive-embed;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-visibility-classes;
@include foundation-float-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import "~@accessible360/accessible-slick/slick/slick.scss";
// @import "~@accessible360/accessible-slick/slick/accessible-slick-theme.scss";

@import "base/all";
@import "components/all";
@import "layout/all";
@import "modules/all";
@import "pages/all";
@import "vendor/cookieconsent";

@import "layout/header-reip";