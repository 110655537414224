.equity-hero-tag {
    display: block;
    @include font-family(TheinhardtMedium);
    font-size: 24px;
    line-height: 1;
    color: color(blue-accesible);
    letter-spacing: -1.06px;
    margin-bottom: 10px;
}

.equity-hero-headline {
    @include font-family(TheinhardtMedium);
    color: color(black);
    letter-spacing: -2.6px;
    line-height: 42px;
    font-size: 42px;

    span {
        color: color(blue-accesible);
    }

    @include breakpoint(medium) {
        letter-spacing: -2.4px;
        line-height: 80px;
        font-size: 80px;
    }

    @include breakpoint(large) {
        letter-spacing: -4.4px;
        line-height: 90px;
        font-size: 100px;
    }

    &.white {
        display: block;
        color: white;
        letter-spacing: -1.7px;
        font-size: 40px;
        line-height: 36px;

        @include breakpoint(large) {
            font-size: 75px;
            letter-spacing: -3.3px;
            line-height: 67px;
        }
    }
}

.equity-hero-subheadline {
    display: block;
    @include font-family(PlantinMTProLightItalic);
    color: color(black);
    letter-spacing: -0.5px;
    line-height: 50px;
    font-size: 25px;
    margin-top: 10px;

    @include breakpoint(large) {
        letter-spacing: -0.8px;
        font-size: 40px;
        margin-top: 30px;
    }
}

.equity-subheadline {
    position: relative;
    display: block;
    @include font-family(PlantinMTProLightItalic);
    font-size: 35px;
    color: color(blue-accesible);
    letter-spacing: -0.35px;
    line-height: 55px;
    margin-bottom: 40px;

    @include breakpoint(large) {
        font-size: 48px;
        line-height: 64px;
        letter-spacing: -0.5px;
        margin-bottom: 0px;
    }

    &.black {
        @include font-family(PlantinMTProLight);
        color: color(black);
        line-height: 35px;

        @include breakpoint(large) {
            line-height: 48px;
        }
    }

    span {
        position: relative;
        @include font-family(TheinhardtBold);
        color: color(blue);

        svg {
            max-width: 140%;
            position: absolute;
            top: -10px;
            left: -10px;

            @include breakpoint(large) {
                top: -5px;
            }
        }
    }

    #taking-action-line-one {
        position: absolute;
        top: 55px;
        left: 0;

        @include breakpoint(small only) {
            top: 45px;
            max-width: 190px;
        }

        @include breakpoint(medium only) {
            top: 45px;
            max-width: 195px;
        }
    }

    #taking-action-line-two {
        position: absolute;
        bottom: 10px;
        left: 0;

        @include breakpoint(small only) {
            max-width: 131px;
        }

        @include breakpoint(medium only) {
            max-width: 131px;
        }
    }
}

.equity-headline-white {
    display: block;
    @include font-family(TheinhardtMedium);
    color: white;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
}

.equity-subheadline-white {
    display: block;
    @include font-family(TheinhardtRegular);
    color: white;
    font-size: 16px;
    line-height: 22px;

    @include breakpoint(large){
        font-size: 20px;
        line-height: 28px;
    }

    em {
        font-style: normal;
        @include font-family(TheinhardtRegularItalic);
    }
}

.equity-white-big-paragraph {
    @include font-family(TheinhardtMedium);
    font-size: 18px;
    line-height: 26px;
    color: white;

    @include breakpoint(large){
        font-size: 30px;
        line-height: 30px;
        letter-spacing: -0.85px;
    }
}


.equity-big-headline {
    display: block;
    @include font-family(TheinhardtMedium);
    color: color(black);
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 30px;

    @include breakpoint(large) {
        font-size: 70px;
        line-height: 80px;
        letter-spacing: -3.3px;
    }

    .globe {
        position: relative;
        display: inline-block;
        padding: 0 10px;

        svg {
            position: absolute;
            top: -2px;
            left: 0px;
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
}

.equity-big-subheadline {
    display: block;
    font-family: Theinhardt-Regular;
    color: color(black);
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 60px;

    @include breakpoint(large) {
        font-size: 30px;
        line-height: 65px;
    }

    .underline {
        display: inline-block;
        position: relative;

        svg {
            position: absolute;
            bottom: 0;
            left: 0px;
            display: block;
            max-width: 100%;
        }
    }
}

.equity-blue-head {
    display: block;
    font-family: Theinhardt-Medium;
    color: #4C71FF;
    letter-spacing: 0;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 5px;
    margin-top: 40px;

    @include breakpoint(large) {
        font-size: 19px;
        line-height: 22px;
    }
}

.equity-quote {
    display: inline-block;
    vertical-align: top;
    max-width: 560px;
    @include font-family(PlantinMTProLightItalic);
    color: color(black);
    letter-spacing: -0.2px;
    font-size: 30px;
    line-height: 35px;
    text-align: left;
    margin-top: 50px;

    @include breakpoint(large) {
        
    }
}


.equity-blue-bold {
    @include font-family(TheinhardtBold);
    color: color(blue);
}