
// Buttons

.button:not(.hamburger),
button:not(.hamburger) {
  margin-bottom: 0;
  background-color: color(blue);
  @include font-family(TheinhardtBold);
  font-size: 20px;

  &:hover,
  &:active {
    background-color: color(black);
  }

  &.button--small {
    font-size: 14px;
  }
}
