header {

    &.choose-both-header {
        position: fixed;
        padding-bottom: 11px;
        padding-top: 15px;
        transition: all 0.5s ease;
        // box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
        // background-color: white;

        @include breakpoint(medium) {
            padding-bottom: 24px;
            padding-top: 30px;
        }

        body.page-template-templatestemplate-reip-detail-php & {
          background: transparent;
        }

        .tools {
          top: 14px;
          @media (min-width: 1200px) {
            top: 35px;
            right: 50px;
            body.choose-both-nav-expanded & {
              right: 50px;
            }
          }
        }

        .btn-lang {
          display: none;
          @include breakpoint(large) {
            display: inline-block;
          }
        }

        &.op0 {
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
            background-color: white;
            opacity: 0;
            nav.equity-nav {
                //opacity: 1;
            }
        }

        &.logo-symbol-only {
            svg .logo-letter {
                display: none;
            }
        }

        .logo {
            @include breakpoint(medium only) {
                margin-top: -12px;
            }

            @media screen and (min-width: 1024px) and (max-width: 1360px) {
                svg .logo-letter {
                    display: none;
                }
            }
        }

        .logo-choose-both {
          position: absolute;
          top: 5px;
          left: 50%;
          width: 126px;
          transform: translateX(-50%);

          @include breakpoint(large) {
            width: 177px;
            top: 25px;
          }

          svg {
            display: block;
            max-width: 100%;
          }

          body.choose-both-nav-expanded & {
            svg {
              path {
                fill: white;
              }
              rect {
                stroke: white;
                fill: #1A1C24;
              }
            }
          }
        }


        // .hamburger {
        //     @include breakpoint(small only) {
        //         top: 22px;
        //     }
        //
        //     @include breakpoint(large) {
        //         top: 40px;
        //     }
        // }

        .mobile-nav-headline {
            @include breakpoint(large) {
                display: none;
            }
        }

        body.choose-both-nav-expanded & {
            background-color: color(black);
            box-shadow: none;

            .logo {
                svg {
                  .logo-main-group {
                    fill: white;
                  }
                }
            }

            .hamburger {
                span {
                  background: white;
                }
            }

            .btn-arrow {
                background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSI3cHgiIHZpZXdCb3g9IjAgMCAxMSA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xODEuMDAwMDAwLCAtNDkuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIj48cG9seWxpbmUgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTg3LjAwMDAwMCwgNTIuNTAwMDAwKSBzY2FsZSgtMSwgLTEpIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0xODcuMDAwMDAwLCAtNTIuNTAwMDAwKSAiIHBvaW50cz0iMTkyIDU1IDE4Ni45OTk1NjYgNTAgMTgyIDU1Ij48L3BvbHlsaW5lPjwvZz48L2c+PC9zdmc+') center center no-repeat;
                height: 25px;
                margin-top: -10px;
                z-index: 20;
                position: relative;
            }

            #nav-headline {
                transform: translateY(70px);
                a {
                    pointer-events: initial;
                    color: white;
                }
            }
        }
    }

    nav.choose-both-nav {
        // opacity: 0;
        transition: opacity 0.3s ease;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        width: max-content;
        text-align: center;

        .btn-arrow {
            display: block;
            margin-top: -5px;
            height: 10px;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSI3cHgiIHZpZXdCb3g9IjAgMCAxMSA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4Mi4wMDAwMDAsIC00OS4wMDAwMDApIiBzdHJva2U9IiMxRDFFMjEiPgogICAgICAgICAgICA8cG9seWxpbmUgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTg3LjAwMDAwMCwgNTIuNTAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTg3LjAwMDAwMCwgLTUyLjUwMDAwMCkgIiBwb2ludHM9IjE5MiA1NSAxODYuOTk5NTY2IDUwIDE4MiA1NSI+PC9wb2x5bGluZT4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==') bottom center no-repeat;
        }

        #nav-headline {
            a {
                pointer-events: none;
            }
        }

        span {
            display: inline-block;
            vertical-align: top;
            @include font-family(TheinhardtMedium);
            font-size: 16px;
            line-height: 16px;
            color: color(black);
            transition: all .2s linear;

            a {
                color: color(black);
            }

            @include breakpoint(medium){
                font-size: 18px;
                line-height: 18px;
                background: none;
            }

            @include breakpoint(large) {
                margin-right: 25px;
                font-size: 21px;
                line-height: 21px;
            }
        }

        ul {
            display: none;

            @include breakpoint(large) {
                display: inline-block;
                vertical-align: middle;
            }

            li {
                display: inline-block;
                @include font-family(TheinhardtMedium);
                font-size: 16px;
                line-height: 16px;
                color: color(black);
                margin: 0 20px;

                @include breakpoint(medium){
                    font-size: 21px;
                    line-height: 21px;
                }

                &:not(:first-child) {
                    display: none;

                    @include breakpoint(medium){
                        display: inline-block;
                    }
                }

                &.current_page_item {
                    a {
                        color: color(blue-accesible);
                    }
                }

                a {
                    position: relative;
                    font-size: 15px;
                    color: color(black);
                    line-height: 26px;
                    padding-top: 10px;

                    &:hover {
                        color: color(blue-accesible);
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: color(black);
                        height: 2px;
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}



.off-canvas-choose-both-nav {
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: color(black);
    padding: 140px 20px 50px 20px;
    z-index: 1;
    text-align: left;
    transition: top .3s ease;
    @include breakpoint(large){
      padding-top: 260px;
    }

    body.choose-both-nav-expanded & {
        top: 0;
        z-index: 20;
    }

    nav {
      ul {
        @include breakpoint(large){
          max-width: 1000px;
          margin: 0 auto;
          -webkit-column-count: 2;
          -webkit-column-gap: 30px;
          column-count: 2;
          column-gap: 30px;
        }

        li {
          display: block;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            @include font-family(TheinhardtRegular);
            font-size: 26px;
            line-height: 27px;
            color: #FFFFFF;
            padding-top: 5px;

            @include breakpoint(large){
              font-size: 40px;
              line-height: 42px;
            }

            &:hover {
              color: #BABBBD;
            }
          }
        }
      }
    }

  }
