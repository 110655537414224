
// Fonts

//@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i,900,900i');

@font-face {
  font-family: 'PlantinMTPro-Light';
  src: url('/src/fonts/PlantinMTPro-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlantinMTPro-LightItalic';
  src: url('/src/fonts/PlantinMTPro-LightItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Theinhardt-Bold';
  src: url('/src/fonts/Theinhardt-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Theinhardt-Regular';
  src: url('/src/fonts/Theinhardt-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Theinhardt-Regular-Italic';
  src: url('/src/fonts/Theinhardt-RegularIta.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Theinhardt-Medium';
  src: url('/src/fonts/Theinhardt-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Theinhardt-Thin';
  src: url('/src/fonts/Theinhardt-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin font-family($family) {
  // @if $family == primary {
  //   font-family: 'Lato', sans-serif;
  // }
  @if $family == secondary {
    font-family: sans-serif;
  }
  @if $family == PlantinMTProLight {
    font-family: 'PlantinMTPro-Light';
  }
  @if $family == PlantinMTProLightItalic {
    font-family: 'PlantinMTPro-LightItalic';
  }
  @if $family == TheinhardtRegular {
    font-family: 'Theinhardt-Regular';
  }
  @if $family == TheinhardtRegularItalic {
    font-family: 'Theinhardt-Regular-Italic';
  }
  @if $family == TheinhardtMedium {
    font-family: 'Theinhardt-Medium';
  }
  @if $family == TheinhardtBold {
    font-family: 'Theinhardt-Bold';
  }
  @if $family == TheinhardtThin {
    font-family: 'Theinhardt-Thin';
  }
  @if $family == LibreBaskerville {
    font-family: 'Libre Baskerville', serif;
  }
  @if $family == WorkSans {
    font-family: 'Work Sans', sans-serif;
  }
}

@mixin font-weight($weight) {
  @if $weight == extra-light {
    font-weight: 200;
  }
  @if $weight == light {
    font-weight: 300;
  }
  @if $weight == regular {
    font-weight: 400;
  }
  @if $weight == medium {
    font-weight: 500;
  }
  @if $weight == bold {
    font-weight: 600;
  }
  @if $weight == extra-bold {
    font-weight: 900;
  }
}
