
// Footer

footer {
  display: block;
  position: relative;

  &.show {

    .footer-content {
      @include breakpoint(large){
        opacity: 1;
        z-index: 2;
      }
    }
  }

  .footer-spacer {
    display: none;
    max-width: $global-width;
    margin: 0 auto;
    @include breakpoint(large){
      display:block;
    }

  }

  .footer-content {
    max-width: $global-width;
    margin: 0 auto;
    position: relative;
    padding: 100px 0 30px 0;

    @include breakpoint(large){
      opacity: 0;
      position: fixed;
      z-index: -1;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }

    @include breakpoint(xlarge){
      padding: 240px 0 30px 0;
    }

    .logo {
      display: none;
      width: 40px;
      height: 47px;

      @include breakpoint(large){
        display: block;
        position: absolute;
        top: 240px;
        left: 1.875rem;
      }

      span {
        display: none;
        background: url('../images/logo.svg');
      }

      svg {
        .logo-letter {
          opacity: 0;
        }
      }
    }

    .nav-container {

      .btn-backup {
        display: block;
      }

      // Changes only for Mobile
      @media (max-width: 767px) {

          .primary-nav {
          ul {
            li {
              a {
                text-align: left;
              }
            }
          }
        }

          .secondary-nav {
          float: none;
          ul {
            li {
              a {
                text-align: left;
              }
            }
          }
        }

          .clocks-container {
          display: block;
          margin-top: 40px;

          ul {
            li {
              width: 100%;
              margin-bottom: 30px;

              .clock {
                margin-right: 30px;
                margin-bottom: 0;
              }
              .label {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                margin-top: 15px;
              }
            }
          }
        }

      }

      @include breakpoint(large){

        .primary-nav {
          position: relative;

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            left: -100px;
            width: 40px;
            height: 63px;
            background: url('../images/logo-certified-corp.png') 0 0 no-repeat;
            background-size: contain;
          }
        }
      }
    }

    .copyright {
      display: block;

      .b-logo {
        float: right;
        width: 25px;
        height: 40px;
        background: url('../images/logo-certified-corp.png') 0 0 no-repeat;
        background-size: contain;

        @include breakpoint(large){
          display: none;
        }
      }

      p {
        @include font-family(TheinhardtRegular);
        font-size: 14px;
        line-height: 24px;
        color: color(black);
        letter-spacing: 0;
        opacity: 0.4;
        width: 70%;
        float: left;

        .separator {
          display: none;
          margin: 0 10px;

          @include breakpoint(large){
            display: inline-block;
          }
        }

        a {
          color: color(black);
          text-decoration: underline;

          &:hover,
          &:active,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }


    @include shortscreen(){
      .clocks-container{ display:none; }
    }


  }
}

.btn-footer-equity-frameworks {
  @include font-family(TheinhardtBold);
  background: white;
  border-left: 6px solid color(blue);
  border-right: 6px solid color(blue);
  border-top: 6px solid color(blue);
  bottom: 0;
  color: color(black);
  font-size: 16px;
  line-height: 18px;
  padding: 20px;
  position: fixed;
  right: 30px;
  z-index: 100;

  &:hover {
    color: color(blue);
  }
}
