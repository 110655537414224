// Typography

body {
  @include font-family(primary);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: 1;
}

hr{
    border-bottom: 1px solid color(neutral-grey);
}

.section-headline {
  display: block;
  @include font-family(PlantinMTProLight);
  font-size: 22px;
  line-height: 1;
  color: color(black);
  letter-spacing: 0;
  margin-bottom: 45px;

  body.page-template-template-labs-home & {
    @include font-family(TheinhardtBold);
  }

  span {
    @include font-family(TheinhardtBold);
  }

  @include breakpoint(large){
    font-size: 40px;
    margin-bottom: 80px;
  }

  &.center {
    text-align: center;
  }
}

.btn-see-more {
  display: inline-block;
  @include font-family(TheinhardtMedium);
  font-size: 16px;
  color: color(black);
  letter-spacing: 0;
  padding-bottom: 3px;
  border-bottom: 1px solid color(black);
  margin-top: 50px;

  @include breakpoint(large){
    font-size: 20px;
  }

  body.page-template-template-labs-home & {
    display: none;
  }

  &:hover,
  &:active,
  &:focus {
    color: color(blue);
    border-color: color(blue);
  }
}

a.nowrap{
  white-space: nowrap;
}
