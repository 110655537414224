// see ApplyClassOnScroll.coffee
// CSS from https://github.com/daneden/animate.css


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

body.single-case_studies section:not(.off-canvas-nav){
  h2,h3,h4,[data-fadein],p:not([data-no-fadein]){
    opacity: 0;
  }
}

[data-fadein]:not(.fadeInUp){
  opacity:0;
}

.fadeInUp {
  animation-name: fadeInUp;
}



.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}


@media (prefers-reduced-motion) {
  .animated {
    animation: unset !important;
    transition: none !important;
  }
}
