@media print, screen and (min-width: 48em) {
  .reveal.large, .reveal.small, .reveal.tiny, .reveal {
    margin: 0 auto;
    left: auto;
    right: auto;
  }
}

.slide-in-down.mui-enter {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateY(-100%);
}

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-left.mui-enter {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateX(-100%);
}

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-in-up.mui-enter {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateY(100%);
}

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-right.mui-enter {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateX(100%);
}

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-out-down.mui-leave {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateY(0);
}

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%);
}

.slide-out-right.mui-leave {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateX(0);
}

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%);
}

.slide-out-up.mui-leave {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateY(0);
}

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%);
}

.slide-out-left.mui-leave {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateX(0);
}

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%);
}

.fade-in.mui-enter {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
}

.fade-in.mui-enter.mui-enter-active {
  opacity: 1;
}

.fade-out.mui-leave {
  opacity: 1;
  transition-property: opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
}

.fade-out.mui-leave.mui-leave-active {
  opacity: 0;
}

.hinge-in-from-top.mui-enter {
  transform-origin: top;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateX(-90deg);
}

.hinge-in-from-top.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-in-from-right.mui-enter {
  transform-origin: 100%;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateY(-90deg);
}

.hinge-in-from-right.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-in-from-bottom.mui-enter {
  transform-origin: bottom;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateX(90deg);
}

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-in-from-left.mui-enter {
  transform-origin: 0;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateY(90deg);
}

.hinge-in-from-left.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-in-from-middle-x.mui-enter {
  transform-origin: center;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateX(-90deg);
}

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-in-from-middle-y.mui-enter {
  transform-origin: center;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateY(-90deg);
}

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-top.mui-leave {
  transform-origin: top;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-top.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateX(90deg);
}

.hinge-out-from-right.mui-leave {
  transform-origin: 100%;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-right.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateY(90deg);
}

.hinge-out-from-bottom.mui-leave {
  transform-origin: bottom;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateX(-90deg);
}

.hinge-out-from-left.mui-leave {
  transform-origin: 0;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-left.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateY(-90deg);
}

.hinge-out-from-middle-x.mui-leave {
  transform-origin: center;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateX(90deg);
}

.hinge-out-from-middle-y.mui-leave {
  transform-origin: center;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateY(90deg);
}

.scale-in-up.mui-enter {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: scale(.5);
}

.scale-in-up.mui-enter.mui-enter-active {
  opacity: 1;
  transform: scale(1);
}

.scale-in-down.mui-enter {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: scale(1.5);
}

.scale-in-down.mui-enter.mui-enter-active {
  opacity: 1;
  transform: scale(1);
}

.scale-out-up.mui-leave {
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: scale(1);
}

.scale-out-up.mui-leave.mui-leave-active {
  opacity: 0;
  transform: scale(1.5);
}

.scale-out-down.mui-leave {
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: scale(1);
}

.scale-out-down.mui-leave.mui-leave-active {
  opacity: 0;
  transform: scale(.5);
}

.spin-in.mui-enter {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: rotate(-.75turn);
}

.spin-in.mui-enter.mui-enter-active {
  opacity: 1;
  transform: rotate(0);
}

.spin-out.mui-leave {
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: rotate(0);
}

.spin-out.mui-leave.mui-leave-active {
  opacity: 0;
  transform: rotate(.75turn);
}

.spin-in-ccw.mui-enter {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: rotate(.75turn);
}

.spin-in-ccw.mui-enter.mui-enter-active {
  opacity: 1;
  transform: rotate(0);
}

.spin-out-ccw.mui-leave {
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: rotate(0);
}

.spin-out-ccw.mui-leave.mui-leave-active {
  opacity: 0;
  transform: rotate(-.75turn);
}

.slow {
  transition-duration: .75s !important;
}

.fast {
  transition-duration: .25s !important;
}

.linear {
  transition-timing-function: linear !important;
}

.ease {
  transition-timing-function: ease !important;
}

.ease-in {
  transition-timing-function: ease-in !important;
}

.ease-out {
  transition-timing-function: ease-out !important;
}

.ease-in-out {
  transition-timing-function: ease-in-out !important;
}

.bounce-in {
  transition-timing-function: cubic-bezier(.485, .155, .24, 1.245) !important;
}

.bounce-out {
  transition-timing-function: cubic-bezier(.485, .155, .515, .845) !important;
}

.bounce-in-out {
  transition-timing-function: cubic-bezier(.76, -.245, .24, 1.245) !important;
}

.short-delay {
  transition-delay: .3s !important;
}

.long-delay {
  transition-delay: .7s !important;
}

.shake {
  animation-name: shake-7;
}

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%);
  }

  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%);
  }
}

.spin-cw {
  animation-name: spin-cw-1turn;
}

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn);
  }

  100% {
    transform: rotate(0);
  }
}

.spin-ccw {
  animation-name: spin-ccw-1turn;
}

@keyframes spin-ccw-1turn {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-1turn);
  }
}

.wiggle {
  animation-name: wiggle-7deg;
}

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg);
  }

  35%, 45%, 55%, 65% {
    transform: rotate(-7deg);
  }

  0%, 30%, 70%, 100% {
    transform: rotate(0);
  }
}

.shake, .spin-cw, .spin-ccw, .wiggle {
  animation-duration: .5s;
}

.infinite {
  animation-iteration-count: infinite;
}

.slow {
  animation-duration: .75s !important;
}

.fast {
  animation-duration: .25s !important;
}

.linear {
  animation-timing-function: linear !important;
}

.ease {
  animation-timing-function: ease !important;
}

.ease-in {
  animation-timing-function: ease-in !important;
}

.ease-out {
  animation-timing-function: ease-out !important;
}

.ease-in-out {
  animation-timing-function: ease-in-out !important;
}

.bounce-in {
  animation-timing-function: cubic-bezier(.485, .155, .24, 1.245) !important;
}

.bounce-out {
  animation-timing-function: cubic-bezier(.485, .155, .515, .845) !important;
}

.bounce-in-out {
  animation-timing-function: cubic-bezier(.76, -.245, .24, 1.245) !important;
}

.short-delay {
  animation-delay: .3s !important;
}

.long-delay {
  animation-delay: .7s !important;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

[data-whatintent="mouse"] *, [data-whatintent="mouse"] :focus, [data-whatintent="touch"] *, [data-whatintent="touch"] :focus, [data-whatinput="mouse"] *, [data-whatinput="mouse"] :focus, [data-whatinput="touch"] *, [data-whatinput="touch"] :focus {
  outline: none;
}

[draggable="false"] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.foundation-mq {
  font-family: "small=0em&medium=48em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fefefe;
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img, .map_canvas embed, .map_canvas object, .mqa-display img, .mqa-display embed, .mqa-display object {
  max-width: none !important;
}

button {
  -webkit-appearance: none;
  appearance: none;
  cursor: auto;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  line-height: 1;
}

[data-whatinput="mouse"] button {
  outline: 0;
}

pre {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 81.25rem;
  margin-left: auto;
  margin-right: auto;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row.collapse > .column, .row.collapse > .false {
  padding-left: 0;
  padding-right: 0;
}

.row .row {
  margin-left: -1.5625rem;
  margin-right: -1.5625rem;
}

@media print, screen and (min-width: 48em) {
  .row .row {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row .row {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
}

@media screen and (min-width: 75em) {
  .row .row {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
}

.row .row.collapse {
  margin-left: 0;
  margin-right: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-left: auto;
  margin-right: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.row.gutter-small > .column, .row.gutter-small > .false {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.row.gutter-medium > .column, .row.gutter-medium > .false {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.column, .false {
  width: 100%;
  float: left;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

@media print, screen and (min-width: 48em) {
  .column, .false {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.column, .false, .column:last-child:not(:first-child) {
  float: left;
  clear: none;
}

.column:last-child:not(:first-child), .false:last-child:not(:first-child) {
  float: right;
}

.column.end:last-child:last-child, .end.false:last-child:last-child {
  float: left;
}

.column.row.row, .row.row.false {
  float: none;
}

.row .column.row.row, .row .row.row.false {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.small-1 {
  width: 8.33333%;
}

.small-push-1 {
  position: relative;
  left: 8.33333%;
}

.small-pull-1 {
  position: relative;
  left: -8.33333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  width: 16.6667%;
}

.small-push-2 {
  position: relative;
  left: 16.6667%;
}

.small-pull-2 {
  position: relative;
  left: -16.6667%;
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.small-3 {
  width: 25%;
}

.small-push-3 {
  position: relative;
  left: 25%;
}

.small-pull-3 {
  position: relative;
  left: -25%;
}

.small-offset-2 {
  margin-left: 16.6667%;
}

.small-4 {
  width: 33.3333%;
}

.small-push-4 {
  position: relative;
  left: 33.3333%;
}

.small-pull-4 {
  position: relative;
  left: -33.3333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  width: 41.6667%;
}

.small-push-5 {
  position: relative;
  left: 41.6667%;
}

.small-pull-5 {
  position: relative;
  left: -41.6667%;
}

.small-offset-4 {
  margin-left: 33.3333%;
}

.small-6 {
  width: 50%;
}

.small-push-6 {
  position: relative;
  left: 50%;
}

.small-pull-6 {
  position: relative;
  left: -50%;
}

.small-offset-5 {
  margin-left: 41.6667%;
}

.small-7 {
  width: 58.3333%;
}

.small-push-7 {
  position: relative;
  left: 58.3333%;
}

.small-pull-7 {
  position: relative;
  left: -58.3333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  width: 66.6667%;
}

.small-push-8 {
  position: relative;
  left: 66.6667%;
}

.small-pull-8 {
  position: relative;
  left: -66.6667%;
}

.small-offset-7 {
  margin-left: 58.3333%;
}

.small-9 {
  width: 75%;
}

.small-push-9 {
  position: relative;
  left: 75%;
}

.small-pull-9 {
  position: relative;
  left: -75%;
}

.small-offset-8 {
  margin-left: 66.6667%;
}

.small-10 {
  width: 83.3333%;
}

.small-push-10 {
  position: relative;
  left: 83.3333%;
}

.small-pull-10 {
  position: relative;
  left: -83.3333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  width: 91.6667%;
}

.small-push-11 {
  position: relative;
  left: 91.6667%;
}

.small-pull-11 {
  position: relative;
  left: -91.6667%;
}

.small-offset-10 {
  margin-left: 83.3333%;
}

.small-12 {
  width: 100%;
}

.small-offset-11 {
  margin-left: 91.6667%;
}

.small-up-1 > .column, .small-up-1 > .false {
  float: left;
  width: 100%;
}

.small-up-1 > .column:nth-of-type(n), .small-up-1 > .false:nth-of-type(n) {
  clear: none;
}

.small-up-1 > .column:nth-of-type(n+1), .small-up-1 > .false:nth-of-type(n+1) {
  clear: both;
}

.small-up-1 > .column:last-child, .small-up-1 > .false:last-child {
  float: left;
}

.small-up-2 > .column, .small-up-2 > .false {
  float: left;
  width: 50%;
}

.small-up-2 > .column:nth-of-type(n), .small-up-2 > .false:nth-of-type(n) {
  clear: none;
}

.small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .false:nth-of-type(2n+1) {
  clear: both;
}

.small-up-2 > .column:last-child, .small-up-2 > .false:last-child {
  float: left;
}

.small-up-3 > .column, .small-up-3 > .false {
  float: left;
  width: 33.3333%;
}

.small-up-3 > .column:nth-of-type(n), .small-up-3 > .false:nth-of-type(n) {
  clear: none;
}

.small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .false:nth-of-type(3n+1) {
  clear: both;
}

.small-up-3 > .column:last-child, .small-up-3 > .false:last-child {
  float: left;
}

.small-up-4 > .column, .small-up-4 > .false {
  float: left;
  width: 25%;
}

.small-up-4 > .column:nth-of-type(n), .small-up-4 > .false:nth-of-type(n) {
  clear: none;
}

.small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .false:nth-of-type(4n+1) {
  clear: both;
}

.small-up-4 > .column:last-child, .small-up-4 > .false:last-child {
  float: left;
}

.small-up-5 > .column, .small-up-5 > .false {
  float: left;
  width: 20%;
}

.small-up-5 > .column:nth-of-type(n), .small-up-5 > .false:nth-of-type(n) {
  clear: none;
}

.small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .false:nth-of-type(5n+1) {
  clear: both;
}

.small-up-5 > .column:last-child, .small-up-5 > .false:last-child {
  float: left;
}

.small-up-6 > .column, .small-up-6 > .false {
  float: left;
  width: 16.6667%;
}

.small-up-6 > .column:nth-of-type(n), .small-up-6 > .false:nth-of-type(n) {
  clear: none;
}

.small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .false:nth-of-type(6n+1) {
  clear: both;
}

.small-up-6 > .column:last-child, .small-up-6 > .false:last-child {
  float: left;
}

.small-up-7 > .column, .small-up-7 > .false {
  float: left;
  width: 14.2857%;
}

.small-up-7 > .column:nth-of-type(n), .small-up-7 > .false:nth-of-type(n) {
  clear: none;
}

.small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .false:nth-of-type(7n+1) {
  clear: both;
}

.small-up-7 > .column:last-child, .small-up-7 > .false:last-child {
  float: left;
}

.small-up-8 > .column, .small-up-8 > .false {
  float: left;
  width: 12.5%;
}

.small-up-8 > .column:nth-of-type(n), .small-up-8 > .false:nth-of-type(n) {
  clear: none;
}

.small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .false:nth-of-type(8n+1) {
  clear: both;
}

.small-up-8 > .column:last-child, .small-up-8 > .false:last-child {
  float: left;
}

.small-collapse > .column, .small-collapse > .false {
  padding-left: 0;
  padding-right: 0;
}

.small-collapse .row, .expanded.row .small-collapse.row {
  margin-left: 0;
  margin-right: 0;
}

.small-uncollapse > .column, .small-uncollapse > .false {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.small-centered {
  margin-left: auto;
  margin-right: auto;
}

.small-centered, .small-centered:last-child:not(:first-child) {
  float: none;
  clear: both;
}

.small-uncentered, .small-push-0, .small-pull-0 {
  margin-left: 0;
  margin-right: 0;
  position: static;
}

.small-uncentered, .small-uncentered:last-child:not(:first-child), .small-push-0, .small-push-0:last-child:not(:first-child), .small-pull-0, .small-pull-0:last-child:not(:first-child) {
  float: left;
  clear: none;
}

.small-uncentered:last-child:not(:first-child), .small-push-0:last-child:not(:first-child), .small-pull-0:last-child:not(:first-child) {
  float: right;
}

@media print, screen and (min-width: 48em) {
  .medium-1 {
    width: 8.33333%;
  }

  .medium-push-1 {
    position: relative;
    left: 8.33333%;
  }

  .medium-pull-1 {
    position: relative;
    left: -8.33333%;
  }

  .medium-offset-0 {
    margin-left: 0%;
  }

  .medium-2 {
    width: 16.6667%;
  }

  .medium-push-2 {
    position: relative;
    left: 16.6667%;
  }

  .medium-pull-2 {
    position: relative;
    left: -16.6667%;
  }

  .medium-offset-1 {
    margin-left: 8.33333%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-push-3 {
    position: relative;
    left: 25%;
  }

  .medium-pull-3 {
    position: relative;
    left: -25%;
  }

  .medium-offset-2 {
    margin-left: 16.6667%;
  }

  .medium-4 {
    width: 33.3333%;
  }

  .medium-push-4 {
    position: relative;
    left: 33.3333%;
  }

  .medium-pull-4 {
    position: relative;
    left: -33.3333%;
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .medium-5 {
    width: 41.6667%;
  }

  .medium-push-5 {
    position: relative;
    left: 41.6667%;
  }

  .medium-pull-5 {
    position: relative;
    left: -41.6667%;
  }

  .medium-offset-4 {
    margin-left: 33.3333%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-push-6 {
    position: relative;
    left: 50%;
  }

  .medium-pull-6 {
    position: relative;
    left: -50%;
  }

  .medium-offset-5 {
    margin-left: 41.6667%;
  }

  .medium-7 {
    width: 58.3333%;
  }

  .medium-push-7 {
    position: relative;
    left: 58.3333%;
  }

  .medium-pull-7 {
    position: relative;
    left: -58.3333%;
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .medium-8 {
    width: 66.6667%;
  }

  .medium-push-8 {
    position: relative;
    left: 66.6667%;
  }

  .medium-pull-8 {
    position: relative;
    left: -66.6667%;
  }

  .medium-offset-7 {
    margin-left: 58.3333%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-push-9 {
    position: relative;
    left: 75%;
  }

  .medium-pull-9 {
    position: relative;
    left: -75%;
  }

  .medium-offset-8 {
    margin-left: 66.6667%;
  }

  .medium-10 {
    width: 83.3333%;
  }

  .medium-push-10 {
    position: relative;
    left: 83.3333%;
  }

  .medium-pull-10 {
    position: relative;
    left: -83.3333%;
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .medium-11 {
    width: 91.6667%;
  }

  .medium-push-11 {
    position: relative;
    left: 91.6667%;
  }

  .medium-pull-11 {
    position: relative;
    left: -91.6667%;
  }

  .medium-offset-10 {
    margin-left: 83.3333%;
  }

  .medium-12 {
    width: 100%;
  }

  .medium-offset-11 {
    margin-left: 91.6667%;
  }

  .medium-up-1 > .column, .medium-up-1 > .false {
    float: left;
    width: 100%;
  }

  .medium-up-1 > .column:nth-of-type(n), .medium-up-1 > .false:nth-of-type(n) {
    clear: none;
  }

  .medium-up-1 > .column:nth-of-type(n+1), .medium-up-1 > .false:nth-of-type(n+1) {
    clear: both;
  }

  .medium-up-1 > .column:last-child, .medium-up-1 > .false:last-child {
    float: left;
  }

  .medium-up-2 > .column, .medium-up-2 > .false {
    float: left;
    width: 50%;
  }

  .medium-up-2 > .column:nth-of-type(n), .medium-up-2 > .false:nth-of-type(n) {
    clear: none;
  }

  .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .false:nth-of-type(2n+1) {
    clear: both;
  }

  .medium-up-2 > .column:last-child, .medium-up-2 > .false:last-child {
    float: left;
  }

  .medium-up-3 > .column, .medium-up-3 > .false {
    float: left;
    width: 33.3333%;
  }

  .medium-up-3 > .column:nth-of-type(n), .medium-up-3 > .false:nth-of-type(n) {
    clear: none;
  }

  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .false:nth-of-type(3n+1) {
    clear: both;
  }

  .medium-up-3 > .column:last-child, .medium-up-3 > .false:last-child {
    float: left;
  }

  .medium-up-4 > .column, .medium-up-4 > .false {
    float: left;
    width: 25%;
  }

  .medium-up-4 > .column:nth-of-type(n), .medium-up-4 > .false:nth-of-type(n) {
    clear: none;
  }

  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .false:nth-of-type(4n+1) {
    clear: both;
  }

  .medium-up-4 > .column:last-child, .medium-up-4 > .false:last-child {
    float: left;
  }

  .medium-up-5 > .column, .medium-up-5 > .false {
    float: left;
    width: 20%;
  }

  .medium-up-5 > .column:nth-of-type(n), .medium-up-5 > .false:nth-of-type(n) {
    clear: none;
  }

  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .false:nth-of-type(5n+1) {
    clear: both;
  }

  .medium-up-5 > .column:last-child, .medium-up-5 > .false:last-child {
    float: left;
  }

  .medium-up-6 > .column, .medium-up-6 > .false {
    float: left;
    width: 16.6667%;
  }

  .medium-up-6 > .column:nth-of-type(n), .medium-up-6 > .false:nth-of-type(n) {
    clear: none;
  }

  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .false:nth-of-type(6n+1) {
    clear: both;
  }

  .medium-up-6 > .column:last-child, .medium-up-6 > .false:last-child {
    float: left;
  }

  .medium-up-7 > .column, .medium-up-7 > .false {
    float: left;
    width: 14.2857%;
  }

  .medium-up-7 > .column:nth-of-type(n), .medium-up-7 > .false:nth-of-type(n) {
    clear: none;
  }

  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .false:nth-of-type(7n+1) {
    clear: both;
  }

  .medium-up-7 > .column:last-child, .medium-up-7 > .false:last-child {
    float: left;
  }

  .medium-up-8 > .column, .medium-up-8 > .false {
    float: left;
    width: 12.5%;
  }

  .medium-up-8 > .column:nth-of-type(n), .medium-up-8 > .false:nth-of-type(n) {
    clear: none;
  }

  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .false:nth-of-type(8n+1) {
    clear: both;
  }

  .medium-up-8 > .column:last-child, .medium-up-8 > .false:last-child {
    float: left;
  }

  .medium-collapse > .column, .medium-collapse > .false {
    padding-left: 0;
    padding-right: 0;
  }

  .medium-collapse .row, .expanded.row .medium-collapse.row {
    margin-left: 0;
    margin-right: 0;
  }

  .medium-uncollapse > .column, .medium-uncollapse > .false {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .medium-centered {
    margin-left: auto;
    margin-right: auto;
  }

  .medium-centered, .medium-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .medium-uncentered, .medium-push-0, .medium-pull-0 {
    margin-left: 0;
    margin-right: 0;
    position: static;
  }

  .medium-uncentered, .medium-uncentered:last-child:not(:first-child), .medium-push-0, .medium-push-0:last-child:not(:first-child), .medium-pull-0, .medium-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }

  .medium-uncentered:last-child:not(:first-child), .medium-push-0:last-child:not(:first-child), .medium-pull-0:last-child:not(:first-child) {
    float: right;
  }
}

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%;
  }

  .large-push-1 {
    position: relative;
    left: 8.33333%;
  }

  .large-pull-1 {
    position: relative;
    left: -8.33333%;
  }

  .large-offset-0 {
    margin-left: 0%;
  }

  .large-2 {
    width: 16.6667%;
  }

  .large-push-2 {
    position: relative;
    left: 16.6667%;
  }

  .large-pull-2 {
    position: relative;
    left: -16.6667%;
  }

  .large-offset-1 {
    margin-left: 8.33333%;
  }

  .large-3 {
    width: 25%;
  }

  .large-push-3 {
    position: relative;
    left: 25%;
  }

  .large-pull-3 {
    position: relative;
    left: -25%;
  }

  .large-offset-2 {
    margin-left: 16.6667%;
  }

  .large-4 {
    width: 33.3333%;
  }

  .large-push-4 {
    position: relative;
    left: 33.3333%;
  }

  .large-pull-4 {
    position: relative;
    left: -33.3333%;
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .large-5 {
    width: 41.6667%;
  }

  .large-push-5 {
    position: relative;
    left: 41.6667%;
  }

  .large-pull-5 {
    position: relative;
    left: -41.6667%;
  }

  .large-offset-4 {
    margin-left: 33.3333%;
  }

  .large-6 {
    width: 50%;
  }

  .large-push-6 {
    position: relative;
    left: 50%;
  }

  .large-pull-6 {
    position: relative;
    left: -50%;
  }

  .large-offset-5 {
    margin-left: 41.6667%;
  }

  .large-7 {
    width: 58.3333%;
  }

  .large-push-7 {
    position: relative;
    left: 58.3333%;
  }

  .large-pull-7 {
    position: relative;
    left: -58.3333%;
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .large-8 {
    width: 66.6667%;
  }

  .large-push-8 {
    position: relative;
    left: 66.6667%;
  }

  .large-pull-8 {
    position: relative;
    left: -66.6667%;
  }

  .large-offset-7 {
    margin-left: 58.3333%;
  }

  .large-9 {
    width: 75%;
  }

  .large-push-9 {
    position: relative;
    left: 75%;
  }

  .large-pull-9 {
    position: relative;
    left: -75%;
  }

  .large-offset-8 {
    margin-left: 66.6667%;
  }

  .large-10 {
    width: 83.3333%;
  }

  .large-push-10 {
    position: relative;
    left: 83.3333%;
  }

  .large-pull-10 {
    position: relative;
    left: -83.3333%;
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .large-11 {
    width: 91.6667%;
  }

  .large-push-11 {
    position: relative;
    left: 91.6667%;
  }

  .large-pull-11 {
    position: relative;
    left: -91.6667%;
  }

  .large-offset-10 {
    margin-left: 83.3333%;
  }

  .large-12 {
    width: 100%;
  }

  .large-offset-11 {
    margin-left: 91.6667%;
  }

  .large-up-1 > .column, .large-up-1 > .false {
    float: left;
    width: 100%;
  }

  .large-up-1 > .column:nth-of-type(n), .large-up-1 > .false:nth-of-type(n) {
    clear: none;
  }

  .large-up-1 > .column:nth-of-type(n+1), .large-up-1 > .false:nth-of-type(n+1) {
    clear: both;
  }

  .large-up-1 > .column:last-child, .large-up-1 > .false:last-child {
    float: left;
  }

  .large-up-2 > .column, .large-up-2 > .false {
    float: left;
    width: 50%;
  }

  .large-up-2 > .column:nth-of-type(n), .large-up-2 > .false:nth-of-type(n) {
    clear: none;
  }

  .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .false:nth-of-type(2n+1) {
    clear: both;
  }

  .large-up-2 > .column:last-child, .large-up-2 > .false:last-child {
    float: left;
  }

  .large-up-3 > .column, .large-up-3 > .false {
    float: left;
    width: 33.3333%;
  }

  .large-up-3 > .column:nth-of-type(n), .large-up-3 > .false:nth-of-type(n) {
    clear: none;
  }

  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .false:nth-of-type(3n+1) {
    clear: both;
  }

  .large-up-3 > .column:last-child, .large-up-3 > .false:last-child {
    float: left;
  }

  .large-up-4 > .column, .large-up-4 > .false {
    float: left;
    width: 25%;
  }

  .large-up-4 > .column:nth-of-type(n), .large-up-4 > .false:nth-of-type(n) {
    clear: none;
  }

  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .false:nth-of-type(4n+1) {
    clear: both;
  }

  .large-up-4 > .column:last-child, .large-up-4 > .false:last-child {
    float: left;
  }

  .large-up-5 > .column, .large-up-5 > .false {
    float: left;
    width: 20%;
  }

  .large-up-5 > .column:nth-of-type(n), .large-up-5 > .false:nth-of-type(n) {
    clear: none;
  }

  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .false:nth-of-type(5n+1) {
    clear: both;
  }

  .large-up-5 > .column:last-child, .large-up-5 > .false:last-child {
    float: left;
  }

  .large-up-6 > .column, .large-up-6 > .false {
    float: left;
    width: 16.6667%;
  }

  .large-up-6 > .column:nth-of-type(n), .large-up-6 > .false:nth-of-type(n) {
    clear: none;
  }

  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .false:nth-of-type(6n+1) {
    clear: both;
  }

  .large-up-6 > .column:last-child, .large-up-6 > .false:last-child {
    float: left;
  }

  .large-up-7 > .column, .large-up-7 > .false {
    float: left;
    width: 14.2857%;
  }

  .large-up-7 > .column:nth-of-type(n), .large-up-7 > .false:nth-of-type(n) {
    clear: none;
  }

  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .false:nth-of-type(7n+1) {
    clear: both;
  }

  .large-up-7 > .column:last-child, .large-up-7 > .false:last-child {
    float: left;
  }

  .large-up-8 > .column, .large-up-8 > .false {
    float: left;
    width: 12.5%;
  }

  .large-up-8 > .column:nth-of-type(n), .large-up-8 > .false:nth-of-type(n) {
    clear: none;
  }

  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .false:nth-of-type(8n+1) {
    clear: both;
  }

  .large-up-8 > .column:last-child, .large-up-8 > .false:last-child {
    float: left;
  }

  .large-collapse > .column, .large-collapse > .false {
    padding-left: 0;
    padding-right: 0;
  }

  .large-collapse .row, .expanded.row .large-collapse.row {
    margin-left: 0;
    margin-right: 0;
  }

  .large-uncollapse > .column, .large-uncollapse > .false {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .large-centered {
    margin-left: auto;
    margin-right: auto;
  }

  .large-centered, .large-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .large-uncentered, .large-push-0, .large-pull-0 {
    margin-left: 0;
    margin-right: 0;
    position: static;
  }

  .large-uncentered, .large-uncentered:last-child:not(:first-child), .large-push-0, .large-push-0:last-child:not(:first-child), .large-pull-0, .large-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }

  .large-uncentered:last-child:not(:first-child), .large-push-0:last-child:not(:first-child), .large-pull-0:last-child:not(:first-child) {
    float: right;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-1 {
    width: 8.33333%;
  }

  .xlarge-push-1 {
    position: relative;
    left: 8.33333%;
  }

  .xlarge-pull-1 {
    position: relative;
    left: -8.33333%;
  }

  .xlarge-offset-0 {
    margin-left: 0%;
  }

  .xlarge-2 {
    width: 16.6667%;
  }

  .xlarge-push-2 {
    position: relative;
    left: 16.6667%;
  }

  .xlarge-pull-2 {
    position: relative;
    left: -16.6667%;
  }

  .xlarge-offset-1 {
    margin-left: 8.33333%;
  }

  .xlarge-3 {
    width: 25%;
  }

  .xlarge-push-3 {
    position: relative;
    left: 25%;
  }

  .xlarge-pull-3 {
    position: relative;
    left: -25%;
  }

  .xlarge-offset-2 {
    margin-left: 16.6667%;
  }

  .xlarge-4 {
    width: 33.3333%;
  }

  .xlarge-push-4 {
    position: relative;
    left: 33.3333%;
  }

  .xlarge-pull-4 {
    position: relative;
    left: -33.3333%;
  }

  .xlarge-offset-3 {
    margin-left: 25%;
  }

  .xlarge-5 {
    width: 41.6667%;
  }

  .xlarge-push-5 {
    position: relative;
    left: 41.6667%;
  }

  .xlarge-pull-5 {
    position: relative;
    left: -41.6667%;
  }

  .xlarge-offset-4 {
    margin-left: 33.3333%;
  }

  .xlarge-6 {
    width: 50%;
  }

  .xlarge-push-6 {
    position: relative;
    left: 50%;
  }

  .xlarge-pull-6 {
    position: relative;
    left: -50%;
  }

  .xlarge-offset-5 {
    margin-left: 41.6667%;
  }

  .xlarge-7 {
    width: 58.3333%;
  }

  .xlarge-push-7 {
    position: relative;
    left: 58.3333%;
  }

  .xlarge-pull-7 {
    position: relative;
    left: -58.3333%;
  }

  .xlarge-offset-6 {
    margin-left: 50%;
  }

  .xlarge-8 {
    width: 66.6667%;
  }

  .xlarge-push-8 {
    position: relative;
    left: 66.6667%;
  }

  .xlarge-pull-8 {
    position: relative;
    left: -66.6667%;
  }

  .xlarge-offset-7 {
    margin-left: 58.3333%;
  }

  .xlarge-9 {
    width: 75%;
  }

  .xlarge-push-9 {
    position: relative;
    left: 75%;
  }

  .xlarge-pull-9 {
    position: relative;
    left: -75%;
  }

  .xlarge-offset-8 {
    margin-left: 66.6667%;
  }

  .xlarge-10 {
    width: 83.3333%;
  }

  .xlarge-push-10 {
    position: relative;
    left: 83.3333%;
  }

  .xlarge-pull-10 {
    position: relative;
    left: -83.3333%;
  }

  .xlarge-offset-9 {
    margin-left: 75%;
  }

  .xlarge-11 {
    width: 91.6667%;
  }

  .xlarge-push-11 {
    position: relative;
    left: 91.6667%;
  }

  .xlarge-pull-11 {
    position: relative;
    left: -91.6667%;
  }

  .xlarge-offset-10 {
    margin-left: 83.3333%;
  }

  .xlarge-12 {
    width: 100%;
  }

  .xlarge-offset-11 {
    margin-left: 91.6667%;
  }

  .xlarge-up-1 > .column, .xlarge-up-1 > .false {
    float: left;
    width: 100%;
  }

  .xlarge-up-1 > .column:nth-of-type(n), .xlarge-up-1 > .false:nth-of-type(n) {
    clear: none;
  }

  .xlarge-up-1 > .column:nth-of-type(n+1), .xlarge-up-1 > .false:nth-of-type(n+1) {
    clear: both;
  }

  .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .false:last-child {
    float: left;
  }

  .xlarge-up-2 > .column, .xlarge-up-2 > .false {
    float: left;
    width: 50%;
  }

  .xlarge-up-2 > .column:nth-of-type(n), .xlarge-up-2 > .false:nth-of-type(n) {
    clear: none;
  }

  .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .false:nth-of-type(2n+1) {
    clear: both;
  }

  .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .false:last-child {
    float: left;
  }

  .xlarge-up-3 > .column, .xlarge-up-3 > .false {
    float: left;
    width: 33.3333%;
  }

  .xlarge-up-3 > .column:nth-of-type(n), .xlarge-up-3 > .false:nth-of-type(n) {
    clear: none;
  }

  .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .false:nth-of-type(3n+1) {
    clear: both;
  }

  .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .false:last-child {
    float: left;
  }

  .xlarge-up-4 > .column, .xlarge-up-4 > .false {
    float: left;
    width: 25%;
  }

  .xlarge-up-4 > .column:nth-of-type(n), .xlarge-up-4 > .false:nth-of-type(n) {
    clear: none;
  }

  .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .false:nth-of-type(4n+1) {
    clear: both;
  }

  .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .false:last-child {
    float: left;
  }

  .xlarge-up-5 > .column, .xlarge-up-5 > .false {
    float: left;
    width: 20%;
  }

  .xlarge-up-5 > .column:nth-of-type(n), .xlarge-up-5 > .false:nth-of-type(n) {
    clear: none;
  }

  .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .false:nth-of-type(5n+1) {
    clear: both;
  }

  .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .false:last-child {
    float: left;
  }

  .xlarge-up-6 > .column, .xlarge-up-6 > .false {
    float: left;
    width: 16.6667%;
  }

  .xlarge-up-6 > .column:nth-of-type(n), .xlarge-up-6 > .false:nth-of-type(n) {
    clear: none;
  }

  .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .false:nth-of-type(6n+1) {
    clear: both;
  }

  .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .false:last-child {
    float: left;
  }

  .xlarge-up-7 > .column, .xlarge-up-7 > .false {
    float: left;
    width: 14.2857%;
  }

  .xlarge-up-7 > .column:nth-of-type(n), .xlarge-up-7 > .false:nth-of-type(n) {
    clear: none;
  }

  .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .false:nth-of-type(7n+1) {
    clear: both;
  }

  .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .false:last-child {
    float: left;
  }

  .xlarge-up-8 > .column, .xlarge-up-8 > .false {
    float: left;
    width: 12.5%;
  }

  .xlarge-up-8 > .column:nth-of-type(n), .xlarge-up-8 > .false:nth-of-type(n) {
    clear: none;
  }

  .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .false:nth-of-type(8n+1) {
    clear: both;
  }

  .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .false:last-child {
    float: left;
  }

  .xlarge-collapse > .column, .xlarge-collapse > .false {
    padding-left: 0;
    padding-right: 0;
  }

  .xlarge-collapse .row, .expanded.row .xlarge-collapse.row {
    margin-left: 0;
    margin-right: 0;
  }

  .xlarge-uncollapse > .column, .xlarge-uncollapse > .false {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .xlarge-centered {
    margin-left: auto;
    margin-right: auto;
  }

  .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .xlarge-uncentered, .xlarge-push-0, .xlarge-pull-0 {
    margin-left: 0;
    margin-right: 0;
    position: static;
  }

  .xlarge-uncentered, .xlarge-uncentered:last-child:not(:first-child), .xlarge-push-0, .xlarge-push-0:last-child:not(:first-child), .xlarge-pull-0, .xlarge-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }

  .xlarge-uncentered:last-child:not(:first-child), .xlarge-push-0:last-child:not(:first-child), .xlarge-pull-0:last-child:not(:first-child) {
    float: right;
  }
}

.column-block {
  margin-bottom: 3.125rem;
}

.column-block > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 48em) {
  .column-block {
    margin-bottom: 3.75rem;
  }

  .column-block > :last-child {
    margin-bottom: 0;
  }
}

div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

p {
  font-size: inherit;
  text-rendering: optimizelegibility;
  margin-bottom: 1rem;
  line-height: 1.6;
}

em, i {
  font-style: italic;
  line-height: inherit;
}

strong, b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: inherit;
  text-rendering: optimizelegibility;
  font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small {
  color: #cacaca;
  line-height: 0;
}

h1, .h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: 1.4;
}

h2, .h2 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.25rem;
  line-height: 1.4;
}

h3, .h3 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.1875rem;
  line-height: 1.4;
}

h4, .h4 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.125rem;
  line-height: 1.4;
}

h5, .h5 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.0625rem;
  line-height: 1.4;
}

h6, .h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1rem;
  line-height: 1.4;
}

@media print, screen and (min-width: 48em) {
  h1, .h1 {
    font-size: 3rem;
  }

  h2, .h2 {
    font-size: 2.5rem;
  }

  h3, .h3 {
    font-size: 1.9375rem;
  }

  h4, .h4 {
    font-size: 1.5625rem;
  }

  h5, .h5 {
    font-size: 1.25rem;
  }

  h6, .h6 {
    font-size: 1rem;
  }
}

a {
  line-height: inherit;
  color: #1779ba;
  cursor: pointer;
  text-decoration: none;
}

a:hover, a:focus {
  color: #1468a0;
}

a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 81.25rem;
  height: 0;
  border: 0;
  border-bottom: 1px solid #fefefe;
  margin: 1.25rem auto;
}

ul, ol, dl {
  margin-bottom: 1rem;
  line-height: 1.6;
  list-style-position: outside;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: .3rem;
  font-weight: bold;
}

blockquote {
  border-left: 1px solid #cacaca;
  margin: 0 0 1rem;
  padding: .5625rem 1.25rem 0 1.1875rem;
}

blockquote, blockquote p {
  color: #8a8a8a;
  line-height: 1.6;
}

abbr, abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #0a0a0a;
  text-decoration: none;
}

figure {
  margin: 0;
}

kbd {
  color: #0a0a0a;
  background-color: #e6e6e6;
  margin: 0;
  padding: .125rem .25rem 0;
  font-family: Consolas, Liberation Mono, Courier, monospace;
}

.subheader {
  color: #8a8a8a;
  margin-top: .2rem;
  margin-bottom: .5rem;
  font-weight: normal;
  line-height: 1.4;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.cite-block, cite {
  color: #8a8a8a;
  font-size: .8125rem;
  display: block;
}

.cite-block:before, cite:before {
  content: "— ";
}

.code-inline, code {
  color: #0a0a0a;
  max-width: 100%;
  word-wrap: break-word;
  background-color: #e6e6e6;
  border: 1px solid #cacaca;
  padding: .125rem .3125rem .0625rem;
  font-family: Consolas, Liberation Mono, Courier, monospace;
  font-weight: normal;
  display: inline;
}

.code-block {
  color: #0a0a0a;
  white-space: pre;
  background-color: #e6e6e6;
  border: 1px solid #cacaca;
  margin-bottom: 1.5rem;
  padding: 1rem;
  font-family: Consolas, Liberation Mono, Courier, monospace;
  font-weight: normal;
  display: block;
  overflow: auto;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 48em) {
  .medium-text-left {
    text-align: left;
  }

  .medium-text-right {
    text-align: right;
  }

  .medium-text-center {
    text-align: center;
  }

  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }

  .large-text-right {
    text-align: right;
  }

  .large-text-center {
    text-align: center;
  }

  .large-text-justify {
    text-align: justify;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left;
  }

  .xlarge-text-right {
    text-align: right;
  }

  .xlarge-text-center {
    text-align: center;
  }

  .xlarge-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    print-color-adjust: economy;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  .show-for-print {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  table.show-for-print {
    display: table !important;
  }

  thead.show-for-print {
    display: table-header-group !important;
  }

  tbody.show-for-print {
    display: table-row-group !important;
  }

  tr.show-for-print {
    display: table-row !important;
  }

  td.show-for-print, th.show-for-print {
    display: table-cell !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #8a8a8a;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: .5cm;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .print-break-inside {
    page-break-inside: auto;
  }
}

.button {
  vertical-align: middle;
  -webkit-appearance: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 0;
  margin: 0 0 1rem;
  padding: .85em 1em;
  font-family: inherit;
  font-size: .9rem;
  line-height: 1;
  transition: background-color .25s ease-out, color .25s ease-out;
  display: inline-block;
}

[data-whatinput="mouse"] .button {
  outline: 0;
}

.button.tiny {
  font-size: .6rem;
}

.button.small {
  font-size: .75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: block;
}

.button, .button.disabled, .button[disabled], .button.disabled:hover, .button[disabled]:hover, .button.disabled:focus, .button[disabled]:focus {
  color: #fefefe;
  background-color: #1779ba;
}

.button:hover, .button:focus {
  color: #fefefe;
  background-color: #14679e;
}

.button.primary, .button.primary.disabled, .button.primary[disabled], .button.primary.disabled:hover, .button.primary[disabled]:hover, .button.primary.disabled:focus, .button.primary[disabled]:focus {
  color: #fefefe;
  background-color: #1779ba;
}

.button.primary:hover, .button.primary:focus {
  color: #fefefe;
  background-color: #126195;
}

.button.secondary, .button.secondary.disabled, .button.secondary[disabled], .button.secondary.disabled:hover, .button.secondary[disabled]:hover, .button.secondary.disabled:focus, .button.secondary[disabled]:focus {
  color: #fefefe;
  background-color: #767676;
}

.button.secondary:hover, .button.secondary:focus {
  color: #fefefe;
  background-color: #5e5e5e;
}

.button.success, .button.success.disabled, .button.success[disabled], .button.success.disabled:hover, .button.success[disabled]:hover, .button.success.disabled:focus, .button.success[disabled]:focus {
  color: #0a0a0a;
  background-color: #3adb76;
}

.button.success:hover, .button.success:focus {
  color: #0a0a0a;
  background-color: #22bb5b;
}

.button.warning, .button.warning.disabled, .button.warning[disabled], .button.warning.disabled:hover, .button.warning[disabled]:hover, .button.warning.disabled:focus, .button.warning[disabled]:focus {
  color: #0a0a0a;
  background-color: #ffae00;
}

.button.warning:hover, .button.warning:focus {
  color: #0a0a0a;
  background-color: #cc8b00;
}

.button.alert, .button.alert.disabled, .button.alert[disabled], .button.alert.disabled:hover, .button.alert[disabled]:hover, .button.alert.disabled:focus, .button.alert[disabled]:focus {
  color: #fefefe;
  background-color: #cc4b37;
}

.button.alert:hover, .button.alert:focus {
  color: #fefefe;
  background-color: #a53b2a;
}

.button.hollow, .button.hollow:hover, .button.hollow:focus, .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: #0000;
}

.button.hollow, .button.hollow.disabled, .button.hollow[disabled], .button.hollow.disabled:hover, .button.hollow[disabled]:hover, .button.hollow.disabled:focus, .button.hollow[disabled]:focus {
  color: #1779ba;
  border: 1px solid #1779ba;
}

.button.hollow:hover, .button.hollow:focus {
  color: #0c3d5d;
  border-color: #0c3d5d;
}

.button.hollow.primary, .button.hollow.primary.disabled, .button.hollow.primary[disabled], .button.hollow.primary.disabled:hover, .button.hollow.primary[disabled]:hover, .button.hollow.primary.disabled:focus, .button.hollow.primary[disabled]:focus {
  color: #1779ba;
  border: 1px solid #1779ba;
}

.button.hollow.primary:hover, .button.hollow.primary:focus {
  color: #0c3d5d;
  border-color: #0c3d5d;
}

.button.hollow.secondary, .button.hollow.secondary.disabled, .button.hollow.secondary[disabled], .button.hollow.secondary.disabled:hover, .button.hollow.secondary[disabled]:hover, .button.hollow.secondary.disabled:focus, .button.hollow.secondary[disabled]:focus {
  color: #767676;
  border: 1px solid #767676;
}

.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  color: #3b3b3b;
  border-color: #3b3b3b;
}

.button.hollow.success, .button.hollow.success.disabled, .button.hollow.success[disabled], .button.hollow.success.disabled:hover, .button.hollow.success[disabled]:hover, .button.hollow.success.disabled:focus, .button.hollow.success[disabled]:focus {
  color: #3adb76;
  border: 1px solid #3adb76;
}

.button.hollow.success:hover, .button.hollow.success:focus {
  color: #157539;
  border-color: #157539;
}

.button.hollow.warning, .button.hollow.warning.disabled, .button.hollow.warning[disabled], .button.hollow.warning.disabled:hover, .button.hollow.warning[disabled]:hover, .button.hollow.warning.disabled:focus, .button.hollow.warning[disabled]:focus {
  color: #ffae00;
  border: 1px solid #ffae00;
}

.button.hollow.warning:hover, .button.hollow.warning:focus {
  color: #805700;
  border-color: #805700;
}

.button.hollow.alert, .button.hollow.alert.disabled, .button.hollow.alert[disabled], .button.hollow.alert.disabled:hover, .button.hollow.alert[disabled]:hover, .button.hollow.alert.disabled:focus, .button.hollow.alert[disabled]:focus {
  color: #cc4b37;
  border: 1px solid #cc4b37;
}

.button.hollow.alert:hover, .button.hollow.alert:focus {
  color: #67251a;
  border-color: #67251a;
}

.button.clear, .button.clear:hover, .button.clear:focus, .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: #0000;
  border-color: #0000;
}

.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear.disabled:hover, .button.clear[disabled]:hover, .button.clear.disabled:focus, .button.clear[disabled]:focus {
  color: #1779ba;
}

.button.clear:hover, .button.clear:focus {
  color: #0c3d5d;
}

.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary.disabled:hover, .button.clear.primary[disabled]:hover, .button.clear.primary.disabled:focus, .button.clear.primary[disabled]:focus {
  color: #1779ba;
}

.button.clear.primary:hover, .button.clear.primary:focus {
  color: #0c3d5d;
}

.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary.disabled:hover, .button.clear.secondary[disabled]:hover, .button.clear.secondary.disabled:focus, .button.clear.secondary[disabled]:focus {
  color: #767676;
}

.button.clear.secondary:hover, .button.clear.secondary:focus {
  color: #3b3b3b;
}

.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success.disabled:hover, .button.clear.success[disabled]:hover, .button.clear.success.disabled:focus, .button.clear.success[disabled]:focus {
  color: #3adb76;
}

.button.clear.success:hover, .button.clear.success:focus {
  color: #157539;
}

.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning.disabled:hover, .button.clear.warning[disabled]:hover, .button.clear.warning.disabled:focus, .button.clear.warning[disabled]:focus {
  color: #ffae00;
}

.button.clear.warning:hover, .button.clear.warning:focus {
  color: #805700;
}

.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert.disabled:hover, .button.clear.alert[disabled]:hover, .button.clear.alert.disabled:focus, .button.clear.alert[disabled]:focus {
  color: #cc4b37;
}

.button.clear.alert:hover, .button.clear.alert:focus {
  color: #67251a;
}

.button.disabled, .button[disabled] {
  opacity: .25;
  cursor: not-allowed;
}

.button.dropdown:after {
  width: 0;
  height: 0;
  content: "";
  float: right;
  border: .4em solid #0000;
  border-top-color: #fefefe;
  border-bottom-width: 0;
  margin-left: 1em;
  display: inline-block;
  position: relative;
  top: .4em;
}

.button.dropdown.hollow:after, .button.dropdown.clear:after, .button.dropdown.hollow.primary:after, .button.dropdown.clear.primary:after {
  border-top-color: #1779ba;
}

.button.dropdown.hollow.secondary:after, .button.dropdown.clear.secondary:after {
  border-top-color: #767676;
}

.button.dropdown.hollow.success:after, .button.dropdown.clear.success:after {
  border-top-color: #3adb76;
}

.button.dropdown.hollow.warning:after, .button.dropdown.clear.warning:after {
  border-top-color: #ffae00;
}

.button.dropdown.hollow.alert:after, .button.dropdown.clear.alert:after {
  border-top-color: #cc4b37;
}

.button.arrow-only:after {
  float: none;
  margin-left: 0;
  top: -.1em;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

.accordion {
  background: #fefefe;
  margin-left: 0;
  list-style-type: none;
}

.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child, .accordion-item:last-child > :last-child {
  border-radius: 0;
}

.accordion-title {
  color: #1779ba;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  padding: 1.25rem 1rem;
  font-size: .75rem;
  line-height: 1;
  display: block;
  position: relative;
}

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}

.accordion-title:before {
  content: "+";
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
  right: 1rem;
}

.is-active > .accordion-title:before {
  content: "–";
}

.accordion-content {
  color: #0a0a0a;
  background-color: #fefefe;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  padding: 1rem;
  display: none;
}

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.dropdown-pane {
  z-index: 10;
  width: 300px;
  visibility: hidden;
  background-color: #fefefe;
  border: 1px solid #cacaca;
  border-radius: 0;
  padding: 1rem;
  font-size: 1rem;
  display: none;
  position: absolute;
}

.dropdown-pane.is-opening {
  display: block;
}

.dropdown-pane.is-open {
  visibility: visible;
  display: block;
}

.dropdown-pane.tiny {
  width: 100px;
}

.dropdown-pane.small {
  width: 200px;
}

.dropdown-pane.large {
  width: 400px;
}

.responsive-embed, .flex-video {
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
}

.responsive-embed iframe, .responsive-embed object, .responsive-embed embed, .responsive-embed video, .flex-video iframe, .flex-video object, .flex-video embed, .flex-video video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.responsive-embed.widescreen, .flex-video.widescreen {
  padding-bottom: 56.25%;
}

.sticky-container {
  position: relative;
}

.sticky {
  z-index: 0;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.sticky.is-stuck {
  z-index: 5;
  width: 100%;
  position: fixed;
}

.sticky.is-stuck.is-at-top {
  top: 0;
}

.sticky.is-stuck.is-at-bottom {
  bottom: 0;
}

.sticky.is-anchored {
  position: relative;
  left: auto;
  right: auto;
}

.sticky.is-anchored.is-at-bottom {
  bottom: 0;
}

html.is-reveal-open {
  width: 100%;
  position: fixed;
  overflow-y: hidden;
}

html.is-reveal-open.zf-has-scroll {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

html.is-reveal-open body {
  overflow-y: hidden;
}

.reveal-overlay {
  z-index: 1005;
  -webkit-overflow-scrolling: touch;
  background-color: #0a0a0a73;
  display: none;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: #fefefe;
  border: 1px solid #cacaca;
  border-radius: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  display: none;
  position: relative;
  top: 100px;
  overflow-y: auto;
}

[data-whatinput="mouse"] .reveal {
  outline: 0;
}

@media print, screen and (min-width: 48em) {
  .reveal {
    min-height: 0;
  }
}

.reveal .column, .reveal .false {
  min-width: 0;
}

.reveal > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 48em) {
  .reveal {
    width: 600px;
    max-width: 81.25rem;
  }
}

.reveal.collapse {
  padding: 0;
}

@media print, screen and (min-width: 48em) {
  .reveal.tiny {
    width: 30%;
    max-width: 81.25rem;
  }

  .reveal.small {
    width: 50%;
    max-width: 81.25rem;
  }

  .reveal.large {
    width: 90%;
    max-width: 81.25rem;
  }
}

.reveal.full {
  width: 100%;
  max-width: none;
  height: 100%;
  min-height: 100%;
  border: 0;
  border-radius: 0;
  margin-left: 0;
  inset: 0;
}

@media print, screen and (max-width: 47.9988em) {
  .reveal {
    width: 100%;
    max-width: none;
    height: 100%;
    min-height: 100%;
    border: 0;
    border-radius: 0;
    margin-left: 0;
    inset: 0;
  }
}

.reveal.without-overlay {
  position: fixed;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

@media print, screen and (max-width: 47.9988em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 48em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 48em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 47.9988em) {
  .show-for-medium {
    display: none !important;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9988em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 47.9988em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9988em) {
  .show-for-large {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.9988em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9988em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important;
  }
}

@media screen and (max-width: 74.9988em) {
  .show-for-xlarge {
    display: none !important;
  }
}

@media screen and (min-width: 75em) and (max-width: 89.9988em) {
  .hide-for-xlarge-only {
    display: none !important;
  }
}

@media screen and (max-width: 74.9988em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important;
  }
}

.show-for-sr, .show-on-focus {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.show-on-focus:active, .show-on-focus:focus {
  width: auto !important;
  height: auto !important;
  clip: auto !important;
  white-space: normal !important;
  position: static !important;
  overflow: visible !important;
}

.show-for-landscape, .hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape, .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape, .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape, .show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape, .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape, .show-for-portrait {
    display: block !important;
  }
}

.show-for-dark-mode {
  display: none;
}

.hide-for-dark-mode {
  display: block;
}

@media screen and (prefers-color-scheme: dark) {
  .show-for-dark-mode {
    display: block !important;
  }

  .hide-for-dark-mode {
    display: none !important;
  }
}

.show-for-ie {
  display: none;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .show-for-ie {
    display: block !important;
  }

  .hide-for-ie {
    display: none !important;
  }
}

.show-for-sticky {
  display: none;
}

.is-stuck .show-for-sticky {
  display: block;
}

.is-stuck .hide-for-sticky {
  display: none;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.slide-in-down.mui-enter {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateY(-100%);
}

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-left.mui-enter {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateX(-100%);
}

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-in-up.mui-enter {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateY(100%);
}

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-right.mui-enter {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateX(100%);
}

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-out-down.mui-leave {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateY(0);
}

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%);
}

.slide-out-right.mui-leave {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateX(0);
}

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%);
}

.slide-out-up.mui-leave {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateY(0);
}

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%);
}

.slide-out-left.mui-leave {
  backface-visibility: hidden;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: translateX(0);
}

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%);
}

.fade-in.mui-enter {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
}

.fade-in.mui-enter.mui-enter-active {
  opacity: 1;
}

.fade-out.mui-leave {
  opacity: 1;
  transition-property: opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
}

.fade-out.mui-leave.mui-leave-active {
  opacity: 0;
}

.hinge-in-from-top.mui-enter {
  transform-origin: top;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateX(-90deg);
}

.hinge-in-from-top.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-in-from-right.mui-enter {
  transform-origin: 100%;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateY(-90deg);
}

.hinge-in-from-right.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-in-from-bottom.mui-enter {
  transform-origin: bottom;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateX(90deg);
}

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-in-from-left.mui-enter {
  transform-origin: 0;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateY(90deg);
}

.hinge-in-from-left.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-in-from-middle-x.mui-enter {
  transform-origin: center;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateX(-90deg);
}

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-in-from-middle-y.mui-enter {
  transform-origin: center;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotateY(-90deg);
}

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  opacity: 1;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-top.mui-leave {
  transform-origin: top;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-top.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateX(90deg);
}

.hinge-out-from-right.mui-leave {
  transform-origin: 100%;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-right.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateY(90deg);
}

.hinge-out-from-bottom.mui-leave {
  transform-origin: bottom;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateX(-90deg);
}

.hinge-out-from-left.mui-leave {
  transform-origin: 0;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-left.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateY(-90deg);
}

.hinge-out-from-middle-x.mui-leave {
  transform-origin: center;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateX(90deg);
}

.hinge-out-from-middle-y.mui-leave {
  transform-origin: center;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: perspective(2000px)rotate(0);
}

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  opacity: 0;
  transform: perspective(2000px)rotateY(90deg);
}

.scale-in-up.mui-enter {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: scale(.5);
}

.scale-in-up.mui-enter.mui-enter-active {
  opacity: 1;
  transform: scale(1);
}

.scale-in-down.mui-enter {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: scale(1.5);
}

.scale-in-down.mui-enter.mui-enter-active {
  opacity: 1;
  transform: scale(1);
}

.scale-out-up.mui-leave {
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: scale(1);
}

.scale-out-up.mui-leave.mui-leave-active {
  opacity: 0;
  transform: scale(1.5);
}

.scale-out-down.mui-leave {
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: scale(1);
}

.scale-out-down.mui-leave.mui-leave-active {
  opacity: 0;
  transform: scale(.5);
}

.spin-in.mui-enter {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: rotate(-.75turn);
}

.spin-in.mui-enter.mui-enter-active {
  opacity: 1;
  transform: rotate(0);
}

.spin-out.mui-leave {
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: rotate(0);
}

.spin-out.mui-leave.mui-leave-active {
  opacity: 0;
  transform: rotate(.75turn);
}

.spin-in-ccw.mui-enter {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: rotate(.75turn);
}

.spin-in-ccw.mui-enter.mui-enter-active {
  opacity: 1;
  transform: rotate(0);
}

.spin-out-ccw.mui-leave {
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: linear;
  transform: rotate(0);
}

.spin-out-ccw.mui-leave.mui-leave-active {
  opacity: 0;
  transform: rotate(-.75turn);
}

.slow {
  transition-duration: .75s !important;
}

.fast {
  transition-duration: .25s !important;
}

.linear {
  transition-timing-function: linear !important;
}

.ease {
  transition-timing-function: ease !important;
}

.ease-in {
  transition-timing-function: ease-in !important;
}

.ease-out {
  transition-timing-function: ease-out !important;
}

.ease-in-out {
  transition-timing-function: ease-in-out !important;
}

.bounce-in {
  transition-timing-function: cubic-bezier(.485, .155, .24, 1.245) !important;
}

.bounce-out {
  transition-timing-function: cubic-bezier(.485, .155, .515, .845) !important;
}

.bounce-in-out {
  transition-timing-function: cubic-bezier(.76, -.245, .24, 1.245) !important;
}

.short-delay {
  transition-delay: .3s !important;
}

.long-delay {
  transition-delay: .7s !important;
}

.shake {
  animation-name: shake-7;
}

.spin-cw {
  animation-name: spin-cw-1turn;
}

.spin-ccw {
  animation-name: spin-ccw-1turn;
}

.wiggle {
  animation-name: wiggle-7deg;
}

.shake, .spin-cw, .spin-ccw, .wiggle {
  animation-duration: .5s;
}

.infinite {
  animation-iteration-count: infinite;
}

.slow {
  animation-duration: .75s !important;
}

.fast {
  animation-duration: .25s !important;
}

.linear {
  animation-timing-function: linear !important;
}

.ease {
  animation-timing-function: ease !important;
}

.ease-in {
  animation-timing-function: ease-in !important;
}

.ease-out {
  animation-timing-function: ease-out !important;
}

.ease-in-out {
  animation-timing-function: ease-in-out !important;
}

.bounce-in {
  animation-timing-function: cubic-bezier(.485, .155, .24, 1.245) !important;
}

.bounce-out {
  animation-timing-function: cubic-bezier(.485, .155, .515, .845) !important;
}

.bounce-in-out {
  animation-timing-function: cubic-bezier(.76, -.245, .24, 1.245) !important;
}

.short-delay {
  animation-delay: .3s !important;
}

.long-delay {
  animation-delay: .7s !important;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  height: auto;
  border: 1px solid #0000;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

div.barba-container {
  transition: opacity .5s;
}

div.barba-container.op0 {
  opacity: 0;
}

div.barba-container.transition-active {
  width: 100%;
  position: absolute;
  top: 0;
}

@font-face {
  font-family: PlantinMTPro-Light;
  src: url("PlantinMTPro-Light.13e4c889.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PlantinMTPro-LightItalic;
  src: url("PlantinMTPro-LightItalic.f5afb154.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Theinhardt-Bold;
  src: url("Theinhardt-Bold.64638ecb.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Theinhardt-Regular;
  src: url("Theinhardt-Regular.f83acadd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Theinhardt-Regular-Italic;
  src: url("Theinhardt-RegularIta.64007bd2.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Theinhardt-Medium;
  src: url("Theinhardt-Medium.ce5eec92.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Theinhardt-Thin;
  src: url("Theinhardt-Thin.4c4d218e.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.icon--ico-hamburguer {
  width: 25px;
  height: 15px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: 1;
}

hr {
  border-bottom: 1px solid #b2aea3;
}

.section-headline {
  color: #1d1e21;
  letter-spacing: 0;
  margin-bottom: 45px;
  font-family: PlantinMTPro-Light;
  font-size: 22px;
  line-height: 1;
  display: block;
}

body.page-template-template-labs-home .section-headline, .section-headline span {
  font-family: Theinhardt-Bold;
}

@media print, screen and (min-width: 64em) {
  .section-headline {
    margin-bottom: 80px;
    font-size: 40px;
  }
}

.section-headline.center {
  text-align: center;
}

.btn-see-more {
  color: #1d1e21;
  letter-spacing: 0;
  border-bottom: 1px solid #1d1e21;
  margin-top: 50px;
  padding-bottom: 3px;
  font-family: Theinhardt-Medium;
  font-size: 16px;
  display: inline-block;
}

@media print, screen and (min-width: 64em) {
  .btn-see-more {
    font-size: 20px;
  }
}

body.page-template-template-labs-home .btn-see-more {
  display: none;
}

.btn-see-more:hover, .btn-see-more:active, .btn-see-more:focus {
  color: #4c71ff;
  border-color: #4c71ff;
}

a.nowrap {
  white-space: nowrap;
}

.equity-hero-tag {
  color: #4c71ff;
  letter-spacing: -1.06px;
  margin-bottom: 10px;
  font-family: Theinhardt-Medium;
  font-size: 24px;
  line-height: 1;
  display: block;
}

.equity-hero-headline {
  color: #1d1e21;
  letter-spacing: -2.6px;
  font-family: Theinhardt-Medium;
  font-size: 42px;
  line-height: 42px;
}

.equity-hero-headline span {
  color: #4c71ff;
}

@media print, screen and (min-width: 48em) {
  .equity-hero-headline {
    letter-spacing: -2.4px;
    font-size: 80px;
    line-height: 80px;
  }
}

@media print, screen and (min-width: 64em) {
  .equity-hero-headline {
    letter-spacing: -4.4px;
    font-size: 100px;
    line-height: 90px;
  }
}

.equity-hero-headline.white {
  color: #fff;
  letter-spacing: -1.7px;
  font-size: 40px;
  line-height: 36px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .equity-hero-headline.white {
    letter-spacing: -3.3px;
    font-size: 75px;
    line-height: 67px;
  }
}

.equity-hero-subheadline {
  color: #1d1e21;
  letter-spacing: -.5px;
  margin-top: 10px;
  font-family: PlantinMTPro-LightItalic;
  font-size: 25px;
  line-height: 50px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .equity-hero-subheadline {
    letter-spacing: -.8px;
    margin-top: 30px;
    font-size: 40px;
  }
}

.equity-subheadline {
  color: #4c71ff;
  letter-spacing: -.35px;
  margin-bottom: 40px;
  font-family: PlantinMTPro-LightItalic;
  font-size: 35px;
  line-height: 55px;
  display: block;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .equity-subheadline {
    letter-spacing: -.5px;
    margin-bottom: 0;
    font-size: 48px;
    line-height: 64px;
  }
}

.equity-subheadline.black {
  color: #1d1e21;
  font-family: PlantinMTPro-Light;
  line-height: 35px;
}

@media print, screen and (min-width: 64em) {
  .equity-subheadline.black {
    line-height: 48px;
  }
}

.equity-subheadline span {
  color: #4c71ff;
  font-family: Theinhardt-Bold;
  position: relative;
}

.equity-subheadline span svg {
  max-width: 140%;
  position: absolute;
  top: -10px;
  left: -10px;
}

@media print, screen and (min-width: 64em) {
  .equity-subheadline span svg {
    top: -5px;
  }
}

.equity-subheadline #taking-action-line-one {
  position: absolute;
  top: 55px;
  left: 0;
}

@media print, screen and (max-width: 47.9988em) {
  .equity-subheadline #taking-action-line-one {
    max-width: 190px;
    top: 45px;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9988em) {
  .equity-subheadline #taking-action-line-one {
    max-width: 195px;
    top: 45px;
  }
}

.equity-subheadline #taking-action-line-two {
  position: absolute;
  bottom: 10px;
  left: 0;
}

@media print, screen and (max-width: 47.9988em) {
  .equity-subheadline #taking-action-line-two {
    max-width: 131px;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9988em) {
  .equity-subheadline #taking-action-line-two {
    max-width: 131px;
  }
}

.equity-headline-white {
  color: #fff;
  margin-bottom: 10px;
  font-family: Theinhardt-Medium;
  font-size: 30px;
  line-height: 40px;
  display: block;
}

.equity-subheadline-white {
  color: #fff;
  font-family: Theinhardt-Regular;
  font-size: 16px;
  line-height: 22px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .equity-subheadline-white {
    font-size: 20px;
    line-height: 28px;
  }
}

.equity-subheadline-white em {
  font-family: Theinhardt-Regular-Italic;
  font-style: normal;
}

.equity-white-big-paragraph {
  color: #fff;
  font-family: Theinhardt-Medium;
  font-size: 18px;
  line-height: 26px;
}

@media print, screen and (min-width: 64em) {
  .equity-white-big-paragraph {
    letter-spacing: -.85px;
    font-size: 30px;
    line-height: 30px;
  }
}

.equity-big-headline {
  color: #1d1e21;
  margin-bottom: 30px;
  font-family: Theinhardt-Medium;
  font-size: 30px;
  line-height: 30px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .equity-big-headline {
    letter-spacing: -3.3px;
    font-size: 70px;
    line-height: 80px;
  }
}

.equity-big-headline .globe {
  padding: 0 10px;
  display: inline-block;
  position: relative;
}

.equity-big-headline .globe svg {
  max-width: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
}

.equity-big-subheadline {
  color: #1d1e21;
  margin-bottom: 60px;
  font-family: Theinhardt-Regular;
  font-size: 22px;
  line-height: 35px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .equity-big-subheadline {
    font-size: 30px;
    line-height: 65px;
  }
}

.equity-big-subheadline .underline {
  display: inline-block;
  position: relative;
}

.equity-big-subheadline .underline svg {
  max-width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.equity-blue-head {
  color: #4c71ff;
  letter-spacing: 0;
  margin-top: 40px;
  margin-bottom: 5px;
  font-family: Theinhardt-Medium;
  font-size: 18px;
  line-height: 26px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .equity-blue-head {
    font-size: 19px;
    line-height: 22px;
  }
}

.equity-quote {
  vertical-align: top;
  max-width: 560px;
  color: #1d1e21;
  letter-spacing: -.2px;
  text-align: left;
  margin-top: 50px;
  font-family: PlantinMTPro-LightItalic;
  font-size: 30px;
  line-height: 35px;
  display: inline-block;
}

.equity-blue-bold {
  color: #4c71ff;
  font-family: Theinhardt-Bold;
}

.button:not(.hamburger), button:not(.hamburger) {
  background-color: #4c71ff;
  margin-bottom: 0;
  font-family: Theinhardt-Bold;
  font-size: 20px;
}

.button:not(.hamburger):hover, .button:not(.hamburger):active, button:not(.hamburger):hover, button:not(.hamburger):active {
  background-color: #1d1e21;
}

.button:not(.hamburger).button--small, button:not(.hamburger).button--small {
  font-size: 14px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

body.single-case_studies section:not(.off-canvas-nav) h2, body.single-case_studies section:not(.off-canvas-nav) h3, body.single-case_studies section:not(.off-canvas-nav) h4, body.single-case_studies section:not(.off-canvas-nav) [data-fadein], body.single-case_studies section:not(.off-canvas-nav) p:not([data-no-fadein]), [data-fadein]:not(.fadeInUp) {
  opacity: 0;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@media (prefers-reduced-motion) {
  .animated {
    animation: unset !important;
    transition: none !important;
  }
}

label {
  font-family: Theinhardt-Medium;
  font-size: 1.2rem;
  line-height: 1.2;
}

label .required {
  color: red;
}

input[type="text"], input[type="email"] {
  height: 50px;
  box-shadow: none;
  color: #1d1e21;
  letter-spacing: 0;
  border: 1px solid #0000001a;
  padding: 0 20px;
  font-family: Theinhardt-Regular;
  font-size: 20px;
  line-height: 50px;
  display: block;
}

input[type="text"]:focus, input[type="email"]:focus {
  box-shadow: 0 0 20px #00000026;
}

@media print, screen and (min-width: 64em) {
  input[type="text"], input[type="email"] {
    height: 66px;
    margin-bottom: 1.875rem;
    font-size: 24px;
    line-height: 66px;
  }
}

.wpcf7-form p, .wpcf7-form label {
  opacity: .7;
}

.wpcf7-form p:last-of-type {
  opacity: 1 !important;
}

.wpcf7-form .button-submit {
  cursor: pointer;
  border: none;
  font-family: Theinhardt-Medium;
  color: #4c71ff !important;
  letter-spacing: 0 !important;
  background-color: #0000 !important;
  border-bottom: 1px solid #4c71ff !important;
  margin-top: 40px !important;
  font-size: 20px !important;
  line-height: 30px !important;
  display: inline-block !important;
}

@media print, screen and (min-width: 64em) {
  .wpcf7-form .button-submit {
    font-size: 24px !important;
  }
}

.wpcf7-form h3 {
  color: #1d1e21;
  letter-spacing: 0;
  margin-bottom: 30px;
  font-family: Theinhardt-Bold;
  font-size: 28px;
  line-height: 40px;
}

.wpcf7-form h4 {
  font-family: sans-serif;
  line-height: 1.4;
}

input[type="text"]::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

input[type="text"]::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.gdpr {
  padding-bottom: 60px !important;
}

.gdpr small {
  color: #b6b6b8;
  text-align: left;
  padding-top: 15px;
  font-family: Theinhardt-Regular;
  font-size: 14px;
  line-height: 22px;
  display: block;
}

.gdpr small a {
  color: #2354fb;
  text-decoration: underline;
}

.gdpr .error, .gdpr .gdpr_message_no {
  display: none;
}

.gdpr .gdpr_message_no p {
  color: #1d1e21;
  text-align: left;
  padding-top: 15px;
  font-family: Theinhardt-Regular;
  font-size: 14px;
  line-height: 22px;
  display: block;
}

.gdpr .gdpr_message_no p strong {
  font-family: Theinhardt-Medium;
  font-weight: normal;
}

.gdpr .gdpr_message_no p a {
  color: #4c71ff;
}

.gdpr .gdpr_label {
  color: #222;
  text-align: left;
  padding-top: 15px;
  font-family: Theinhardt-Medium;
  font-size: 16px;
  line-height: 22px;
  display: block;
}

.gdpr .gdpr_label input[type="radio"] {
  margin-bottom: 0;
  margin-right: .5rem;
}

.gdpr .gdpr_label .form-error {
  text-align: left;
  padding-top: 10px;
  position: absolute;
  left: 1.5625rem;
  right: 1.5625rem;
}

@media print, screen and (min-width: 48em) {
  .gdpr .gdpr_label .form-error {
    left: 1.875rem;
    right: 1.875rem;
  }
}

a {
  -o-transition: all .2s linear;
  transition: all .2s linear;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.spinner {
  width: 70px;
  text-align: center;
  min-height: 700px;
  margin: 50px auto 100px;
}

.spinner > div {
  width: 15px;
  height: 15px;
  background-color: #d8d8d8;
  border-radius: 100%;
  animation: 1.4s ease-in-out infinite both sk-bouncedelay;
  display: inline-block;
}

.spinner .bounce1 {
  animation-delay: -.32s;
}

.spinner .bounce2 {
  animation-delay: -.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

[data-show-more] {
  cursor: pointer;
  text-decoration: underline;
  color: #4c71ff !important;
}

[data-show-more-target] {
  display: none;
}

@font-face {
  font-family: slick;
  src: url("slick.019ff462.eot");
  src: url("slick.019ff462.eot#iefix") format("embedded-opentype"), url("slick.c8d05265.woff") format("woff"), url("slick.441ccdd6.ttf") format("truetype"), url("slick.0ba9c062.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

.slick-loading .slick-list {
  background: #fff url("ajax-loader.86ce59ae.gif") center no-repeat;
}

.slick-prev, .slick-next {
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover .slick-prev-icon, .slick-prev:hover .slick-next-icon, .slick-prev:focus .slick-prev-icon, .slick-prev:focus .slick-next-icon, .slick-next:hover .slick-prev-icon, .slick-next:hover .slick-next-icon, .slick-next:focus .slick-prev-icon, .slick-next:focus .slick-next-icon {
  opacity: 1;
}

.slick-prev:focus, .slick-next:focus {
  top: calc(50% - 1px);
}

.slick-prev:focus .slick-prev-icon, .slick-prev:focus .slick-next-icon, .slick-next:focus .slick-prev-icon, .slick-next:focus .slick-next-icon {
  color: orange;
  margin-left: -2px;
  font-size: 28px;
}

.slick-prev.slick-disabled, .slick-next.slick-disabled {
  cursor: default;
}

.slick-prev.slick-disabled .slick-prev-icon, .slick-prev.slick-disabled .slick-next-icon, .slick-next.slick-disabled .slick-prev-icon, .slick-next.slick-disabled .slick-next-icon {
  opacity: .25;
}

.slick-prev .slick-prev-icon, .slick-prev .slick-next-icon, .slick-next .slick-prev-icon, .slick-next .slick-next-icon {
  color: #000;
  opacity: .75;
  font-family: slick;
  font-size: 24px;
  line-height: 1;
  display: block;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev .slick-prev-icon:before {
  content: "←";
}

[dir="rtl"] .slick-prev .slick-prev-icon:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next .slick-next-icon:before {
  content: "→";
}

[dir="rtl"] .slick-next .slick-next-icon:before {
  content: "←";
}

.slick-slider {
  margin-bottom: 30px;
}

.slick-slider.slick-dotted {
  margin-bottom: 60px;
}

.slick-dots {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -30px;
}

.slick-dots li {
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  height: 20px;
  width: 20px;
  color: #0000;
  cursor: pointer;
  background: none;
  border: 0;
  margin-top: -4px;
  margin-left: -4px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover .slick-dot-icon, .slick-dots li button:focus .slick-dot-icon {
  opacity: 1;
}

.slick-dots li button:focus .slick-dot-icon:before {
  color: orange;
}

.slick-dots li button .slick-dot-icon {
  color: #000;
  opacity: .25;
}

.slick-dots li button .slick-dot-icon:before {
  content: "";
  width: 12px;
  height: 12px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  line-height: 1;
  transition: all 50ms linear;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:focus .slick-dot-icon {
  color: orange;
  opacity: 1;
}

.slick-dots li.slick-active button .slick-dot-icon {
  color: #000;
  opacity: 1;
}

.slick-dots li.slick-active button .slick-dot-icon:before {
  margin-top: -3px;
  margin-left: -2px;
  font-size: 18px;
}

.slick-sr-only {
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.slick-autoplay-toggle-button {
  z-index: 10;
  opacity: .75;
  cursor: pointer;
  color: #000;
  background: none;
  border: 0;
  position: absolute;
  bottom: -32px;
  left: 5px;
}

.slick-autoplay-toggle-button:hover, .slick-autoplay-toggle-button:focus {
  opacity: 1;
}

.slick-autoplay-toggle-button:focus {
  color: orange;
}

.slick-autoplay-toggle-button .slick-pause-icon:before {
  content: "⏸";
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: slick;
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
}

.slick-autoplay-toggle-button .slick-play-icon:before {
  content: "▶";
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: slick;
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
}

.reveal {
  box-shadow: none;
  border: none;
}

.reveal .modal-content {
  position: relative;
}

.reveal .modal-content .voce-sabia {
  max-width: 50%;
  position: absolute;
  top: 20px;
}

.reveal .modal-content .voce-sabia.left {
  left: 20px;
}

.reveal .modal-content .voce-sabia.right {
  right: 20px;
}

.reveal .modal-content .voce-sabia p {
  color: #1d1e21;
  margin-bottom: 0;
  font-family: Work Sans, sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.reveal .modal-content .voce-sabia p em {
  font-style: normal;
  font-weight: bold;
}

.reveal.highlight-blue .modal-content .voce-sabia p em {
  color: #4f75fb;
}

.reveal.highlight-cyan .modal-content .voce-sabia p em {
  color: #33c6a7;
}

.reveal.highlight-orange .modal-content .voce-sabia p em {
  color: #fd9c23;
}

.reveal.highlight-peach .modal-content .voce-sabia p em {
  color: #f57d78;
}

.reveal.highlight-yellow .modal-content .voce-sabia p em {
  color: #fdc143;
}

footer {
  display: block;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  footer.show .footer-content {
    opacity: 1;
    z-index: 2;
  }
}

footer .footer-spacer {
  max-width: 81.25rem;
  margin: 0 auto;
  display: none;
}

@media print, screen and (min-width: 64em) {
  footer .footer-spacer {
    display: block;
  }
}

footer .footer-content {
  max-width: 81.25rem;
  margin: 0 auto;
  padding: 100px 0 30px;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  footer .footer-content {
    opacity: 0;
    z-index: -1;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 75em) {
  footer .footer-content {
    padding: 240px 0 30px;
  }
}

footer .footer-content .logo {
  width: 40px;
  height: 47px;
  display: none;
}

@media print, screen and (min-width: 64em) {
  footer .footer-content .logo {
    display: block;
    position: absolute;
    top: 240px;
    left: 1.875rem;
  }
}

footer .footer-content .logo span {
  background: url("logo.a69ebf18.svg");
  display: none;
}

footer .footer-content .logo svg .logo-letter {
  opacity: 0;
}

footer .footer-content .nav-container .btn-backup {
  display: block;
}

@media (max-width: 767px) {
  footer .footer-content .nav-container .primary-nav ul li a {
    text-align: left;
  }

  footer .footer-content .nav-container .secondary-nav {
    float: none;
  }

  footer .footer-content .nav-container .secondary-nav ul li a {
    text-align: left;
  }

  footer .footer-content .nav-container .clocks-container {
    margin-top: 40px;
    display: block;
  }

  footer .footer-content .nav-container .clocks-container ul li {
    width: 100%;
    margin-bottom: 30px;
  }

  footer .footer-content .nav-container .clocks-container ul li .clock {
    margin-bottom: 0;
    margin-right: 30px;
  }

  footer .footer-content .nav-container .clocks-container ul li .label {
    vertical-align: top;
    width: 50%;
    margin-top: 15px;
    display: inline-block;
  }
}

@media print, screen and (min-width: 64em) {
  footer .footer-content .nav-container .primary-nav {
    position: relative;
  }

  footer .footer-content .nav-container .primary-nav:before {
    content: "";
    width: 40px;
    height: 63px;
    background: url("logo-certified-corp.0556458f.png") 0 0 / contain no-repeat;
    display: block;
    position: absolute;
    top: 10px;
    left: -100px;
  }
}

footer .footer-content .copyright {
  display: block;
}

footer .footer-content .copyright .b-logo {
  float: right;
  width: 25px;
  height: 40px;
  background: url("logo-certified-corp.0556458f.png") 0 0 / contain no-repeat;
}

@media print, screen and (min-width: 64em) {
  footer .footer-content .copyright .b-logo {
    display: none;
  }
}

footer .footer-content .copyright p {
  color: #1d1e21;
  letter-spacing: 0;
  opacity: .4;
  width: 70%;
  float: left;
  font-family: Theinhardt-Regular;
  font-size: 14px;
  line-height: 24px;
}

footer .footer-content .copyright p .separator {
  margin: 0 10px;
  display: none;
}

@media print, screen and (min-width: 64em) {
  footer .footer-content .copyright p .separator {
    display: inline-block;
  }
}

footer .footer-content .copyright p a {
  color: #1d1e21;
  text-decoration: underline;
}

footer .footer-content .copyright p a:hover, footer .footer-content .copyright p a:active, footer .footer-content .copyright p a:focus {
  text-decoration: none;
}

@media screen and (max-height: 800px) and (min-width: 768px) {
  footer .footer-content .clocks-container {
    display: none;
  }
}

.btn-footer-equity-frameworks {
  color: #1d1e21;
  z-index: 100;
  background: #fff;
  border-top: 6px solid #4c71ff;
  border-left: 6px solid #4c71ff;
  border-right: 6px solid #4c71ff;
  padding: 20px;
  font-family: Theinhardt-Bold;
  font-size: 16px;
  line-height: 18px;
  position: fixed;
  bottom: 0;
  right: 30px;
}

.btn-footer-equity-frameworks:hover {
  color: #4c71ff;
}

header {
  width: 100%;
  z-index: 100;
  padding-top: 24px;
  transition: opacity .5s;
  position: absolute;
  top: 0;
  left: 0;
}

header.op0 {
  opacity: 0;
}

@media print, screen and (min-width: 48em) {
  header {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media print, screen and (min-width: 64em) {
  header.op0 {
    opacity: 1;
  }
}

@media (min-width: 1300px) {
  header {
    padding-top: 50px;
  }
}

@media print, screen and (min-width: 48em) {
  body.team-modal-visible header {
    z-index: 100;
    height: 115px;
    background-color: #fff;
    position: fixed;
    box-shadow: 0 0 36px #00000026;
  }
}

body.team-modal-visible header .hamburger, body.team-modal-visible header .btn-lang {
  display: none;
}

body.navigation-expanded header {
  position: fixed;
  display: block !important;
}

body.navigation-expanded header .hamburger span {
  background: #1d1e21;
}

body.navigation-expanded header .hamburger span:nth-child(1) {
  top: 5px;
  transform: rotate(135deg);
}

@media print, screen and (min-width: 48em) {
  body.navigation-expanded header .hamburger span:nth-child(1) {
    top: 9px;
  }
}

body.navigation-expanded header .hamburger span:nth-child(3) {
  top: 5px;
  transform: rotate(-135deg);
}

@media print, screen and (min-width: 48em) {
  body.navigation-expanded header .hamburger span:nth-child(3) {
    top: 9px;
  }
}

body.navigation-expanded header .logo svg .logo-letter {
  opacity: 0;
}

body.navigation-expanded header svg .logo-main-group {
  fill: #1d1e21;
}

@media (min-width: 1300px) {
  body.navigation-expanded header .tools {
    right: 65px;
  }
}

body.navigation-expanded header .btn-lang .ico-world svg #shape {
  stroke: #1d1e21;
}

body.navigation-expanded header .btn-lang span {
  color: #1d1e21;
}

body.navigation-expanded header .btn-lang .ico-triangle svg #shape, header.black .logo svg .logo-main-group {
  fill: #1d1e21;
}

header.black .hamburger span {
  background: #1d1e21;
}

header.black .btn-lang .ico-world svg #shape {
  stroke: #1d1e21;
}

header.black .btn-lang span {
  color: #1d1e21;
}

header.black .btn-lang .ico-triangle svg #shape {
  fill: #1d1e21;
}

header .column, header .false {
  height: 28px;
}

@media print, screen and (min-width: 64em) {
  header .column, header .false {
    height: 47px;
  }
}

header .logo {
  float: left;
  height: 28px;
  width: 40px;
  margin: 0 0 0 24px;
  position: fixed;
}

@media print, screen and (min-width: 64em) {
  header .logo {
    width: auto;
    height: auto;
    margin-left: 30px;
  }
}

@media (min-width: 1300px) {
  header .logo {
    margin-left: 50px;
  }
}

header .logo span {
  background: url("logo.a69ebf18.svg");
  display: none;
}

header .logo svg .logo-letter {
  opacity: 0;
}

@media print, screen and (min-width: 48em) {
  header .logo svg .logo-letter {
    opacity: 1;
  }
}

header .logo svg .logo-main-group {
  fill: #fff;
  -o-transition: all .2s linear;
  transition: all .2s linear;
}

header .tools {
  width: 120px;
  text-align: right;
  position: fixed;
  top: 32px;
  right: 24px;
}

@media (min-width: 1200px) {
  header .tools {
    width: 150px;
    top: 61px;
    right: 50px;
  }
}

header .tools:after {
  content: "";
  clear: both;
  display: table;
}

header .btn-lang {
  vertical-align: middle;
  float: left;
  display: inline-block;
}

header .btn-lang.hover .ico-triangle {
  transform: rotate(0);
}

header .btn-lang .ico-world {
  vertical-align: middle;
  width: 16px;
  height: 16px;
  display: inline-block;
}

header .btn-lang .ico-world svg {
  max-width: 100%;
  height: auto;
  display: block;
}

header .btn-lang .ico-world:before {
  background: url("ico-language.9c7823cd.svg");
  display: none;
}

header .btn-lang span {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 2px;
  vertical-align: middle;
  margin: 0 2px;
  font-family: Theinhardt-Medium;
  font-size: 18px;
  line-height: 1;
  display: inline-block;
}

header .btn-lang .ico-triangle {
  vertical-align: middle;
  width: 10px;
  height: 8px;
  display: inline-block;
  transform: rotate(-180deg);
}

header .btn-lang .ico-triangle svg {
  max-width: 100%;
  height: auto;
  display: block;
}

header .btn-lang .ico-triangle:before {
  background: url("ico-triangle.b5c322f3.svg");
  display: none;
}

header .hamburger {
  vertical-align: middle;
  width: 26px;
  height: 13px;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  header .hamburger {
    width: 44px;
    height: 22px;
  }
}

header .hamburger span {
  height: 3px;
  width: 100%;
  opacity: 1;
  background: #fff;
  transition: all .3s ease-in-out;
  display: block;
  position: absolute;
  left: 0;
  transform: rotate(0);
}

@media print, screen and (min-width: 48em) {
  header .hamburger span {
    height: 5px;
  }
}

header .hamburger span:nth-child(1) {
  top: 0;
}

header .hamburger span:nth-child(2) {
  display: none;
}

header .hamburger span:nth-child(3) {
  top: 10px;
}

@media print, screen and (min-width: 48em) {
  header .hamburger span:nth-child(3) {
    top: 17px;
  }
}

header .dropdown-pane {
  width: 150px;
  text-align: left;
  border: none;
  padding: 20px;
}

header .dropdown-pane.is-open {
  top: 40px !important;
  left: 0 !important;
}

header .dropdown-pane span {
  color: #1d1e21;
  margin-bottom: 2px;
  font-family: Theinhardt-Medium;
  font-size: 16px;
  display: block;
}

header .dropdown-pane .wpml-ls {
  padding-left: 0;
  display: block;
}

header .dropdown-pane .wpml-ls ul li a.wpml-ls-link {
  margin: 0;
  padding: 0;
}

header .dropdown-pane .wpml-ls ul li a.wpml-ls-link span {
  -o-transition: all .2s linear;
  color: #0000004d;
  letter-spacing: 0;
  margin: 0;
  font-family: PlantinMTPro-Light;
  font-size: 18px;
  line-height: 38px;
  transition: all .2s linear;
  display: block;
}

header .dropdown-pane .wpml-ls ul li a.wpml-ls-link span.wpml-ls-display {
  display: none;
}

header .dropdown-pane .wpml-ls ul li a.wpml-ls-link:hover span {
  color: #000;
}

.content {
  z-index: 12;
  background-color: #fff;
  padding: 65px 0;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .content {
    padding: 200px 0;
  }
}

.content.no-padding-top {
  padding-top: 0;
}

.content.no-padding-bottom {
  padding-bottom: 0;
}

.content.blog-type {
  padding-top: 50px;
}

@media print, screen and (min-width: 64em) {
  .content.blog-type {
    padding-top: 100px;
  }
}

.content h2 {
  color: #1d1e21;
  letter-spacing: 0;
  margin-bottom: 30px;
  margin-left: 20px;
  font-family: PlantinMTPro-Light;
  font-size: 30px;
  line-height: 34px;
}

.content h2 span {
  font-family: Theinhardt-Bold;
}

@media print, screen and (min-width: 64em) {
  .content h2 {
    max-width: 810px;
    margin-left: 160px;
    font-size: 68px;
    line-height: 76px;
  }
}

.content .home-intro {
  padding-left: 90px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .content .home-intro {
    max-width: 600px;
    float: right;
    margin: 0 175px 0 0;
  }
}

.content .home-intro p.main, body.page-template-templatestemplate-climate-lab-php .content .home-intro p, body.single-offices .content .home-intro p {
  opacity: .3;
  color: #1d1e21;
  letter-spacing: 0;
  margin-bottom: 30px;
  font-family: Theinhardt-Regular;
  font-size: 16px;
  line-height: 22px;
}

@media print, screen and (min-width: 64em) {
  .content .home-intro p.main, body.page-template-templatestemplate-climate-lab-php .content .home-intro p, body.single-offices .content .home-intro p {
    font-size: 24px;
    line-height: 40px;
  }
}

.content .home-intro a {
  color: #1d1e21;
  letter-spacing: 0;
  border-bottom: 1px solid #1d1e21;
  padding-bottom: 5px;
  font-family: Theinhardt-Medium;
  font-size: 16px;
  line-height: 40px;
}

@media print, screen and (min-width: 64em) {
  .content .home-intro a {
    font-size: 24px;
    line-height: 40px;
  }
}

.content .home-intro a:hover, .content .home-intro a:active, .content .home-intro a:focus {
  color: #4c71ff;
  border-color: #4c71ff;
}

.nav-container {
  position: relative;
}

.nav-container .btn-backup {
  opacity: .1;
  color: #1d1e21;
  z-index: 10;
  font-family: Theinhardt-Medium;
  font-size: 20px;
  display: none;
  position: absolute;
  top: 85px;
  right: -40px;
  transform: rotate(-90deg);
}

@media print, screen and (min-width: 64em) {
  .nav-container .btn-backup {
    font-size: 30px;
    right: 0;
  }
}

.nav-container .btn-backup:after {
  content: "";
  vertical-align: middle;
  width: 90px;
  height: 2px;
  background-color: #1d1e21;
  margin-left: 20px;
  display: inline-block;
}

.nav-container .contact-us.get-in-touch {
  text-align: center;
  background: #fff;
  padding: 30px 0;
  display: none;
}

@media print, screen and (min-width: 64em) {
  .nav-container .contact-us.get-in-touch {
    width: 100%;
    height: 300px;
    z-index: 10;
    padding: 0;
    position: absolute;
  }
}

.nav-container .contact-us.get-in-touch span {
  text-align: left;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .nav-container .contact-us.get-in-touch span {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.nav-container .contact-us.get-in-touch span a {
  color: #4c71ff;
}

.nav-container .newsletter {
  width: 100%;
  z-index: 10;
  height: calc(100vh - 90px);
  background: #fff;
  display: none;
  position: absolute;
  overflow-y: scroll;
}

@media print, screen and (min-width: 64em) {
  .nav-container .newsletter {
    height: calc(100vh - 139px);
    padding: 40px 0 30px;
  }
}

.nav-container .newsletter .submit-newsletter {
  position: relative;
  top: 5px;
}

.nav-container .newsletter .thank-you-message {
  margin-top: 90px;
  display: none;
}

@media print, screen and (min-width: 64em) {
  .nav-container .newsletter .thank-you-message {
    margin-top: 0;
  }
}

.nav-container .newsletter .thank-you-message span {
  color: #1d1e21;
  margin-bottom: 20px;
  font-family: Theinhardt-Bold;
  font-size: 22px;
  line-height: 40px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .nav-container .newsletter .thank-you-message span {
    font-size: 32px;
  }
}

.nav-container .newsletter .thank-you-message .btn-back {
  color: #1d1e21;
  font-family: Theinhardt-Medium;
  font-size: 16px;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .nav-container .newsletter .thank-you-message .btn-back {
    font-size: 20px;
  }
}

body.not-ios .nav-container .newsletter .thank-you-message .btn-back:hover {
  color: #4c71ff;
}

body.not-ios .nav-container .newsletter .thank-you-message .btn-back:hover:before {
  background: #4c71ff;
}

.nav-container .newsletter .thank-you-message .btn-back:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #1d1e21;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.nav-container .newsletter .headline {
  color: #1d1e21;
  margin-bottom: 50px;
  font-family: PlantinMTPro-Light;
  font-size: 30px;
  line-height: 34px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .nav-container .newsletter .headline {
    margin: 0;
    font-size: 50px;
    line-height: 50px;
  }
}

.nav-container .newsletter .headline strong {
  font-family: Theinhardt-Bold;
  font-weight: normal;
}

.nav-container .newsletter form {
  text-align: right;
}

@media print, screen and (min-width: 64em) {
  .nav-container .newsletter form {
    text-align: left;
  }
}

.nav-container .newsletter form input:not([type="radio"]) {
  color: #bbbbbc;
  letter-spacing: 0;
  height: 54px;
  box-shadow: none;
  text-align: left;
  border: none;
  padding: 0;
  font-family: Theinhardt-Regular;
  font-size: 20px;
  line-height: 54px;
}

@media print, screen and (min-width: 64em) {
  .nav-container .newsletter form input:not([type="radio"]) {
    margin: 0;
  }
}

.nav-container .newsletter form input:not([type="radio"]):focus, .nav-container .newsletter form input:not([type="radio"]):active {
  border-bottom: 1px solid #ccc;
}

.nav-container .newsletter form small {
  font-size: 13px;
}

.nav-container .newsletter form .form-error {
  margin-bottom: 0;
}

.nav-container .newsletter form .gdpr_label {
  vertical-align: top;
  width: 80px;
  color: #bbbbbc;
  text-align: left;
  font-family: Theinhardt-Regular;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
}

.nav-container .newsletter form .submit-newsletter {
  color: #4c71ff;
  letter-spacing: 0;
  border-bottom: 1px solid #4c71ff;
  font-family: Theinhardt-Medium;
  font-size: 20px;
  line-height: 40px;
}

@media print, screen and (min-width: 64em) {
  .nav-container .primary-nav {
    text-align: center;
  }

  .nav-container .primary-nav ul {
    vertical-align: top;
    column-count: 2;
    display: block;
  }
}

.nav-container .primary-nav ul li {
  display: block;
}

.nav-container .primary-nav ul li.active a, .nav-container .primary-nav ul li.current a {
  font-family: PlantinMTPro-LightItalic;
}

.nav-container .primary-nav ul li a {
  color: #1d1e21;
  letter-spacing: 0;
  text-align: right;
  font-family: Theinhardt-Bold;
  font-size: 45px;
  line-height: 60px;
  display: block;
}

body.lang-fr .nav-container .primary-nav ul li a {
  font-size: 28px;
  line-height: 44px;
}

@media print, screen and (min-width: 64em) {
  .nav-container .primary-nav ul li a {
    text-align: left;
  }
}

body.not-ios .nav-container .primary-nav ul li a:hover, body.not-ios .nav-container .primary-nav ul li a:active, body.not-ios .nav-container .primary-nav ul li a:focus {
  font-family: PlantinMTPro-LightItalic;
  position: relative;
  top: 6px;
}

.nav-container .secondary-nav {
  float: right;
  text-align: right;
  margin-top: 30px;
}

@media print, screen and (min-width: 64em) {
  .nav-container .secondary-nav {
    float: none;
    text-align: center;
    margin: 0 auto;
  }
}

.nav-container .secondary-nav ul {
  width: 100px;
  vertical-align: top;
  display: inline-block;
}

@media print, screen and (min-width: 64em) {
  .nav-container .secondary-nav ul {
    width: 140px;
  }
}

.nav-container .secondary-nav ul li {
  display: block;
}

.nav-container .secondary-nav ul li a {
  opacity: .2;
  color: #1d1e21;
  letter-spacing: 0;
  text-align: right;
  font-family: Theinhardt-Regular;
  font-size: 16px;
  line-height: 30px;
  display: block;
}

.nav-container .secondary-nav ul li a:hover, .nav-container .secondary-nav ul li a:active, .nav-container .secondary-nav ul li a:focus {
  opacity: .5;
}

@media print, screen and (min-width: 64em) {
  .nav-container .secondary-nav ul li a {
    text-align: left;
    font-size: 22px;
    line-height: 43px;
  }
}

.equity-aux-nav {
  max-width: 300px;
  margin-top: 10px;
  margin-left: 10px;
  display: inline-block;
}

.equity-aux-nav span {
  color: #4c71ff;
  text-align: right;
  font-family: Theinhardt-Medium;
  font-size: 14px;
  line-height: 14px;
  display: block;
}

@media print, screen and (min-width: 48em) {
  .equity-aux-nav span {
    text-align: left;
    font-size: 18px;
    line-height: 18px;
  }
}

.equity-aux-nav a {
  color: #1d1e21;
  text-align: right;
  font-family: Theinhardt-Medium;
  font-size: 16px;
  line-height: 16px;
  display: block;
}

.equity-aux-nav a:hover {
  color: #4c71ff;
}

@media print, screen and (min-width: 48em) {
  .equity-aux-nav a {
    text-align: left;
    font-size: 22px;
    line-height: 22px;
  }
}

@media print, screen and (max-width: 47.9988em) {
  html.navigation-expanded {
    overflow-y: auto;
  }
}

body {
  background-color: #fff;
}

body.black {
  background-color: #1d1e21;
}

body.navigation-expanded, body.team-modal-visible {
  position: relative;
  overflow: hidden;
}

@media print, screen and (max-width: 47.9988em) {
  body.navigation-expanded, body.team-modal-visible {
    height: auto !important;
  }
}

body.navigation-expanded .barba-container > :not(:nth-child(-n+2)) {
  display: none;
}

.relative {
  position: relative;
}

[data-whatinput="mouse"] a {
  outline: 0;
}

section.shadow, div.shadow {
  position: relative;
}

section.shadow:after, div.shadow:after {
  content: "";
  height: 20px;
  width: 100%;
  background: linear-gradient(#0000000d 0%, #fff 100%);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.form-salesforce {
  margin-top: 40px;
  margin-bottom: 40px;
}

.reip-container.op0 {
  opacity: 0;
}

.reip-container .scroll {
  opacity: 0;
  margin-bottom: 70px;
}

.reip-container.landing .reip-hero {
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  padding-top: 70px;
  display: flex;
}

@media print, screen and (min-width: 48em) {
  .reip-container.landing .reip-hero {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 120px;
    padding-top: 0;
  }
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero {
    margin-top: 100px;
  }
}

.reip-container.landing .reip-hero .hero-column-left {
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .hero-column-left {
    width: 45%;
    margin-bottom: 0;
    position: relative;
  }
}

.reip-container.landing .reip-hero .hero-column-right {
  width: 100%;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .hero-column-right {
    width: 52%;
  }

  .reip-container.landing .reip-hero .desktop-full-height {
    height: calc(100vh - 90px);
    position: absolute;
  }

  .reip-container.landing .reip-hero .desktop-full-height.spacer {
    position: static;
  }

  .reip-container.landing .reip-hero .desktop-full-height.spacer.w43 {
    width: 43vw;
    max-width: 500px;
  }
}

.reip-container.landing .reip-hero .reip-hero-content_headline {
  color: #1d1e21;
  font-family: Theinhardt-Regular;
  font-size: 26px;
  line-height: 27px;
  display: block;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .reip-hero-content_headline {
    font-size: 40px;
    line-height: 42px;
  }
}

.reip-container.landing .reip-hero .reip-hero-content_headline:not(:first-child) {
  margin-top: 15px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .reip-hero-content_headline:not(:first-child) {
    margin-top: 20px;
  }
}

.reip-container.landing .reip-hero .reip-hero-content_headline span {
  background-color: #b7c6ff;
  padding: 0 3px;
  line-height: 23px;
  display: inline-block;
}

@media print, screen and (min-width: 64em) {
  .reip-container.landing .reip-hero .reip-hero-content_headline span {
    line-height: 33px;
  }
}

.reip-container.landing .reip-hero .vertical-align {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .vertical-slide {
    width: 43vw;
    max-width: 500px;
    position: fixed;
    top: 90px;
  }
}

.reip-container.landing .reip-hero #content-desktop {
  display: none;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero #content-desktop {
    display: block;
  }
}

.reip-container.landing .reip-hero .reip-drag-container {
  position: relative;
}

.reip-container.landing .reip-hero .reip-drag-container.fixed {
  position: fixed;
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-image {
  height: 408px;
  width: 330px;
  margin: 0 auto;
  display: block;
  position: relative;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .reip-drag-container .reip-drag-image {
    width: 610px;
    height: 371px;
  }
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-image .reip-image {
  z-index: 1;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%) !important;
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-image .reip-image #drag_image_1, .reip-container.landing .reip-hero .reip-drag-container .reip-drag-image .reip-image #drag_image_2, .reip-container.landing .reip-hero .reip-drag-container .reip-drag-image .reip-image #drag_image_3 {
  display: none;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .reip-drag-container .reip-drag-image .reip-image #drag_image_1, .reip-container.landing .reip-hero .reip-drag-container .reip-drag-image .reip-image #drag_image_2, .reip-container.landing .reip-hero .reip-drag-container .reip-drag-image .reip-image #drag_image_3 {
    display: block;
  }
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-image .reip-image img {
  min-width: 245px;
  min-height: 370px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable, .reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable-handle {
  z-index: 2;
  cursor: move;
  display: block;
  position: absolute;
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable.top, .reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable-handle.top {
  top: 0;
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable.bottom, .reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable-handle.bottom {
  bottom: 0;
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable.left, .reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable-handle.left {
  left: 0;
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable.right, .reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable-handle.right {
  right: 0;
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable-handle {
  height: 134px;
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable-handle.left {
  width: 190px;
}

.reip-container.landing .reip-hero .reip-drag-container .reip-drag-draggable-handle.right {
  width: 245px;
}

.reip-container.landing .reip-hero .reip-drag-container .label {
  height: 50px;
  color: #1d1e21;
  text-align: center;
  background: #fff;
  border: 3px solid #000;
  font-family: Theinhardt-Medium;
  font-size: 36px;
  line-height: 44px;
  display: block;
  position: relative;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .reip-drag-container .label {
    height: 67px;
    font-size: 50px;
    line-height: 61px;
  }
}

.reip-container.landing .reip-hero .reip-drag-container .label .draggable-img-pattern {
  opacity: 0;
  width: 100%;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.reip-container.landing .reip-hero .reip-drag-container .label .hidden {
  opacity: 0;
}

.reip-container.landing .reip-hero .reip-drag-container .label.choose {
  width: 140px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .reip-drag-container .label.choose {
    width: 194px;
  }
}

.reip-container.landing .reip-hero .reip-drag-container .label.equity {
  width: 122px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .reip-drag-container .label.equity {
    width: 168px;
  }
}

.reip-container.landing .reip-hero .reip-drag-container .label.evidence {
  width: 169px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .reip-drag-container .label.evidence {
    width: 220px;
  }
}

.reip-container.landing .reip-hero .reip-drag-container .label.both {
  width: 101px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .reip-drag-container .label.both {
    width: 139px;
  }
}

.reip-container.landing .reip-hero .reip-drag-container .label.equity, .reip-container.landing .reip-hero .reip-drag-container .label.both {
  top: -3px;
}

.reip-container.landing .reip-hero .reip-drag-container .label.both {
  left: -21px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-hero .reip-drag-container .label.both {
    left: -26px;
  }
}

.reip-container.landing .reip-hero .reip-drag-container .btn-tap {
  z-index: 11;
  width: auto;
  text-align: center;
  color: #1d1e21;
  white-space: nowrap;
  letter-spacing: -.02rem;
  border-bottom: thin solid #000;
  padding-bottom: 2px;
  font-family: Theinhardt-Regular;
  font-size: 18px;
  line-height: 18px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reip-container.landing .reip-hero .reip-drag-container .btn-tap:hover {
  color: #4c71ff;
  border-bottom: thin solid #4c71ff;
}

.reip-container.landing .intro {
  margin-bottom: 80px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .intro {
    margin-bottom: 0;
  }
}

.reip-container.landing .intro p {
  color: #1d1e21;
  letter-spacing: -.02rem;
  font-family: Theinhardt-Regular;
  font-size: 20px;
  line-height: 22px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .intro p {
    font-size: 26px;
    line-height: 30px;
  }
}

.reip-container.landing .intro p span:not(.highlight) {
  color: #4c71ff;
  font-family: Theinhardt-Medium;
}

.reip-container.landing .intro p span.highlight {
  background-color: #b7c6ff;
  padding: 0 3px;
  line-height: 18px;
  display: inline-block;
}

@media print, screen and (min-width: 64em) {
  .reip-container.landing .intro p span.highlight {
    line-height: 24px;
  }
}

.reip-container.landing .reip-content {
  max-width: 750px;
  margin: 0 auto;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content {
    z-index: 10;
    max-width: none;
    background: #fff;
    margin-top: 80px;
    position: relative;
  }
}

.reip-container.landing .reip-content.mobile {
  display: block;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content.mobile {
    display: none;
  }
}

.reip-container.landing .reip-content.desktop {
  display: none;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content.desktop {
    display: block;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block {
  margin-bottom: 100px;
  display: block;
  position: relative;
}

.reip-container.landing .reip-content .explore-area .explore-area-block:after {
  content: "";
  clear: both;
  display: table;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block {
    width: 939px;
    margin: 0 auto 200px;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active .block-head, .reip-container.landing .reip-content .explore-area .explore-area-block:hover .block-head {
  margin-right: 5%;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block.active .block-head, .reip-container.landing .reip-content .explore-area .explore-area-block:hover .block-head {
    margin-right: 0;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active .block-head .cta-explore, .reip-container.landing .reip-content .explore-area .explore-area-block:hover .block-head .cta-explore, .reip-container.landing .reip-content .explore-area .explore-area-block.active .block-head .block-head-img, .reip-container.landing .reip-content .explore-area .explore-area-block:hover .block-head .block-head-img {
  top: 20px;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active .block-body, .reip-container.landing .reip-content .explore-area .explore-area-block:hover .block-body {
  margin-left: 5%;
  top: 0;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block.active .block-body, .reip-container.landing .reip-content .explore-area .explore-area-block:hover .block-body {
    margin-left: 0;
    top: 20px;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-01 .cta-explore, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-01 .cta-explore {
  background: #ff8557;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-01 .block-body, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-01 .block-body {
  background: #ffe7dd;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-01 .block-body p span, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-01 .block-body p span {
  background: #ffbca3;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-02 .cta-explore, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-02 .cta-explore {
  color: #fff;
  background: #4c71ff;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-02 .block-body, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-02 .block-body {
  background: #e9ebff;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-02 .block-body p span, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-02 .block-body p span {
  background: #a5b8ff;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-03 .cta-explore, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-03 .cta-explore {
  background: #00bf99;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-03 .block-body, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-03 .block-body {
  background: #dcf4ee;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-03 .block-body p span, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-03 .block-body p span {
  background: #7fdfcc;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-04 .cta-explore, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-04 .cta-explore {
  color: #fff;
  background: #1a1c24;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-04 .block-body, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-04 .block-body {
  background: #ddddde;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-04 .block-body p span, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-04 .block-body p span {
  background: #a3a4a7;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-05 .cta-explore, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-05 .cta-explore {
  background: #ffba0d;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-05 .block-body, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-05 .block-body {
  background: #fff1cf;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.active.section-05 .block-body p span, .reip-container.landing .reip-content .explore-area .explore-area-block:hover.section-05 .block-body p span {
  background: #ffd97a;
}

.reip-container.landing .reip-content .explore-area .explore-area-block a {
  display: block;
  position: relative;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block a {
    display: flex;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block .border {
  border-style: solid;
  border-width: 3px;
}

.reip-container.landing .reip-content .explore-area .explore-area-block .block-head, .reip-container.landing .reip-content .explore-area .explore-area-block .block-body {
  width: 90%;
  -o-transition: all .2s linear;
  transition: all .2s linear;
  position: relative;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block .block-head, .reip-container.landing .reip-content .explore-area .explore-area-block .block-body {
    width: 464px;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block .block-head {
  height: 86vw;
  float: right;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block .block-head {
    height: 330px;
    order: 2;
    margin-left: -2px;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block .block-head .cta-explore {
  z-index: 10;
  color: #1d1e21;
  letter-spacing: -.03rem;
  -o-transition: all .2s linear;
  background: #fff;
  padding: 10px;
  font-family: Theinhardt-Regular;
  font-size: 15px;
  line-height: 15px;
  transition: all .2s linear;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block .block-head .cta-explore {
    height: 41px;
    letter-spacing: -.02rem;
    padding: 0 10px;
    font-size: 20px;
    line-height: 38px;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block .block-head .block-head-img {
  height: calc(86vw - 15px);
  width: 100%;
  -o-transition: all .2s linear;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all .2s linear;
  position: absolute;
  top: 38px;
  right: 0;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block .block-head .block-head-img {
    height: 464px;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block .block-body {
  float: left;
  clear: both;
  background: #fff;
  padding: 18px;
  position: relative;
  top: 20px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block .block-body {
    -o-transition: all .2s linear;
    height: 464px;
    order: 1;
    padding: 31px;
    transition: all .2s linear;
    top: 0;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block .block-body .block-headline {
  color: #1d1e21;
  margin-bottom: 100px;
  font-family: Theinhardt-Medium;
  font-size: 26px;
  line-height: 27px;
  display: block;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block .block-body .block-headline {
    margin-bottom: 50px;
    font-size: 42px;
    line-height: 44px;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block .block-body p {
  color: #1d1e21;
  margin-bottom: 0;
  font-family: Theinhardt-Regular;
  font-size: 27px;
  line-height: 30px;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block .block-body p {
    width: 85%;
    margin-bottom: 31px;
    font-size: 40px;
    line-height: 42px;
    position: absolute;
    bottom: 0;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block .block-body p span {
  -o-transition: all .2s linear;
  background: #fff;
  padding-left: 2px;
  padding-right: 2px;
  line-height: 31px;
  transition: all .2s linear;
}

.reip-container.landing .reip-content .explore-area .explore-area-block .block-body p .pattern-ico {
  vertical-align: baseline;
  width: 33px;
  margin-right: 5px;
  display: inline-block;
}

@media screen and (min-width: 75em) {
  .reip-container.landing .reip-content .explore-area .explore-area-block .block-body p .pattern-ico {
    width: 52px;
  }
}

.reip-container.landing .reip-content .explore-area .explore-area-block.section-01 .border {
  border-color: #ff8557;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.section-02 .border {
  border-color: #4c71ff;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.section-03 .border {
  border-color: #00bf99;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.section-04 .border {
  border-color: #1a1c24;
}

.reip-container.landing .reip-content .explore-area .explore-area-block.section-05 .border {
  border-color: #ffba0d;
}

.reip-container .equity_hidden_iframe, .reip-container .year_in_review_hidden_iframe {
  height: 1px;
  visibility: hidden;
  position: absolute;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-slider-gsap-spacer {
    height: 200vh;
  }
}

.reip-container .reip-detail-slider {
  background-color: #fff;
  flex-direction: column;
  flex: auto;
  display: flex;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider.pinned {
    z-index: 12;
    width: 100vw;
    position: fixed;
    top: 0;
  }

  .reip-container .reip-detail-slider {
    height: auto;
    flex-direction: row;
  }
}

.reip-container .reip-detail-slider .reip-detail-hero {
  width: 100vw;
  height: 0;
  padding-bottom: 109.5%;
  position: relative;
  overflow: hidden;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider .reip-detail-hero {
    width: 50vw;
    height: 100vh;
    order: 2;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    right: 0;
  }

  .reip-container .reip-detail-slider .reip-detail-hero svg {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.reip-container .reip-detail-slider .reip-detail-hero .reip-hero-content {
  color: #1d1e21;
  letter-spacing: -.5px;
  opacity: 0;
  min-width: 300px;
  text-align: center;
  font-family: Theinhardt-Regular;
  font-size: 20px;
  line-height: 23px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider .reip-detail-hero .reip-hero-content {
    letter-spacing: -.75px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 26px;
    line-height: 30px;
  }
}

.reip-container .reip-detail-slider .reip-detail-body {
  flex: 1;
  padding: 24px 18px 160px;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider .reip-detail-body {
    width: 50vw;
    height: 100vh;
    order: 1;
    align-items: center;
    padding: 40px;
    display: flex;
    position: absolute;
  }
}

.reip-container .reip-detail-slider .reip-detail-body .slick-dots {
  width: 100%;
  background: #fff;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider .reip-detail-body .slick-dots {
    width: 20px;
    background: none;
    margin: 0;
    padding: 0;
    inset: 45vh 10px auto auto;
    transform: none;
  }
}

.reip-container .reip-detail-slider .reip-detail-body .slick-dots:before {
  content: "Tap/Swipe";
  text-align: center;
  color: #1d1e21;
  font-family: Theinhardt-Regular;
  font-size: 15px;
  line-height: 15px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider .reip-detail-body .slick-dots:before {
    display: none;
  }
}

.reip-container .reip-detail-slider .reip-detail-body .slick-dots.text-tap-scroll:before {
  content: "Tap/Scroll";
}

.reip-container .reip-detail-slider .reip-detail-body .slick-dots li {
  margin: 0 2px;
  display: inline-block;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider .reip-detail-body .slick-dots li {
    vertical-align: inherit;
    margin: 5px 0;
    display: block;
  }
}

.reip-container .reip-detail-slider .reip-detail-body .slick-dots li:hover, .reip-container .reip-detail-slider .reip-detail-body .slick-dots li:focus {
  background-color: none !important;
}

.reip-container .reip-detail-slider .reip-detail-body .slick-dots li button {
  width: 12px;
  height: 12px;
  margin-top: 0;
  margin-left: 0;
}

.reip-container .reip-detail-slider .reip-detail-body .slick-dots li button:hover, .reip-container .reip-detail-slider .reip-detail-body .slick-dots li button:focus {
  background-color: #0000 !important;
}

.reip-container .reip-detail-slider .reip-detail-body .slick-dots li button:hover .slick-dot-icon:before, .reip-container .reip-detail-slider .reip-detail-body .slick-dots li button:focus .slick-dot-icon:before {
  color: inherit;
}

.reip-container .reip-detail-slider .reip-detail-body .slick-dots li.slick-active button .slick-dot-icon {
  opacity: 1;
}

.reip-container .reip-detail-slider .reip-detail-body .slick-dots li.slick-active button .slick-dot-icon:before {
  margin-top: 0;
  margin-left: 0;
  font-size: 12px;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider .reip-detail-body .slick-dots li.slick-active button .slick-dot-icon, .reip-container .reip-detail-slider .reip-detail-body .slick-dots li .slick-dot-icon {
    color: #fff !important;
  }
}

.reip-container .reip-detail-slider .reip-detail-body .reip-slider-container {
  opacity: 0;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider .reip-detail-body .reip-slider-container {
    margin: 0;
  }

  .reip-container .reip-detail-slider .reip-detail-body .reip-slider-container .reip-slide {
    min-height: 95vh;
    position: relative;
  }

  .reip-container .reip-detail-slider .reip-detail-body .reip-slider-container .reip-slide .slide-content {
    width: 44vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .reip-container .reip-detail-slider .reip-detail-body .reip-slider-container .reip-slide .slide-content.bottom-align {
    top: auto;
    bottom: 15px;
    transform: none;
  }
}

.reip-container .reip-detail-slider .reip-detail-body .headline {
  color: #1d1e21;
  margin-bottom: 20px;
  font-family: Theinhardt-Medium;
  font-size: 24px;
  line-height: 25px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider .reip-detail-body .headline {
    font-size: 42px;
    line-height: 44px;
  }
}

.reip-container .reip-detail-slider .reip-detail-body p {
  color: #1d1e21;
  letter-spacing: -.75px;
  font-family: Theinhardt-Regular;
  font-size: 26px;
  line-height: 29px;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-slider .reip-detail-body p {
    margin: 0;
    font-size: 40px;
    line-height: 42px;
  }
}

.reip-container .reip-detail-slider .reip-detail-body p span {
  padding-left: 3px;
  padding-right: 3px;
}

.reip-container .reip-detail-wrap-up {
  z-index: 14;
  background-color: #fff;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio {
    display: flex;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_hero {
  width: 100%;
  height: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid;
  padding-bottom: 100%;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_hero {
    width: 50%;
    height: 728px;
    padding-bottom: 0;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_hero .how-to-choose-both {
  z-index: 5;
  width: 186px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_hero .how-to-choose-both {
    width: 307px;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_hero .how-to-choose-both svg {
  max-width: 100%;
  display: block;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content {
  padding: 34px 18px;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content {
    width: 50%;
    padding: 170px 60px 50px;
  }

  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content :not(.headline) {
    letter-spacing: -.02rem;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .cta-hear-a-scenario {
  height: 75px;
  text-align: center;
  color: #1d1e21;
  -o-transition: all .2s linear;
  border-style: solid;
  border-width: 3px;
  margin-bottom: 45px;
  padding: 0 20px;
  font-family: Theinhardt-Regular;
  font-size: 24px;
  line-height: 56px;
  transition: all .2s linear;
  display: none;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .cta-hear-a-scenario i {
  vertical-align: middle;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
  position: relative;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .cta-hear-a-scenario i:before {
  content: "";
  width: 0;
  height: 0;
  border: 9px solid #0000;
  border-left: 15.6px solid #fff;
  border-right-width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .cta-hear-a-scenario {
    display: inline-block;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .cta-hear-a-scenario:hover {
  cursor: pointer;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .headline {
  color: #1d1e21;
  margin-bottom: 20px;
  font-family: Theinhardt-Regular;
  font-size: 29px;
  line-height: 30px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .headline {
    font-size: 40px;
    line-height: 42px;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-player {
  margin-bottom: 20px;
  display: block;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-player .audio-player {
  height: 30px;
  width: 100%;
  position: relative;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-player .audio-player .timeline {
  width: calc(100% - 35px);
  height: 5px;
  cursor: pointer;
  position: absolute;
  top: 19px;
  right: 0;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-player .audio-player .timeline .progress {
  width: 0%;
  height: 100%;
  transition: all .25s;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-player .audio-player .time {
  color: #1d1e21;
  text-align: right;
  font-family: Theinhardt-Regular;
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  top: 0;
  right: 0;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-player .audio-player .controls {
  width: 35px;
  position: absolute;
  top: 12px;
  left: 0;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.play {
  cursor: pointer;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.pause {
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: relative;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.pause:before {
  content: "";
  height: 20px;
  width: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.pause:after {
  content: "";
  height: 20px;
  width: 4px;
  position: absolute;
  top: 0;
  right: 8px;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-author {
  letter-spacing: -.01rem;
  align-items: center;
  margin-bottom: 28px;
  display: flex;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-author {
    margin-bottom: 50px;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-author .wrap-author_avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 13px;
  overflow: hidden;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-author .wrap-author_title {
  color: #1d1e21;
  font-family: Theinhardt-Regular;
  font-size: 15px;
  line-height: 17px;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-author .wrap-author_title {
    font-size: 20px;
    line-height: 21px;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-transcript {
  border-top: 1px solid #1a1c24;
  border-bottom: 1px solid #1a1c24;
  margin-bottom: 35px;
  padding: 11px 0 11px 5px;
  display: block;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-transcript .wrap-transcript_cta-read {
  color: #1d1e21;
  letter-spacing: -.02rem;
  font-family: Theinhardt-Regular;
  font-size: 15px;
  line-height: 15px;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-transcript .wrap-transcript_cta-read {
    font-size: 20px;
    line-height: 21px;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-transcript .wrap-transcript_cta-read i {
  vertical-align: middle;
  width: 8px;
  height: 17px;
  margin-right: 9px;
  display: inline-block;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-transcript .wrap-transcript_cta-read i svg {
  max-width: 100%;
  max-height: 100%;
  -o-transition: all .2s linear;
  transition: all .2s linear;
  display: block;
  transform: rotate(0);
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-transcript .wrap-transcript_cta-read.open svg {
  transform: rotate(90deg);
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-transcript .wrap-transcript_content {
  padding-top: 20px;
  display: none;
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-transcript .wrap-transcript_content p {
  color: #1d1e21;
  letter-spacing: -.3px;
  margin-bottom: .75rem;
  font-family: Theinhardt-Regular;
  font-size: 15px;
  line-height: 18px;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .wrap-transcript .wrap-transcript_content p {
    font-size: 20px;
    line-height: 21px;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .cta-guide {
  -o-transition: all .2s linear;
  letter-spacing: -.01rem;
  color: #1d1e21;
  background-color: #fff;
  border-style: solid;
  border-width: 3px;
  padding: 7px 9px;
  font-family: Theinhardt-Regular;
  font-size: 15px;
  line-height: 15px;
  transition: all .2s linear;
  display: inline-block;
}

@media print, screen and (min-width: 64em) {
  .reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .cta-guide {
    letter-spacing: -.02rem;
    padding: 10px;
    font-size: 20px;
    line-height: 21px;
  }
}

.reip-container .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_content .cta-guide:hover {
  cursor: pointer;
}

.reip-container .you-can-choose-both {
  background: #1a1c24;
  padding: 64px 0;
}

.reip-container .you-can-choose-both_headline {
  color: #fff;
  margin-bottom: 42px;
  font-family: Theinhardt-Medium;
  font-size: 26px;
  line-height: 27px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .reip-container .you-can-choose-both_headline {
    margin-bottom: 85px;
    font-size: 42px;
    line-height: 44px;
  }

  .reip-container .you-can-choose-both_recaptcha-wrap {
    width: 50%;
  }
}

.reip-container .you-can-choose-both .g-recaptcha {
  border: none;
  padding: 10px 0;
}

.reip-container .you-can-choose-both .g-recaptcha > div {
  margin: auto;
}

@media print, screen and (min-width: 64em) {
  .reip-container .you-can-choose-both .g-recaptcha {
    margin: 20px 0 40px;
  }

  .reip-container .you-can-choose-both .g-recaptcha > div {
    margin: 0;
  }

  .reip-container .you-can-choose-both_form-container {
    max-width: 932px;
    align-items: flex-start;
    margin: 0 auto;
    display: flex;
  }
}

.reip-container .you-can-choose-both_form-container_fields {
  border-style: solid;
  border-width: 3px;
}

.reip-container .you-can-choose-both_form-container_fields p {
  color: #fff;
  padding: 22px 15px 60px;
  font-family: Theinhardt-Regular;
  font-size: 26px;
  line-height: 27px;
}

@media print, screen and (min-width: 64em) {
  .reip-container .you-can-choose-both_form-container_fields p {
    padding: 21px 21px 44px;
    font-size: 40px;
    line-height: 42px;
  }
}

.reip-container .you-can-choose-both_form-container_fields input {
  width: 100%;
  height: 48px;
  color: #fff;
  cursor: pointer;
  -o-transition: all .2s linear;
  background-color: #0000;
  border: none;
  margin: 0;
  font-family: Theinhardt-Regular;
  font-size: 17px;
  line-height: 48px;
  transition: all .2s linear;
}

@media print, screen and (min-width: 64em) {
  .reip-container .you-can-choose-both_form-container_fields input {
    height: 60px;
    font-size: 26px;
    line-height: 56px;
  }
}

.reip-container .you-can-choose-both_form-container_fields input::placeholder {
  color: #fff;
}

.reip-container .you-can-choose-both_form-container_fields input:-ms-input-placeholder {
  color: #fff;
}

.reip-container .you-can-choose-both_form-container_fields input::-moz-placeholder {
  color: #fff;
}

.reip-container .you-can-choose-both_form-container_fields .border.top {
  border-top-style: solid;
  border-top-width: 3px;
}

.reip-container .you-can-choose-both_form-container_fields .border.right {
  border-right-style: solid;
  border-right-width: 3px;
}

@media print, screen and (min-width: 64em) {
  .reip-container .you-can-choose-both_form-container_graphic {
    width: 50%;
    margin-left: -2px;
  }
}

.reip-container .you-can-choose-both_form-container_graphic .reip-share-graphic {
  border-style: solid;
  border-width: 3px;
}

.reip-container .you-can-choose-both_form-container_graphic .reip-share-graphic video {
  height: 100%;
  width: 100%;
  margin: auto;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .reip-container .you-can-choose-both_form-container_graphic .reip-share-graphic {
    border-top-style: solid;
    border-top-width: 3px;
  }
}

.reip-container .you-can-choose-both_form-container_graphic .reip-social-lnks {
  text-align: center;
  padding: 10px 0;
}

@media print, screen and (min-width: 64em) {
  .reip-container .you-can-choose-both_form-container_graphic .reip-social-lnks {
    text-align: right;
    padding: 10px 0;
  }
}

.reip-container .you-can-choose-both_form-container_graphic .reip-social-lnks a {
  vertical-align: middle;
  letter-spacing: 0;
  color: #fff;
  border-bottom: thin solid #0000;
  margin-right: 10px;
  font-family: Theinhardt-Regular;
  font-size: 15px;
  line-height: 15px;
  display: inline-block;
}

@media print, screen and (min-width: 64em) {
  .reip-container .you-can-choose-both_form-container_graphic .reip-social-lnks a {
    margin-right: 24px;
    font-size: 20px;
    line-height: 20px;
  }
}

.reip-container .you-can-choose-both_form-container_graphic .reip-social-lnks a:last-of-type {
  margin-right: 0;
}

.reip-container .you-can-choose-both_form-container_graphic .reip-social-lnks a:hover {
  border-bottom: thin solid #fff;
}

.reip-container .explore-more-areas {
  background: #1a1c24;
  padding: 20px 0;
}

.reip-container .explore-more-areas_headline {
  vertical-align: middle;
  color: #fff;
  margin-left: 30px;
  margin-right: 30px;
  font-family: Theinhardt-Medium;
  font-size: 26px;
  line-height: 27px;
  display: inline-block;
}

@media print, screen and (min-width: 64em) {
  .reip-container .explore-more-areas_headline {
    font-size: 42px;
    line-height: 44px;
  }
}

.reip-container .explore-more-areas_btn-slide {
  vertical-align: middle;
  width: 12px;
  height: 21px;
  display: inline-block;
}

.reip-container .explore-more-areas_btn-slide svg {
  max-width: 100%;
  display: block;
}

.reip-container .explore-more-areas_slider {
  padding: 42px 0;
}

.reip-container .explore-more-areas .slick-slide {
  opacity: .7;
  margin: 0 10px;
  padding-bottom: 10px;
  width: 170px !important;
}

@media print, screen and (min-width: 64em) {
  .reip-container .explore-more-areas .slick-slide {
    width: 293px !important;
  }
}

.reip-container .explore-more-areas .slick-slide.slick-center {
  opacity: 1;
}

.reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide_cta {
  letter-spacing: -.02rem;
}

.reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide--section-1 a:hover .explore-more-areas_slide_headline, .reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide--section-1 a:hover .explore-more-areas_slide_cta {
  background: #ff855799;
}

.reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide--section-2 a:hover .explore-more-areas_slide_headline, .reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide--section-2 a:hover .explore-more-areas_slide_cta {
  background: #4c71ff99;
}

.reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide--section-3 a:hover .explore-more-areas_slide_headline, .reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide--section-3 a:hover .explore-more-areas_slide_cta {
  background: #00bf9999;
}

.reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide--section-4 a:hover .explore-more-areas_slide_headline, .reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide--section-4 a:hover .explore-more-areas_slide_cta {
  background: #ffffff80;
}

.reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide--section-5 a:hover .explore-more-areas_slide_headline, .reip-container .explore-more-areas .slick-slide.slick-center .explore-more-areas_slide--section-5 a:hover .explore-more-areas_slide_cta {
  background: #ffba0d99;
}

.reip-container .explore-more-areas_slide--section-1 .explore-more-areas_slide_image, .reip-container .explore-more-areas_slide--section-1 .explore-more-areas_slide_headline, .reip-container .explore-more-areas_slide--section-1 .explore-more-areas_slide_cta {
  border-color: #ff8557;
}

.reip-container .explore-more-areas_slide--section-2 .explore-more-areas_slide_image, .reip-container .explore-more-areas_slide--section-2 .explore-more-areas_slide_headline, .reip-container .explore-more-areas_slide--section-2 .explore-more-areas_slide_cta {
  border-color: #4c71ff;
}

.reip-container .explore-more-areas_slide--section-3 .explore-more-areas_slide_image, .reip-container .explore-more-areas_slide--section-3 .explore-more-areas_slide_headline, .reip-container .explore-more-areas_slide--section-3 .explore-more-areas_slide_cta {
  border-color: #00bf99;
}

.reip-container .explore-more-areas_slide--section-4 .explore-more-areas_slide_image, .reip-container .explore-more-areas_slide--section-4 .explore-more-areas_slide_headline, .reip-container .explore-more-areas_slide--section-4 .explore-more-areas_slide_cta {
  border-color: #fff;
}

.reip-container .explore-more-areas_slide--section-5 .explore-more-areas_slide_image, .reip-container .explore-more-areas_slide--section-5 .explore-more-areas_slide_headline, .reip-container .explore-more-areas_slide--section-5 .explore-more-areas_slide_cta {
  border-color: #ffba0d;
}

.reip-container .explore-more-areas_slide a {
  display: block;
}

.reip-container .explore-more-areas_slide_image {
  height: 161px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-style: solid;
  border-width: 3px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .reip-container .explore-more-areas_slide_image {
    height: 287px;
  }
}

.reip-container .explore-more-areas_slide_headline {
  color: #fff;
  letter-spacing: -.02rem;
  -o-transition: all .2s linear;
  border-style: none solid solid;
  border-width: medium 3px 3px;
  border-top-color: currentColor;
  margin-bottom: 4px;
  padding: 10px;
  font-family: Theinhardt-Medium;
  font-size: 20px;
  line-height: 22px;
  transition: all .2s linear;
}

@media print, screen and (min-width: 64em) {
  .reip-container .explore-more-areas_slide_headline {
    margin-bottom: 6px;
    font-size: 38px;
    line-height: 42px;
  }
}

.reip-container .explore-more-areas_slide_cta {
  color: #fff;
  letter-spacing: -.01rem;
  -o-transition: all .2s linear;
  border-style: none solid solid;
  border-width: medium 3px 3px;
  border-top-color: currentColor;
  padding: 10px;
  font-family: Theinhardt-Regular;
  font-size: 15px;
  line-height: 15px;
  transition: all .2s linear;
}

@media print, screen and (min-width: 64em) {
  .reip-container .explore-more-areas_slide_cta {
    font-size: 20px;
    line-height: 20px;
  }
}

.reip-container.section-1 .reip-detail-hero {
  background-color: #ffbca3;
}

.reip-container.section-1 .reip-detail-body .slick-dots li button .slick-dot-icon {
  color: #ffbca3;
}

.reip-container.section-1 .reip-detail-body .slick-dots li.slick-active button .slick-dot-icon {
  color: #ff8557;
}

.reip-container.section-1 .reip-detail-body p span {
  background-color: #ffbca3;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_hero {
  border-color: #ff8557;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario {
  background: #ffe7dd;
  border-color: #ff8557;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario i, .reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover, .reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play {
  background: #ff8557;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover i, .reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play i {
  background: #fff;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover i:before, .reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play i:before {
  border-left-color: #ff8557;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .cta-guide {
  border-color: #ff8557;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .cta-guide:hover {
  background-color: #ff8557;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .timeline {
  background: #ffe7dd;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .timeline .progress {
  background: #ff8557;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play .pp-play {
  fill: #ff8557;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play .pp-pause {
  stroke: #ff8557;
}

.reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.play .pp-play, .reip-container.section-1 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.pause .pp-pause {
  opacity: .5;
}

.reip-container.section-1 .you-can-choose-both_form-container_fields {
  border-color: #ff8557;
}

.reip-container.section-1 .you-can-choose-both_form-container_fields input[type="submit"]:hover {
  background-color: #ff8557;
}

.reip-container.section-1 .you-can-choose-both_form-container_fields .border.top, .reip-container.section-1 .you-can-choose-both_form-container_fields .border.right, .reip-container.section-1 .you-can-choose-both_form-container_fields .recaptcha, .reip-container.section-1 .you-can-choose-both_form-container_graphic .reip-share-graphic {
  border-color: #ff8557;
}

.reip-container.section-2 .reip-detail-slider .reip-detail-hero {
  background-color: #a5b8ff;
}

.reip-container.section-2 .reip-detail-slider .reip-detail-hero .reip-hero-content {
  color: #fff;
}

.reip-container.section-2 .reip-detail-body .slick-dots li button .slick-dot-icon {
  color: #a5b8ff;
}

.reip-container.section-2 .reip-detail-body .slick-dots li.slick-active button .slick-dot-icon {
  color: #4c71ff;
}

.reip-container.section-2 .reip-detail-body p span {
  background-color: #a5b8ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_hero {
  border-color: #4c71ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario {
  background: #e9ebff;
  border-color: #4c71ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario i {
  background: #4c71ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover, .reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play {
  color: #fff;
  background: #4c71ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover i, .reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play i {
  background: #fff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover i:before, .reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play i:before {
  border-left-color: #4c71ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .cta-guide {
  border-color: #4c71ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .cta-guide:hover {
  color: #fff;
  background-color: #4c71ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .timeline {
  background: #e9ebff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .timeline .progress {
  background: #4c71ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play .pp-play {
  fill: #4c71ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play .pp-pause {
  stroke: #4c71ff;
}

.reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.play .pp-play, .reip-container.section-2 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.pause .pp-pause {
  opacity: .5;
}

.reip-container.section-2 .you-can-choose-both_form-container_fields {
  border-color: #4c71ff;
}

.reip-container.section-2 .you-can-choose-both_form-container_fields input[type="submit"]:hover {
  background-color: #4c71ff;
}

.reip-container.section-2 .you-can-choose-both_form-container_fields .border.top, .reip-container.section-2 .you-can-choose-both_form-container_fields .border.right, .reip-container.section-2 .you-can-choose-both_form-container_fields .recaptcha, .reip-container.section-2 .you-can-choose-both_form-container_graphic .reip-share-graphic {
  border-color: #4c71ff;
}

.reip-container.section-3 .reip-detail-hero {
  background-color: #7fdfcc;
}

.reip-container.section-3 .reip-detail-body .slick-dots li button .slick-dot-icon {
  color: #7fdfcc;
}

.reip-container.section-3 .reip-detail-body .slick-dots li.slick-active button .slick-dot-icon {
  color: #00bf99;
}

.reip-container.section-3 .reip-detail-body p span {
  background-color: #7fdfcc;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_hero {
  border-color: #00bf99;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario {
  background: #dcf4ee;
  border-color: #00bf99;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario i, .reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover, .reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play {
  background: #00bf99;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover i, .reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play i {
  background: #fff;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover i:before, .reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play i:before {
  border-left-color: #00bf99;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .cta-guide {
  border-color: #00bf99;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .cta-guide:hover {
  background-color: #00bf99;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .timeline {
  background: #dcf4ee;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .timeline .progress {
  background: #00bf99;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play .pp-play {
  fill: #00bf99;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play .pp-pause {
  stroke: #00bf99;
}

.reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.play .pp-play, .reip-container.section-3 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.pause .pp-pause {
  opacity: .5;
}

.reip-container.section-3 .you-can-choose-both_form-container_fields {
  border-color: #00bf99;
}

.reip-container.section-3 .you-can-choose-both_form-container_fields input[type="submit"]:hover {
  background-color: #00bf99;
}

.reip-container.section-3 .you-can-choose-both_form-container_fields .border.top, .reip-container.section-3 .you-can-choose-both_form-container_fields .border.right, .reip-container.section-3 .you-can-choose-both_form-container_fields .recaptcha, .reip-container.section-3 .you-can-choose-both_form-container_graphic .reip-share-graphic {
  border-color: #00bf99;
}

.reip-container.section-4 .reip-detail-hero {
  background-color: #76777c;
}

.reip-container.section-4 .reip-detail-hero .reip-hero-content {
  color: #fff !important;
}

.reip-container.section-4 .reip-detail-body .slick-dots li button .slick-dot-icon {
  color: #a3a4a7;
}

.reip-container.section-4 .reip-detail-body .slick-dots li.slick-active button .slick-dot-icon {
  color: #1a1c24;
}

.reip-container.section-4 .reip-detail-body p span {
  background-color: #a3a4a7;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_hero {
  border-color: #1a1c24;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario {
  background: #ddddde;
  border-color: #1a1c24;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario i {
  background: #1a1c24;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover, .reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play {
  color: #fff;
  background: #1a1c24;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover i, .reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play i {
  background: #fff;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover i:before, .reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play i:before {
  border-left-color: #1a1c24;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .cta-guide {
  border-color: #1a1c24;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .cta-guide:hover {
  background-color: #1a1c24;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .timeline {
  background: #ddddde;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .timeline .progress {
  background: #1a1c24;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play .pp-play {
  fill: #1a1c24;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play .pp-pause {
  stroke: #1a1c24;
}

.reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.play .pp-play, .reip-container.section-4 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.pause .pp-pause {
  opacity: .5;
}

.reip-container.section-4 .you-can-choose-both_form-container_fields {
  border-color: #fff;
}

.reip-container.section-4 .you-can-choose-both_form-container_fields input[type="submit"]:hover {
  color: #1a1c24;
  background-color: #fff;
}

.reip-container.section-4 .you-can-choose-both_form-container_fields .border.top, .reip-container.section-4 .you-can-choose-both_form-container_fields .border.right, .reip-container.section-4 .you-can-choose-both_form-container_fields .recaptcha, .reip-container.section-4 .you-can-choose-both_form-container_graphic .reip-share-graphic {
  border-color: #fff;
}

.reip-container.section-5 .reip-detail-hero {
  background-color: #ffd97a;
}

.reip-container.section-5 .reip-detail-hero svg {
  transform-origin: 50% 46.7%;
  transform: scale(2.46);
}

@media print, screen and (min-width: 64em) {
  .reip-container.section-5 .reip-detail-hero svg {
    transform-origin: 50% 84.5%;
    transform: scale(2.46);
  }
}

.reip-container.section-5 .reip-detail-body .slick-dots li button .slick-dot-icon {
  color: #ffd97a;
}

.reip-container.section-5 .reip-detail-body .slick-dots li.slick-active button .slick-dot-icon {
  color: #ffba0d;
}

.reip-container.section-5 .reip-detail-body p span {
  background-color: #ffd97a;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio .wrap-up-audio_hero {
  border-color: #ffba0d;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario {
  background: #fff1cf;
  border-color: #ffba0d;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario i, .reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover, .reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play {
  background: #ffba0d;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover i, .reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play i {
  background: #fff;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario:hover i:before, .reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .cta-hear-a-scenario.play i:before {
  border-left-color: #ffba0d;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .cta-guide {
  border-color: #ffba0d;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .cta-guide:hover {
  background-color: #ffba0d;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .timeline {
  background: #fff1cf;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .timeline .progress {
  background: #ffba0d;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play .pp-play {
  fill: #ffba0d;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play .pp-pause {
  stroke: #ffba0d;
}

.reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.play .pp-play, .reip-container.section-5 .reip-detail-wrap-up .wrap-up-audio_content .wrap-player .audio-player .controls .toggle-play.pause .pp-pause {
  opacity: .5;
}

.reip-container.section-5 .you-can-choose-both_form-container_fields {
  border-color: #ffba0d;
}

.reip-container.section-5 .you-can-choose-both_form-container_fields input[type="submit"]:hover {
  background-color: #ffba0d;
}

.reip-container.section-5 .you-can-choose-both_form-container_fields .border.top, .reip-container.section-5 .you-can-choose-both_form-container_fields .border.right, .reip-container.section-5 .you-can-choose-both_form-container_fields .recaptcha, .reip-container.section-5 .you-can-choose-both_form-container_graphic .reip-share-graphic {
  border-color: #ffba0d;
}

body.scroll-locked {
  max-height: 100vh;
  overflow: hidden;
}

.reip-container {
  letter-spacing: -.04rem;
}

@media print, screen and (min-width: 64em) {
  .reip-container {
    letter-spacing: -.07rem;
  }
}

.reip-container .next-link {
  color: #1d1e21;
  letter-spacing: 0;
  margin-top: 12px;
  font-family: Theinhardt-Regular;
  font-size: 15px;
  line-height: 15px;
  display: block;
}

@media screen and (min-width: 75em) {
  .reip-container .next-link {
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid #000;
    margin-top: 15px;
    font-size: 20px;
    line-height: 21px;
  }

  .reip-container .next-link:hover {
    cursor: pointer;
    color: #4c71ff;
    border-bottom-color: #4c71ff;
  }

  .reip-container .next-link.scroll {
    margin-top: 20px;
  }
}

.reip-container .reip-footer {
  z-index: 13;
  letter-spacing: -.02rem;
  background: #1a1c24;
  padding: 75px 18px;
  position: relative;
}

@media screen and (min-width: 75em) {
  .reip-container .reip-footer {
    display: block;
  }

  .reip-container .reip-footer .row {
    max-width: 939px;
  }
}

.reip-container .reip-footer nav ul li {
  display: block;
}

.reip-container .reip-footer nav ul li .reip-footer-lnk {
  color: #fff;
  border-top: 2px solid #fff;
  padding: 15px 20px 15px 10px;
  font-family: Theinhardt-Regular;
  font-size: 20px;
  line-height: 23px;
  display: block;
}

@media screen and (min-width: 75em) {
  .reip-container .reip-footer nav ul li .reip-footer-lnk {
    padding: 20px 20px 20px 10px;
    font-size: 26px;
    line-height: 30px;
  }
}

.reip-container .reip-footer nav ul li .reip-footer-lnk.expanded i {
  transform: rotate(90deg);
}

.reip-container .reip-footer nav ul li .reip-footer-lnk i {
  vertical-align: middle;
  width: 10px;
  height: 14px;
  -o-transition: all .2s linear;
  margin-right: 5px;
  transition: all .2s linear;
  display: inline-block;
}

@media screen and (min-width: 75em) {
  .reip-container .reip-footer nav ul li .reip-footer-lnk i {
    width: 20px;
    height: 20px;
  }
}

.reip-container .reip-footer nav ul li .reip-footer-lnk i img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.reip-container .reip-footer nav ul li .reip-footer_content {
  max-height: 0;
  padding: 0;
  overflow: hidden;
}

.reip-container .reip-footer nav ul li .reip-footer_content.expanded {
  max-height: none;
  padding: 10px 0 40px;
}

@media screen and (min-width: 75em) {
  .reip-container .reip-footer nav ul li .reip-footer_content.margin-top-adjust {
    margin-top: 0;
  }

  .reip-container .reip-footer nav ul li .reip-footer_content.expanded:not(.margin-top-adjust) {
    margin-top: -50px;
    padding: 0 0 20px;
  }
}

.reip-container .reip-footer nav ul li .reip-footer_content p {
  color: #fff;
  font-family: Theinhardt-Regular;
  font-size: 20px;
  line-height: 23px;
}

@media screen and (min-width: 75em) {
  .reip-container .reip-footer nav ul li .reip-footer_content p {
    font-size: 26px;
    line-height: 31px;
  }
}

.reip-container .reip-footer nav ul li .reip-footer_content p.credits {
  line-height: 32px;
}

.reip-container .reip-footer nav ul li .reip-footer_content p a {
  color: #fff;
  text-decoration: underline;
}

.reip-container .reip-footer nav ul li .reip-footer_content p span {
  color: #4c71ff;
}

.reip-container .reip-footer nav ul li .reip-footer_content .reip-footer-contributor {
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.reip-container .reip-footer nav ul li .reip-footer_content .reip-footer-contributor_avatar {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 13px;
  overflow: hidden;
}

.reip-container .reip-footer nav ul li .reip-footer_content .reip-footer-contributor_title {
  color: #fff;
  font-family: Theinhardt-Regular;
  font-size: 20px;
  line-height: 21px;
}

@media screen and (min-width: 75em) {
  .reip-container .reip-footer nav ul li .reip-footer_content .reip-footer-contributor_title {
    font-size: 26px;
    line-height: 29px;
  }
}

.cc-window {
  opacity: 1;
  transition: opacity 1s;
}

.cc-window.cc-invisible {
  opacity: 0;
}

.cc-animate.cc-revoke {
  transition: transform 1s;
}

.cc-animate.cc-revoke.cc-top {
  transform: translateY(-2em);
}

.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(2em);
}

.cc-animate.cc-revoke.cc-active.cc-bottom, .cc-animate.cc-revoke.cc-active.cc-top, .cc-revoke:hover {
  transform: translateY(0);
}

.cc-grower {
  max-height: 0;
  transition: max-height 1s;
  overflow: hidden;
}

.cc-link, .cc-revoke:hover {
  text-decoration: underline;
}

.cc-revoke, .cc-window {
  box-sizing: border-box;
  z-index: 9999;
  flex-wrap: nowrap;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.cc-window.cc-static {
  position: static;
}

.cc-window.cc-floating {
  max-width: 24em;
  flex-direction: column;
  padding: 2em;
}

.cc-window.cc-banner {
  width: 100%;
  flex-direction: row;
  padding: 1em 1.8em;
}

.cc-revoke {
  padding: .5em;
}

.cc-header {
  font-size: 18px;
  font-weight: 700;
}

.cc-btn, .cc-close, .cc-link, .cc-revoke {
  cursor: pointer;
}

.cc-link {
  opacity: .8;
  padding: .2em;
  display: inline-block;
}

.cc-link:hover {
  opacity: 1;
}

.cc-link:active, .cc-link:visited {
  color: initial;
}

.cc-btn {
  text-align: center;
  white-space: nowrap;
  border-style: solid;
  border-width: 2px;
  padding: .4em .8em;
  font-size: .9em;
  font-weight: 700;
  display: block;
}

.cc-highlight .cc-btn:first-child {
  background-color: #0000;
  border-color: #0000;
}

.cc-highlight .cc-btn:first-child:focus, .cc-highlight .cc-btn:first-child:hover {
  background-color: #0000;
  text-decoration: underline;
}

.cc-close {
  opacity: .9;
  font-size: 1.6em;
  line-height: .75;
  display: block;
  position: absolute;
  top: .5em;
  right: .5em;
}

.cc-close:focus, .cc-close:hover {
  opacity: 1;
}

.cc-revoke.cc-top {
  border-bottom-right-radius: .5em;
  border-bottom-left-radius: .5em;
  top: 0;
  left: 3em;
}

.cc-revoke.cc-bottom {
  border-top-left-radius: .5em;
  border-top-right-radius: .5em;
  bottom: 0;
  left: 3em;
}

.cc-revoke.cc-left {
  left: 3em;
  right: unset;
}

.cc-revoke.cc-right {
  right: 3em;
  left: unset;
}

.cc-top {
  top: 1em;
}

.cc-left {
  left: 1em;
}

.cc-right {
  right: 1em;
}

.cc-bottom {
  bottom: 1em;
}

.cc-floating > .cc-link {
  margin-bottom: 1em;
}

.cc-floating .cc-message {
  margin-bottom: 1em;
  display: block;
}

.cc-window.cc-floating .cc-compliance {
  flex: 1 0 auto;
}

.cc-window.cc-banner {
  align-items: center;
}

.cc-banner.cc-top {
  top: 0;
  left: 0;
  right: 0;
}

.cc-banner.cc-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.cc-banner .cc-message {
  max-width: 100%;
  flex: auto;
  margin-right: 1em;
  display: block;
}

.cc-compliance {
  align-content: space-between;
  align-items: center;
  display: flex;
}

.cc-floating .cc-compliance > .cc-btn {
  flex: 1;
}

.cc-btn + .cc-btn {
  margin-left: .5em;
}

@media print {
  .cc-revoke, .cc-window {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal;
  }
}

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0;
  }

  .cc-window.cc-bottom {
    bottom: 0;
  }

  .cc-window.cc-banner, .cc-window.cc-floating, .cc-window.cc-left, .cc-window.cc-right {
    left: 0;
    right: 0;
  }

  .cc-window.cc-banner {
    flex-direction: column;
  }

  .cc-window.cc-banner .cc-compliance {
    flex: auto;
  }

  .cc-window.cc-floating {
    max-width: none;
  }

  .cc-window .cc-message {
    margin-bottom: 1em;
  }

  .cc-window.cc-banner {
    -ms-flex-align: unset;
    align-items: unset;
  }

  .cc-window.cc-banner .cc-message {
    margin-right: 0;
  }
}

.cc-floating.cc-theme-classic {
  border-radius: 5px;
  padding: 1.2em;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  flex: none;
  display: inline;
}

.cc-theme-classic .cc-btn {
  border-radius: 5px;
}

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block;
}

.cc-theme-edgeless.cc-window {
  padding: 0;
}

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em 2em 1.5em;
}

.cc-banner.cc-theme-edgeless .cc-btn {
  height: 100%;
  margin: 0;
  padding: .8em 1.8em;
}

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em;
}

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0;
}

.cc-window {
  background: #fff !important;
}

@media print, screen and (min-width: 64em) {
  .cc-window {
    box-shadow: 0 2px 40px #00000080;
    width: 60% !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
}

.cc-window p {
  letter-spacing: .23px;
  color: #8f8f8f;
  margin: 0;
  font-family: Theinhardt-Regular;
  font-size: 13px;
  line-height: 18px;
}

@media print, screen and (min-width: 64em) {
  .cc-window p {
    font-size: 15px;
  }
}

.cc-window p a {
  color: #000;
  font-family: Theinhardt-Regular;
  text-decoration: underline;
}

.cc-window p span {
  letter-spacing: .3px;
  color: #000;
  margin-bottom: 10px;
  font-family: PlantinMTPro-LightItalic;
  font-size: 17px;
  line-height: 17px;
  display: block;
}

@media print, screen and (min-width: 64em) {
  .cc-window p span {
    font-size: 20px;
    line-height: 20px;
  }
}

.cc-window p span strong {
  font-family: Theinhardt-Medium;
  font-weight: 500;
}

.cc-window .cc-link {
  display: none;
}

.cc-btn {
  font-family: Theinhardt-Medium;
  letter-spacing: .35px !important;
  background-color: #4c71ff !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

@media print, screen and (min-width: 64em) {
  .cc-btn {
    padding: 20px 30px !important;
    font-size: 20px !important;
  }
}

header.choose-both-header {
  padding-top: 15px;
  padding-bottom: 11px;
  transition: all .5s;
  position: fixed;
}

@media print, screen and (min-width: 48em) {
  header.choose-both-header {
    padding-top: 30px;
    padding-bottom: 24px;
  }
}

body.page-template-templatestemplate-reip-detail-php header.choose-both-header {
  background: none;
}

header.choose-both-header .tools {
  top: 14px;
}

@media (min-width: 1200px) {
  header.choose-both-header .tools {
    top: 35px;
    right: 50px;
  }

  body.choose-both-nav-expanded header.choose-both-header .tools {
    right: 50px;
  }
}

header.choose-both-header .btn-lang {
  display: none;
}

@media print, screen and (min-width: 64em) {
  header.choose-both-header .btn-lang {
    display: inline-block;
  }
}

header.choose-both-header.op0 {
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 2px 7px #0000004d;
}

header.choose-both-header.logo-symbol-only svg .logo-letter {
  display: none;
}

@media print, screen and (min-width: 48em) and (max-width: 63.9988em) {
  header.choose-both-header .logo {
    margin-top: -12px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1360px) {
  header.choose-both-header .logo svg .logo-letter {
    display: none;
  }
}

header.choose-both-header .logo-choose-both {
  width: 126px;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
}

@media print, screen and (min-width: 64em) {
  header.choose-both-header .logo-choose-both {
    width: 177px;
    top: 25px;
  }
}

header.choose-both-header .logo-choose-both svg {
  max-width: 100%;
  display: block;
}

body.choose-both-nav-expanded header.choose-both-header .logo-choose-both svg path {
  fill: #fff;
}

body.choose-both-nav-expanded header.choose-both-header .logo-choose-both svg rect {
  stroke: #fff;
  fill: #1a1c24;
}

@media print, screen and (min-width: 64em) {
  header.choose-both-header .mobile-nav-headline {
    display: none;
  }
}

body.choose-both-nav-expanded header.choose-both-header {
  box-shadow: none;
  background-color: #1d1e21;
}

body.choose-both-nav-expanded header.choose-both-header .logo svg .logo-main-group {
  fill: #fff;
}

body.choose-both-nav-expanded header.choose-both-header .hamburger span {
  background: #fff;
}

body.choose-both-nav-expanded header.choose-both-header .btn-arrow {
  height: 25px;
  z-index: 20;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSI3cHgiIHZpZXdCb3g9IjAgMCAxMSA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xODEuMDAwMDAwLCAtNDkuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIj48cG9seWxpbmUgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTg3LjAwMDAwMCwgNTIuNTAwMDAwKSBzY2FsZSgtMSwgLTEpIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0xODcuMDAwMDAwLCAtNTIuNTAwMDAwKSAiIHBvaW50cz0iMTkyIDU1IDE4Ni45OTk1NjYgNTAgMTgyIDU1Ij48L3BvbHlsaW5lPjwvZz48L2c+PC9zdmc+") center no-repeat;
  margin-top: -10px;
  position: relative;
}

body.choose-both-nav-expanded header.choose-both-header #nav-headline {
  transform: translateY(70px);
}

body.choose-both-nav-expanded header.choose-both-header #nav-headline a {
  pointer-events: initial;
  color: #fff;
}

header nav.choose-both-nav {
  width: max-content;
  text-align: center;
  margin: 0 auto;
  transition: opacity .3s;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

header nav.choose-both-nav .btn-arrow {
  height: 10px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSI3cHgiIHZpZXdCb3g9IjAgMCAxMSA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4Mi4wMDAwMDAsIC00OS4wMDAwMDApIiBzdHJva2U9IiMxRDFFMjEiPgogICAgICAgICAgICA8cG9seWxpbmUgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTg3LjAwMDAwMCwgNTIuNTAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTg3LjAwMDAwMCwgLTUyLjUwMDAwMCkgIiBwb2ludHM9IjE5MiA1NSAxODYuOTk5NTY2IDUwIDE4MiA1NSI+PC9wb2x5bGluZT4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==") bottom no-repeat;
  margin-top: -5px;
  display: block;
}

header nav.choose-both-nav #nav-headline a {
  pointer-events: none;
}

header nav.choose-both-nav span {
  vertical-align: top;
  color: #1d1e21;
  font-family: Theinhardt-Medium;
  font-size: 16px;
  line-height: 16px;
  transition: all .2s linear;
  display: inline-block;
}

header nav.choose-both-nav span a {
  color: #1d1e21;
}

@media print, screen and (min-width: 48em) {
  header nav.choose-both-nav span {
    background: none;
    font-size: 18px;
    line-height: 18px;
  }
}

@media print, screen and (min-width: 64em) {
  header nav.choose-both-nav span {
    margin-right: 25px;
    font-size: 21px;
    line-height: 21px;
  }
}

header nav.choose-both-nav ul {
  display: none;
}

@media print, screen and (min-width: 64em) {
  header nav.choose-both-nav ul {
    vertical-align: middle;
    display: inline-block;
  }
}

header nav.choose-both-nav ul li {
  color: #1d1e21;
  margin: 0 20px;
  font-family: Theinhardt-Medium;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
}

@media print, screen and (min-width: 48em) {
  header nav.choose-both-nav ul li {
    font-size: 21px;
    line-height: 21px;
  }
}

header nav.choose-both-nav ul li:not(:first-child) {
  display: none;
}

@media print, screen and (min-width: 48em) {
  header nav.choose-both-nav ul li:not(:first-child) {
    display: inline-block;
  }
}

header nav.choose-both-nav ul li.current_page_item a {
  color: #4c71ff;
}

header nav.choose-both-nav ul li a {
  color: #1d1e21;
  padding-top: 10px;
  font-size: 15px;
  line-height: 26px;
  position: relative;
}

header nav.choose-both-nav ul li a:hover {
  color: #4c71ff;
}

header nav.choose-both-nav ul li a:before {
  content: "";
  height: 2px;
  width: 100%;
  background: #1d1e21;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.off-canvas-choose-both-nav {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  text-align: left;
  background: #1d1e21;
  padding: 140px 20px 50px;
  transition: top .3s;
  position: fixed;
  top: -100vh;
  left: 0;
}

@media print, screen and (min-width: 64em) {
  .off-canvas-choose-both-nav {
    padding-top: 260px;
  }
}

body.choose-both-nav-expanded .off-canvas-choose-both-nav {
  z-index: 20;
  top: 0;
}

@media print, screen and (min-width: 64em) {
  .off-canvas-choose-both-nav nav ul {
    max-width: 1000px;
    -webkit-column-count: 2;
    -webkit-column-gap: 30px;
    column-count: 2;
    column-gap: 30px;
    margin: 0 auto;
  }
}

.off-canvas-choose-both-nav nav ul li {
  margin-bottom: 20px;
  display: block;
}

.off-canvas-choose-both-nav nav ul li:last-child {
  margin-bottom: 0;
}

.off-canvas-choose-both-nav nav ul li a {
  color: #fff;
  padding-top: 5px;
  font-family: Theinhardt-Regular;
  font-size: 26px;
  line-height: 27px;
}

@media print, screen and (min-width: 64em) {
  .off-canvas-choose-both-nav nav ul li a {
    font-size: 40px;
    line-height: 42px;
  }
}

.off-canvas-choose-both-nav nav ul li a:hover {
  color: #babbbd;
}

/*# sourceMappingURL=index.da11f6aa.css.map */
