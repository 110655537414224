
// Barba

div.barba-container{
  transition: opacity 0.5s ease;

  &.op0{
    opacity: 0;
  }
  &.transition-active{
    position:absolute;
    top:0;
    width:100%;
  }
}
