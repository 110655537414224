.gdpr {
  padding-bottom: 60px!important;

  small {
    @include font-family(TheinhardtRegular);
    color: #B6B6B8;
    display: block;
    font-size: 14px;
    line-height: 22px;
    padding-top: 15px;
    text-align: left;

    a {
      color: #2354fb;
      text-decoration: underline;
    }
  }

  .error {
    display: none;
  }

  .gdpr_message_no {
    display: none;

    p {
      @include font-family(TheinhardtRegular);
      color: color(black);
      display: block;
      font-size: 14px;
      line-height: 22px;
      padding-top: 15px;
      text-align: left;

      strong {
        @include font-family(TheinhardtMedium);
        font-weight: normal;
      }

      a {
        color: color(blue);
      }
    }
  }

  .gdpr_label {
    @include font-family(TheinhardtMedium);
    color: #222222;
    display: block;
    font-size: 16px;
    line-height: 22px;
    padding-top: 15px;
    text-align: left;

    input[type="radio"] {
      margin-bottom: 0px;
      margin-right: .5rem;
    }

    .form-error {
      position: absolute;
      padding-top: 10px;
      left: 1.5625rem;
      right: 1.5625rem;
      text-align: left;

      @include breakpoint(medium){
        left: 1.875rem;
        right: 1.875rem;
      }
    }
  }
}
