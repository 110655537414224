@mixin highlight-modal-colors($color) {
  .modal-content .voce-sabia p em {
    color: $color;
  }
}

.reveal {
  box-shadow: none;
  border: none;

  .modal-content {
    position: relative;

    .voce-sabia {
      position: absolute;
      top: 20px;
      max-width: 50%;

      &.left {
        left: 20px;
      }

      &.right {
        right: 20px;
      }

      p {
        @include font-family(WorkSans);
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: color(black);
        margin-bottom: 0;

        em {
          font-style: normal;
          font-weight: bold;
        }
      }
    }
  }

  &.highlight-blue {
    @include highlight-modal-colors(color(brazil_blue));
  }
  &.highlight-cyan {
    @include highlight-modal-colors(color(brazil_cyan));
  }
  &.highlight-orange {
    @include highlight-modal-colors(color(brazil_orange));
  }
  &.highlight-peach {
    @include highlight-modal-colors(color(brazil_peach));
  }
  &.highlight-yellow {
    @include highlight-modal-colors(color(brazil_yellow));
  }

}
