html {
  &.navigation-expanded {
    @include breakpoint(small only) {
      overflow-y: auto;
    }
  }
}

body {
  background-color: white;

  &.black {
    background-color: color(black);
  }

  &.navigation-expanded,
  &.team-modal-visible {
    position: relative;
    overflow: hidden;

    @include breakpoint(small only) {
      height: auto!important;
    }
  }

  &.navigation-expanded {
    .barba-container > :not(:nth-child(-n+2)){
      display:none;
    }
  }
}

.relative {
  position: relative;
}


a {
  @include disable-mouse-outline;
}


section, div{
  &.shadow {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 20px;
      width: 100%;
      display: block;
      background: linear-gradient(to bottom, rgba(0,0,0,0.05) 0%, rgba(255,255,255,1) 100%);
    }
  }
}

.form-salesforce {
  margin-bottom: 40px;
  margin-top: 40px;
}
