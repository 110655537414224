@import "reip_landing";
@import "reip_detail_global";
@import "reip_detail";

body.scroll-locked {
  max-height: 100vh;
  overflow: hidden;
}

.reip-container {
  letter-spacing: -.04rem;
  @include breakpoint(large){
    letter-spacing: -.07rem;
  }

  .next-link {
    display: block;
    @include font-family(TheinhardtRegular);
    font-size: 15px;
    line-height: 15px;
    color: color(black);
    margin-top: 12px;
    letter-spacing: 0;

    @include breakpoint(xlarge) {
      font-size: 20px;
      line-height: 21px;
      margin-top: 15px;
      width: fit-content;
      border-bottom: 2px solid black;
      &:hover {
        cursor: pointer;
        color: color(blue-accesible);
        border-bottom-color: color(blue-accesible);
      }
      &.scroll {
        margin-top: 20px;
      }
    }
  }

  .reip-footer {
    position: relative;
    background: color(reip_black);
    padding: 75px 18px;
    z-index: 13;
    letter-spacing: -.02rem;

    @include breakpoint(xlarge) {
      display: block;
      .row {
          max-width: 939px;
      }
    }

    nav {
      ul {
        li {
          display: block;

          .reip-footer-lnk {
            display: block;
            padding: 15px 20px 15px 10px;
            border-top: 2px solid white;
            @include font-family(TheinhardtRegular);
            font-size: 20px;
            line-height: 23px;
            color: white;

            @include breakpoint(xlarge) {
              font-size: 26px;
              line-height: 30px;
              padding: 20px 20px 20px 10px;
            }

            &.expanded {
              i {
                transform: rotate(90deg);
              }
            }

            i {
              display: inline-block;
              vertical-align: middle;
              width: 10px;
              height: 14px;
              margin-right: 5px;
              @include animation();

              @include breakpoint(xlarge) {
                width: 20px;
                height: 20px;
              }

              img {
                display: block;
                width: auto;
                max-width: 100%;
                max-height: 100%;
              }
            }
          }

          .reip-footer_content {
            max-height: 0;
            padding: 0;
            overflow: hidden;

            &.expanded {
              max-height: none;
              padding: 10px 0 40px 0;
            }


            @include breakpoint(xlarge) {
              &.margin-top-adjust {
                 margin-top: 0;
              }
              &.expanded:not(.margin-top-adjust) {
                margin-top: -50px;
                padding: 0 0 20px 0;
              }
            }
            // &--two-columns {
            //   @include breakpoint(large) {
            //     p {
            //       column-count: 2;
            //       column-gap: 40px;
            //     }
            //   }
            // }

            p {
              @include font-family(TheinhardtRegular);
              font-size: 20px;
              line-height: 23px;
              color: white;

              @include breakpoint(xlarge) {
                font-size: 26px;
                line-height: 31px;
              }

              &.credits {
                line-height: 32px;
              }

              a {
                color: white;
                text-decoration: underline;
              }

              span {
                color: color(blue-accesible);
              }
            }

            .reip-footer-contributor {
              display: flex;
              align-items: center;
              margin-bottom: 20px;

              &_avatar {
                flex-shrink: 0;
                width: 52px;
                height: 52px;
                border-radius: 50%;
                margin-right: 13px;
                overflow: hidden;
              }

              &_title {
                @include font-family(TheinhardtRegular);
                font-size: 20px;
                line-height: 21px;
                color: white;

                @include breakpoint(xlarge) {
                  font-size: 26px;
                  line-height: 29px;
                }
              }
            }
          }
        }
      }
    }
  }
}
