// Header

// @Mixin used for the :hover, and active state for Hamburger button
@mixin hamburger-active-state(){
  span {

    background: color(black);

    &:nth-child(1) {
      top: 5px;
      transform: rotate(135deg);

      @include breakpoint(medium){
        top: 9px;
      }
    }
    &:nth-child(3) {
      top: 5px;
      transform: rotate(-135deg);

      @include breakpoint(medium){
        top: 9px;
      }
    }
  }
}


header {
  padding-top: 24px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: opacity 0.5s ease;
  &.op0{
    opacity: 0;
  }

  @include breakpoint(medium) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @include breakpoint(large){
    &.op0{
      opacity: 1;
    }
  }

  @media (min-width: 1300px) {
    padding-top: 50px;
  }

  body.team-modal-visible & {
    @include breakpoint(medium){
      box-shadow: 0 0 36px 0 rgba(0,0,0,0.15);
      z-index: 100;
      background-color: white;
      height: 115px;
      position: fixed;
    }
    .hamburger,
    .btn-lang {
      display: none;
    }
  }
  body.navigation-expanded & {
    position: fixed;
    display: block!important;

    .hamburger {
      @include hamburger-active-state();
    }
    .logo svg .logo-letter {
      opacity: 0;
    }
    svg {
      .logo-main-group {
        fill: color(black);
      }
    }
    .tools {
      @media (min-width: 1300px) {
        right: 65px;
      }
    }
    .btn-lang {
      .ico-world {
        svg {
          #shape {
            stroke: color(black);
          }
        }
      }
      span {
        color: color(black);
      }
      .ico-triangle {
        svg {
          #shape {
            fill: color(black);
          }
        }
      }
    }
  }

  &.black {
    // Makes logo black
    .logo {
      svg {
        .logo-main-group {
          fill: color(black);
        }
      }
    }
    // Makes Hamburger lines Black
    .hamburger {
      span {
        background: color(black);
      }
    }
    .btn-lang {
      .ico-world {
        svg {
          #shape {
            stroke: color(black);
          }
        }
      }
      span {
        color: color(black);
      }
      .ico-triangle {
        svg {
          #shape {
            fill: color(black);
          }
        }
      }
    }
  }

  .column {
    height: 28px;

    @include breakpoint(large){
      height: 47px;
    }
  }

  .logo {
    float: left;
    height: 28px;
    width: 40px;
    margin: 0 0 0 24px;
    position: fixed;

    @include breakpoint(large){
      width: auto;
      height: auto;
      margin-left: 30px;
    }

    @media (min-width: 1300px) {
      margin-left: 50px;
    }

    span {
      display: none;
      background: url('../images/logo.svg');
    }

    svg {
      .logo-letter {
        opacity: 0;

        @include breakpoint(medium){
          opacity: 1;
        }
      }
      .logo-main-group {
        fill: white;
        @include animation();

      }
    }
  }

  .tools {
    position: fixed;
    width: 120px;
    right: 24px;
    top: 32px;
    text-align: right;

    @media (min-width: 1200px) {
      right: 50px;
      top: 61px;
      width: 150px;
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .btn-lang {
    display: inline-block;
    vertical-align: middle;
    float: left;

    &.hover {
      .ico-triangle {
        transform: rotate(0deg);
      }
    }

    .ico-world {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;

      svg {
        display: block;
        max-width: 100%;
        height: auto;
      }

      &:before {
        display: none;
        background: url('../images/ico-language.svg');
      }
    }

    span {
      @include font-family(TheinhardtMedium);
      font-size: 18px;
      line-height: 1;
      text-transform: uppercase;
      color: white;
      letter-spacing: 2px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 2px;
    }

    .ico-triangle {
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 8px;
      transform: rotate(-180deg);

      svg {
        display: block;
        max-width: 100%;
        height: auto;
      }

      &:before {
        display: none;
        background: url('../images/ico-triangle.svg');
      }
    }
  }


  .hamburger {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    // float: right;
    width: 26px;
    height: 13px;

    // right: 0px;
    // position: fixed;
    cursor: pointer;
    outline: none;
    // margin-right: 24px;

    @include breakpoint(large){
      width: 44px;
      height: 22px;
      // right: 24px;
      // margin-right: 0;
      // position: fixed;
    }

    // @media (min-width: 1300px) {
    //   right: 50px;
    // }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: white;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .3s ease-in-out;

      @include breakpoint(medium){
        height: 5px;
      }

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        top: 10px;

        @include breakpoint(medium){
          top: 17px;
        }
      }
    }
  }

  .dropdown-pane {
    width: 150px;
    border: none;
    padding: 20px;
    text-align: left;

    &.is-open {
      left: 0!important;
      top: 40px!important;
    }

    span {
      display: block;
      @include font-family(TheinhardtMedium);
      font-size: 16px;
      color: color(black);
      margin-bottom: 2px;
    }

    .wpml-ls {
      display: block;
      padding-left: 0;

      ul li a.wpml-ls-link {
        padding: 0;
        margin: 0;

        span {
          @include animation();
          display: block;
          margin: 0;
          @include font-family(PlantinMTProLight);
          font-size: 18px;
          color: rgba(0,0,0,0.30);
          letter-spacing: 0;
          line-height: 38px;

          &.wpml-ls-display{
            display: none;
          }
        }

        &:hover {
          span {
            color: rgba(0,0,0,1);
          }
        }
      }
    }
  }
}
