$svg-height: 200vh;

.reip-container {

  .reip-slider-gsap-spacer {
    @include breakpoint(large){
      height: $svg_height;
    }
  }

  .reip-detail-slider {
    background-color: white;
    position: relative;
    // z-index: 10;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    @include breakpoint(large){
      &.pinned {
        position: fixed;
        z-index: 12;
        top: 0;
        width: 100vw;
      }
    }

    @include breakpoint(large) {
      height: auto;
      flex-direction: row;
    }




    .reip-detail-hero {
      position: relative;
      width: 100vw;
      height: 0;
      padding-bottom: 109.5%;
      overflow: hidden;

      @include breakpoint(large) {
        position: absolute;
        top: 0;
        right: 0;
        order: 2;
        width: 50vw;
        height: 100vh;
        padding-bottom: 0;

        svg {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .reip-hero-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include font-family(TheinhardtRegular);
        font-size: 20px;
        line-height: 23px;
        color: color(black);
        letter-spacing: -0.5px;
        opacity: 0;
        min-width: 300px;
        text-align: center;

        @include breakpoint(large) {
          font-size: 26px;
          letter-spacing: -0.75px;
          line-height: 30px;
          width: fit-content;
        }
      }
    }

    .reip-detail-body {
      // position: relative;
      flex: 1;
      padding: 24px 18px 160px 18px;

      @include breakpoint(large) {
        // position: absolute;
        // top: 0;
        // left: 0;
        order: 1;
        width: 50vw;
        height: 100vh;
        padding: 40px;
        display: flex;
        align-items: center;
        position: absolute;

        // height: 0;
        // border: 1px solid red;
        // padding: 0 0 50% 0;

        // .reip-slide {
        //   position: absolute;
        //   bottom: 40px;
        //   padding-right: 40px;
        // }
      }

      .slick-dots {
        position: fixed;
        bottom: 0;
        background: white;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 10px;


        @include breakpoint(large) {
          bottom: auto;
          background: transparent;
          top: 45vh;
          right: 10px;
          left: auto;
          transform: none;
          padding: 0;
          margin: 0;
          width: 20px;
        }

        &:before {
          content: "Tap/Swipe";
          display: block;
          text-align: center;
          @include font-family(TheinhardtRegular);
          font-size: 15px;
          line-height: 15px;
          color: color(black);

          @include breakpoint(large) {
            display: none;
          }
        }

        &.text-tap-scroll {
          &:before {
            content: "Tap/Scroll";
          }
        }

        li {
          position: relative;
          display: inline-block;
          margin: 0 2px;

          @include breakpoint(large) {
            display: block;
            vertical-align: inherit;
            margin: 5px 0;
          }

          &:hover, &:focus {
            background-color: none !important;
          }

          button {
            margin-top: 0;
            margin-left: 0;
            width: 12px;
            height: 12px;

            &:hover, &:focus {
              background-color: transparent !important;

              .slick-dot-icon:before {
                color: inherit;
              }
            }
          }

          &.slick-active {
            button {
              .slick-dot-icon {
                &:before {
                  margin-top: 0;
                  margin-left: 0;
                  font-size: 12px;
                }

                opacity: 1;
                @include breakpoint(large) {
                  color: rgba(255,255,255,1) !important;
                }
              }
            }
          }
          .slick-dot-icon {
            @include breakpoint(large) {
              color: rgba(255,255,255,1) !important;
            }
          }
        }
      }

      .reip-slider-container {
        opacity: 0;

        @include breakpoint(large) {
          margin: 0;
        }

        .reip-slide {
          @include breakpoint(large) {
            position: relative;
            min-height: 95vh;
          }


          .slide-content {
            @include breakpoint(large) {
              position: absolute;
              width: 44vw;
              top: 50%;
              transform: translateY(-50%);
              &.bottom-align {
                top: auto;
                bottom: 15px;
                transform: none;
              }
            }
          }
        }

      }

      .headline {
        display: block;
        @include font-family(TheinhardtMedium);
        font-size: 24px;
        line-height: 25px;
        color: color(black);
        margin-bottom: 20px;

        @include breakpoint(large) {
          font-size: 42px;
          line-height: 44px;
        }
      }

      p {
        @include font-family(TheinhardtRegular);
        font-size: 26px;
        line-height: 29px;
        color: color(black);
        letter-spacing: -0.75px;

        @include breakpoint(large) {
          font-size: 40px;
          line-height: 42px;
          margin: 0;
        }

        span {
          padding-left: 3px;
          padding-right: 3px;
        }
      }
    }
  }


  .reip-detail-wrap-up {
    position: relative;
    z-index: 14;
    background-color: white;

    .wrap-up-audio {
      @include breakpoint(large) {
        display: flex;
        // align-content: center;
        // align-items: center;
      }

      .wrap-up-audio_hero {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border: 3px solid;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @include breakpoint(large) {
          width: 50%;
          height: 728px;
          padding-bottom: 0;
        }

        .how-to-choose-both {
          position: absolute;
          z-index: 5;
          width: 186px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @include breakpoint(large) {
            width: 307px;
          }

          svg {
            display: block;
            max-width: 100%;
          }
        }
      }

      .wrap-up-audio_content {
        padding: 34px 18px;

        @include breakpoint(large) {
          width: 50%;
          padding: 170px 60px 50px 60px;

          *:not(.headline) {
            letter-spacing: -.02rem;
          }
        }

        .cta-hear-a-scenario {
          display: none;
          padding: 0 20px;
          height: 75px;
          border-width: 3px;
          border-style: solid;
          text-align: center;
          @include font-family(TheinhardtRegular);
          font-size: 24px;
          line-height: 56px;
          color: color(black);
          margin-bottom: 45px;
          @include animation();

          i {
            display: inline-block;
            vertical-align: middle;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            position: relative;
            margin-right: 5px;

            &:before {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 9px 0 9px 15.6px;
              border-color: transparent transparent transparent #ffffff;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-35%, -50%);
            }
          }

          @include breakpoint(large) {
            display: inline-block;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .headline {
          display: block;
          @include font-family(TheinhardtRegular);
          font-size: 29px;
          line-height: 30px;
          color: color(black);
          margin-bottom: 20px;

          @include breakpoint(large) {
            font-size: 40px;
            line-height: 42px;
          }
        }

        .wrap-player {
          display: block;
          margin-bottom: 20px;

          .audio-player {
            position: relative;
            height: 30px;
            width: 100%;

            .timeline {
              width: calc(100% - 35px);
              height: 5px;
              position: absolute;
              top: 19px;
              right: 0;
              cursor: pointer;

              .progress {
                width: 0%;
                height: 100%;
                transition: 0.25s;
              }
            }

            .time {
              position: absolute;
              top: 0;
              right: 0;
              @include font-family(TheinhardtRegular);
              font-size: 14px;
              line-height: 14px;
              color: color(black);
              text-align: right;
            }

            .controls {
              width: 35px;
              position: absolute;
              left: 0;
              top: 12px;

              .toggle-play {
                &.play {
                  cursor: pointer;
                }

                &.pause {
                  height: 20px;
                  width: 20px;
                  cursor: pointer;
                  position: relative;

                  &:before {
                    position: absolute;
                    top: 0;
                    left: 0px;
                    content: "";
                    height: 20px;
                    width: 4px;
                  }

                  &:after {
                    position: absolute;
                    top: 0;
                    right: 8px;
                    content: "";
                    height: 20px;
                    width: 4px;
                  }
                }
              }
              // .volume-container {
              //   cursor: pointer;
              //   .volume-button {
              //     height: 26px;
              //     display: flex;
              //     align-items: center;
              //     .volume {
              //       transform: scale(0.7);
              //       width: 26px;
              //
              //       svg {
              //         max-width: 100%;
              //       }
              //
              //       &.icon-volumeMedium {
              //         .icon-volume-on {
              //           display: block;
              //         }
              //         .icon-volume-off {
              //           display: none;
              //         }
              //       }
              //       &.icon-volumeMute {
              //         .icon-volume-on {
              //           display: none;
              //         }
              //         .icon-volume-off {
              //           display: block;
              //         }
              //       }
              //     }
              //   }
              //   position: relative;
              //   z-index: 2;
              //   .volume-slider {
              //     position: absolute;
              //     left: -3px; top: 15px;
              //     z-index: -1;
              //     width: 0;
              //     height: 15px;
              //     background: white;
              //     box-shadow: 0 0 20px #000a;
              //     transition: .25s;
              //     .volume-percentage {
              //       background: coral;
              //       height: 100%;
              //       width: 75%;
              //     }
              //   }
              //   &:hover {
              //     .volume-slider {
              //       left: -123px;
              //       width: 120px;
              //     }
              //   }
              // }
            }
          }

        }

        .wrap-author {
          display: flex;
          align-items: center;
          margin-bottom: 28px;
          letter-spacing: -.01rem;

          @include breakpoint(large) {
            margin-bottom: 50px;
          }

          .wrap-author_avatar {
            flex-shrink: 0;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            margin-right: 13px;
            overflow: hidden;
          }

          .wrap-author_title {
            @include font-family(TheinhardtRegular);
            font-size: 15px;
            line-height: 17px;
            color: color(black);

            @include breakpoint(large) {
              font-size: 20px;
              line-height: 21px;
            }
          }
        }

        .wrap-transcript {
          display: block;
          border-top: 1px solid color(reip_black);
          border-bottom: 1px solid color(reip_black);
          padding: 11px 0 11px 5px;
          margin-bottom: 35px;

          .wrap-transcript_cta-read {
            @include font-family(TheinhardtRegular);
            font-size: 15px;
            line-height: 15px;
            color: color(black);
            letter-spacing: -.02rem;

            @include breakpoint(large) {
              font-size: 20px;
              line-height: 21px;
            }

            i {
              display: inline-block;
              vertical-align: middle;
              width: 8px;
              height: 17px;
              margin-right: 9px;

              svg {
                display: block;
                max-width: 100%;
                max-height: 100%;
                @include animation();
                transform: rotate(0deg);
              }
            }
            &.open {
              svg {
                transform: rotate(90deg);
              }
            }
          }

          .wrap-transcript_content {
            display: none;
            padding-top: 20px;

            p {
              @include font-family(TheinhardtRegular);
              font-size: 15px;
              line-height: 18px;
              color: color(black);
              margin-bottom: .75rem;
              letter-spacing: -0.3px;

              @include breakpoint(large) {
                font-size: 20px;
                line-height: 21px;
              }
            }
          }
        }

        .cta-guide {
          @include animation();
          background-color: white;
          display: inline-block;
          padding: 7px 9px;
          letter-spacing: -.01rem;
          @include font-family(TheinhardtRegular);
          font-size: 15px;
          line-height: 15px;
          color: color(black);
          border-width: 3px;
          border-style: solid;

          @include breakpoint(large) {
          letter-spacing: -.02rem;
            font-size: 20px;
            line-height: 21px;
            padding: 10px;
          }

          &:hover {
            cursor: pointer;
          }
        }

      }

    }
  }


  .you-can-choose-both {
    padding: 64px 0;
    background: color(reip_black);

    &_headline {
      display: block;
      @include font-family(TheinhardtMedium);
      font-size: 26px;
      line-height: 27px;
      color: white;
      margin-bottom: 42px;

      @include breakpoint(large) {
        font-size: 42px;
        line-height: 44px;
        margin-bottom: 85px;
      }
    }

    &_recaptcha-wrap{
      @include breakpoint(large) {
       width: 50%;
      }
    }

    .g-recaptcha {
      padding: 10px 0;
      border: none;

      > div {
        margin: auto;
      }

      @include breakpoint(large) {
        margin: 20px 0 40px;
        > div {
          margin: 0;
        }
      }
    }

    &_form-container {

      @include breakpoint(large) {
        display: flex;
        align-items: flex-start;
        max-width: 932px;
        margin: 0 auto;
      }



      &_fields {
        border-width: 3px;
        border-style: solid;


        p {
          @include font-family(TheinhardtRegular);
          font-size: 26px;
          line-height: 27px;
          color: white;
          padding: 22px 15px 60px 15px;

          @include breakpoint(large) {
            font-size: 40px;
            line-height: 42px;
            padding: 21px 21px 44px 21px;
          }
        }

        input {
          border: none;
          background-color: transparent;
          width: 100%;
          height: 48px;
          line-height: 48px;
          @include font-family(TheinhardtRegular);
          font-size: 17px;
          color: white;
          margin: 0;
          cursor: pointer;
          @include animation();

          @include breakpoint(large) {
            font-size: 26px;
            height: 60px;
            line-height: 56px;
          }

          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: white;
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: white;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: white;
          }
        }

        .border {
          &.top {
            border-top-width: 3px;
            border-top-style: solid;
          }
          &.right {
            border-right-width: 3px;
            border-right-style: solid;
          }
        }
      }

      &_graphic {

        @include breakpoint(large) {
          width: 50%;
          margin-left: -2px;
        }

        .reip-share-graphic {
          border-width: 3px;
          border-style: solid;

          video {
            display: block;
            height: 100%;
            width: 100%;
            margin: auto;
          }

          @include breakpoint(large) {
            border-top-width: 3px;
            border-top-style: solid;
          }
        }
        .reip-social-lnks {
          padding: 10px 0;
          text-align: center;

          @include breakpoint(large) {
            text-align: right;
            padding: 10px 0;
          }

          a {
            display: inline-block;
            vertical-align: middle;
            @include font-family(TheinhardtRegular);
            font-size: 15px;
            line-height: 15px;
            letter-spacing: 0;
            color: white;
            margin-right: 10px;
            border-bottom: thin solid transparent;

            @include breakpoint(large) {
              font-size: 20px;
              line-height: 20px;
              margin-right: 24px;
            }

            &:last-of-type {
              margin-right: 0;
            }

            &:hover {
              border-bottom: thin solid white;
            }
          }
        }
      }
    }
  }


  .explore-more-areas {
    padding: 20px 0;
    background: color(reip_black);

    &_headline {
      display: inline-block;
      vertical-align: middle;
      @include font-family(TheinhardtMedium);
      font-size: 26px;
      line-height: 27px;
      color: white;
      margin-left: 30px;
      margin-right: 30px;

      @include breakpoint(large) {
        font-size: 42px;
        line-height: 44px;
      }
    }

    &_btn-slide {
      display: inline-block;
      vertical-align: middle;
      width: 12px;
      height: 21px;

      svg {
        display: block;
        max-width: 100%;
      }
    }

    &_slider {
      padding: 42px 0;
    }

    .slick-slide {
      margin: 0 10px;
      padding-bottom: 10px;
      opacity: .7;
      width: 170px!important;

      @include breakpoint(large){
        width: 293px!important;
      }

      &.slick-center {
        opacity: 1;

        .explore-more-areas_slide {
          &_cta {
            letter-spacing: -.02rem;
          }
          &--section-1 {
            a:hover {
              .explore-more-areas_slide_headline,
              .explore-more-areas_slide_cta {
                background: rgba(255, 133, 87, 0.6);
              }
            }
          }
          &--section-2 {
            a:hover {
              .explore-more-areas_slide_headline,
              .explore-more-areas_slide_cta {
                background: rgba(76, 113, 255, 0.6);
              }
            }
          }
          &--section-3 {
            a:hover {
              .explore-more-areas_slide_headline,
              .explore-more-areas_slide_cta {
                background: rgba(0, 191, 153, 0.6);
              }
            }
          }
          &--section-4 {
            a:hover {
              .explore-more-areas_slide_headline,
              .explore-more-areas_slide_cta {
                background: rgba(255, 255, 255, 0.5);
              }
            }
          }
          &--section-5 {
            a:hover {
              .explore-more-areas_slide_headline,
              .explore-more-areas_slide_cta {
                background: rgba(255, 186, 13, 0.6);
              }
            }
          }
        }
      }
    }

    &_slide {
      &--section-1 {
        .explore-more-areas_slide_image,
        .explore-more-areas_slide_headline,
        .explore-more-areas_slide_cta {
          border-color: color(reip_orange);
        }
      }
      &--section-2 {
        .explore-more-areas_slide_image,
        .explore-more-areas_slide_headline,
        .explore-more-areas_slide_cta {
          border-color: color(reip_blue);
        }
      }
      &--section-3 {
        .explore-more-areas_slide_image,
        .explore-more-areas_slide_headline,
        .explore-more-areas_slide_cta {
          border-color: color(reip_green);
        }
      }
      &--section-4 {
        .explore-more-areas_slide_image,
        .explore-more-areas_slide_headline,
        .explore-more-areas_slide_cta {
          border-color: white;
        }
      }
      &--section-5 {
        .explore-more-areas_slide_image,
        .explore-more-areas_slide_headline,
        .explore-more-areas_slide_cta {
          border-color: color(reip_yellow);
        }
      }

      a {
        display: block;
      }

      &_image {
        display: block;
        height: 161px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-width: 3px;
        border-style: solid;

        @include breakpoint(large){
          height: 287px;
        }
      }

      &_headline {
        padding: 10px;
        @include font-family(TheinhardtMedium);
        font-size: 20px;
        line-height: 22px;
        color: white;
        border-width: 3px;
        border-style: solid;
        border-top: none;
        margin-bottom: 4px;
        letter-spacing: -.02rem;

        @include animation();

        @include breakpoint(large){
          font-size: 38px;
          line-height: 42px;
          margin-bottom: 6px;
        }
      }

      &_cta {
        padding: 10px;
        @include font-family(TheinhardtRegular);
        font-size: 15px;
        line-height: 15px;
        color: white;
        border-width: 3px;
        border-style: solid;
        border-top: none;
        letter-spacing: -.01rem;
        @include animation();

        @include breakpoint(large){
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }
}
