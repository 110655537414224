.content {
  @include z-index-setup();
  padding: 65px 0;
  background-color: color(neutral-light);

  @include breakpoint(large){
    padding: 200px 0;
  }

  &.no-padding-top {
    padding-top: 0;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.blog-type {
    padding-top: 50px;

    @include breakpoint(large){
      padding-top: 100px;
    }
  }

  h2 {
    @include font-family(PlantinMTProLight);
    font-size: 30px;
    line-height: 34px;
    color: color(black);
    letter-spacing: 0;
    margin-left: 20px;
    margin-bottom: 30px;

    span {
      @include font-family(TheinhardtBold);
    }

    @include breakpoint(large){
      max-width: 810px;
      font-size: 68px;
      line-height: 76px;
      margin-left: 160px;
    }
  }

  .home-intro {
    display: block;
    padding-left: 90px;

    @include breakpoint(large){
      max-width: 600px;
      float: right;
      margin: 0 175px 0 0;
    }

    p.main,
    body.page-template-templatestemplate-climate-lab-php & p,
    body.single-offices & p  {
      opacity: 0.3;
      @include font-family(TheinhardtRegular);
      font-size: 16px;
      line-height: 22px;
      color: color(black);
      letter-spacing: 0;
      margin-bottom: 30px;

      @include breakpoint(large){
        font-size: 24px;
        line-height: 40px;
      }
    }

    a {
      @include font-family(TheinhardtMedium);
      font-size: 16px;
      line-height: 40px;
      color: color(black);
      letter-spacing: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid color(black);

      @include breakpoint(large){
        font-size: 24px;
        line-height: 40px;
      }

      &:hover,
      &:active,
      &:focus {
        color: color(blue);
        border-color: color(blue);
      }
    }
  }
}
