
// Forms

input,
select,
textarea {
	&::placeholder {

	}
}

label {
	@include font-family(TheinhardtMedium);
	font-size: 1.2rem;
	line-height: 1.2;

	.required {
		color: red;
	}
}

input[type="text"],
input[type="email"] {
	display: block;
	height: 50px;
	line-height: 50px;
	border: 1px solid rgba(0,0,0,0.1);
	padding: 0 20px;
	box-shadow: none;
	@include font-family(TheinhardtRegular);
	font-size: 20px;
	color: color(black);
	letter-spacing: 0;

	&:focus {
		box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
	}

	@include breakpoint(large){
		height: 66px;
		line-height: 66px;
		font-size: 24px;
		margin-bottom: 1.875rem;
	}
}

.wpcf7-form {

	p, label {
		opacity: 0.7;
	}

	p:last-of-type {
		opacity: 1!important;
	}

	.button-submit {
		display: inline-block!important;
		@include font-family(TheinhardtMedium);
		color: color(blue)!important;
		letter-spacing: 0!important;
		font-size: 20px!important;
		line-height: 30px!important;
		border: none;
		border-bottom: 1px solid color(blue)!important;
		margin-top: 40px!important;
		background-color: transparent!important;
		cursor: pointer;

		@include breakpoint(large){
			font-size: 24px!important;
		}
	}

	h3 {
		@include font-family(TheinhardtBold);
	  font-size: 28px;
	  line-height: 40px;
	  color: color(black);
	  letter-spacing: 0;
	  margin-bottom: 30px;
	}

	h4 {
		@include font-family(secondary);
		line-height: 1.4;
	}
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
	// @include breakpoint(medium){
	// 	display: block;
	// }
}
